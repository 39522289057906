import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {Auth} from "aws-amplify";
import LoaderButton from "../component/LoaderButton";
import {useAppContext} from "../common/context";
import UnauthenticatedPage from "../component/UnauthenticatedPage";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {requiredField, validateEmailField, validatePasswordField} from "../common/util";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Reset() {
    const classes = useStyles();
    const {userHasAuthenticated, setToast} = useAppContext();
    const [isLoading, setIsLoading] = useState(false)
    const [codeSent, setCodeSent] = useState(false);
    const [fields, setFields] = useState({
        email: {value: "", visited: false, valid: false, errors: []},
        password: {value: "", visited: false, valid: false, errors: []},
        confirmationCode: {value: "", visited: false, valid: false, errors: []},
    });
    const {t} = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function handleFieldChange(event) {
        fields[event.target.name].value = event.target.value;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function handleBlur(event) {
        fields[event.target.name].visited = true;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function validateFields() {
        if (codeSent) {
            requiredField(fields.confirmationCode, "Confirmation code is required");
            validatePasswordField(fields.password);
        } else {
            validateEmailField(fields.email);
        }
    }


    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.forgotPassword(fields.email.value);
            setIsLoading(false);
            setCodeSent(true);
        } catch (e) {
            setToast({message: t('reset.error'), error: e, severity: "error"})
            setIsLoading(false)
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.forgotPasswordSubmit(fields.email.value, fields.confirmationCode.value, fields.password.value);
            await Auth.signIn(fields.email.value, fields.password.value);
            userHasAuthenticated(true);
        } catch (e) {
            setToast({message: t('reset.error'), error: e, severity: "error"});
            setIsLoading(false);
        }
    }

    function validateConfirmationForm() {
        return fields.email.valid && fields.confirmationCode.valid && fields.password.valid;
    }

    function validateForgotPasswordForm() {
        return fields.email.valid;
    }

    function renderConfirmationForm() {
        return (
            <form className={classes.form} noValidate onSubmit={handleConfirmationSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label={t('general.email')}
                            name="email"
                            autoComplete="email"
                            value={fields.email.value}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="confirmationCode"
                            variant="outlined"
                            required
                            fullWidth
                            id="confirmationCode"
                            label={t('reset.confirmation.code')}
                            autoFocus
                            value={fields.confirmationCode.value}
                            error={fields.confirmationCode.errors.length > 0}
                            helperText={fields.confirmationCode.errors}
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                        <Typography variant="body2" color="textSecondary">
                            {t('reset.confirmation.description')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="password"
                            autoComplete="off"
                            variant="outlined"
                            required
                            fullWidth
                            id="password"
                            label={t('general.password')}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    (<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>)
                            }}
                            value={fields.password.value}
                            error={fields.password.errors.length > 0}
                            helperText={fields.password.errors}
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                    </Grid>

                </Grid>
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    className={classes.submit}
                    disabled={!validateConfirmationForm()}
                >
                    {t('reset.confirmation.submit')}
                </LoaderButton>
            </form>);

    }

    function renderForgotPasswordForm() {
        return (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label={t('general.email')}
                            name="email"
                            autoComplete="email"
                            value={fields.email.value}
                            error={fields.email.errors.length > 0}
                            helperText={fields.email.errors}
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                </Grid>
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    className={classes.submit}
                    disabled={!validateForgotPasswordForm()}
                >
                    {t('reset.submit')}
                </LoaderButton>
            </form>);
    }

    return (
        <UnauthenticatedPage>
            {codeSent ? renderConfirmationForm() : renderForgotPasswordForm()}
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Link
                        color={"textSecondary"}
                        component={RouterLink}
                        to={"/login"}
                        variant="body2"
                        underline="hover">
                        {t('reset.remembered')}
                    </Link>
                </Grid>
            </Grid>
        </UnauthenticatedPage>
    );
}
