import {get} from "./vrexAuth";

const headers = {accept: "application/vnd.api+json"};

class StreamBimApi {

    static async getProjects() {
        let response = await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/streambim/mgw/api/v3/project-links?filter[active]=true", headers);
        return await response.json();
    }

    static async getBuildings(projectId) {
        let response = await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/streambim/project-" + projectId + "/api/v1/v2/buildings", headers);
        return await response.json();
    }

    static async getModels(projectId, buildingId) {
        let response = await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/streambim/project-" + projectId + "/api/v1/v2/ifc-files?filter%5Bbuilding%5D=" + buildingId, headers);
        return await response.json();
    }
}

export default StreamBimApi;
