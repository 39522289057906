export class Queue {
    constructor(concurrency) {
        this.concurrency = concurrency;
        this.runnerTasks = [];
        this.tasks = [];
    }

    enqueue(task) {
        this.tasks.push(task);
    }

    async execute() {
        for (let i = 0; i < this.concurrency; i++) {
            this.runnerTasks.push((async () => {
                while (this.tasks.length) {
                    try {
                        await this.tasks.shift()();
                    } catch (err) {
                        console.error(err);
                    }
                }
            })());
        }
        await Promise.allSettled(this.runnerTasks)
    }
}

