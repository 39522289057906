import React, {useCallback, useEffect, useRef, useState} from "react";
import {Add} from "@mui/icons-material";
import VrexApi from "../api/vrexApi";
import {Link, Route, Routes} from "react-router-dom";
import AddSource from "./AddSource";
import Integration from "../component/Integration";
import {Bim360Browser} from "./integrations/bim360/Bim360Browser";
import {useAppContext} from "../common/context";
import Typography from "@mui/material/Typography";
import {BimsyncBrowser} from "./integrations/bimsync/BimsyncBrowser";
import {Box} from "@mui/material";
import ProjectPage from "../component/ProjectPage";
import {CircularButton} from "../component/CircularButton";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {BimtrackBrowser} from "./integrations/bimtrack/BimtrackBrowser";
import {StreamBimBrowser} from "./integrations/streambim/StreamBimBrowser";
import {AconexBrowser} from "./integrations/aconex/AconexBrowser";
import {BuildagilBrowser} from "./integrations/buildagil/BuildagilBrowser";
import {BimplusBrowser} from "./integrations/bimplus/BimplusBrowser";
import LocalModelSource from "../component/LocalModelSource";
import {OpenApi} from "../api/openApi";

export default function Models({project}) {
    const {isAuthenticated, setToast, subscribeToEventLocally} = useAppContext();
    const [converters, setConverters] = useState([]);
    const [modelSources, setModelSources] = useState([]);
    const [modelEventReceived, setModelEventReceived] = useState(false);
    const modelEvents = useRef([]);
    const [modelsDeletedEventReceived, setModelsDeletedEventReceived] = useState(false);
    const modelsDeletedEvents = useRef([]);
    const [uploadEventReceived, setUploadEventReceived] = useState(false);
    const uploadEvents = useRef([]);
    const [modelSourceEventReceived, setModelSourceEventReceived] = useState(false);
    const modelSourceEvents = useRef([]);
    const theme = useTheme();
    const {t} = useTranslation();

    function sortModelSources(sources) {
        sources.sort(function (a, b) {
            if (a.type === b.type) {
                if (a.type === "LOCAL") {
                    if (a.subType === b.subType)
                        return a.id > b.id ? -1 : 1;
                    return a.subType > b.subType ? -1 : 1;
                }
                return a.id > b.id ? -1 : 1;
            }
            if (a.type === "LOCAL")
                return 1;
            if (b.type === "LOCAL")
                return -1;
            return a.type.localeCompare(b.type);
        });
    }

    const loadContents = useCallback(async (projectId) => {
        async function loadModelSources() {
            try {
                return await VrexApi.getModelSources(projectId)
            } catch (e) {
                setToast({message: t('project.models.error'), error: e, severity: "error"})
                return [];
            }
        }

        const sources = await loadModelSources();
        sortModelSources(sources);
        setModelSources(sources);
    }, [setToast, t]);

    const getConverters = useCallback(async () => {
        async function getConverters() {
            try {
                return await OpenApi.getConverters();
            } catch (e) {
                setToast({message: t('project.models.converters.error'), error: e, severity: "error"})
                return [];
            }
        }

        const converters = await getConverters();
        setConverters(converters);
    }, [setToast, t]);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        loadContents(project.id).then();
        getConverters().then();
    }, [getConverters, isAuthenticated, loadContents, project.id]);

    useEffect(() => {
        setModelEventReceived(false);
        if (!modelEventReceived) {
            return;
        }
        for (let modelEvent of modelEvents.current) {
            modelEvent.id = modelEvent.modelId;
            let modelSourceIndex = modelSources.findIndex(ms => ms.id === modelEvent.modelSourceId);
            if (modelSourceIndex === -1) {
                continue;
            }
            if (modelEvent.status === "DELETED") {
                if (modelSources[modelSourceIndex].models) {
                    modelSources[modelSourceIndex].models = modelSources[modelSourceIndex].models.filter(m => m.id !== modelEvent.modelId);
                }
            } else {
                if (!modelSources[modelSourceIndex].models) {
                    modelSources[modelSourceIndex].models = [modelEvent];
                } else {
                    let index = modelSources[modelSourceIndex].models.findIndex(m => m.id === modelEvent.modelId);
                    if (index !== -1) {
                        modelSources[modelSourceIndex].models[index] = modelEvent;
                    } else {
                        modelSources[modelSourceIndex].models.push(modelEvent);
                    }
                }
            }
            modelSources[modelSourceIndex].models = [...modelSources[modelSourceIndex].models];
        }
        setModelSources([...modelSources]);
        modelEvents.current = [];
    }, [modelSources, modelEventReceived]);

    useEffect(() => {
        setUploadEventReceived(false);
        if (!uploadEventReceived) {
            return;
        }
        for (let uploadEvent of uploadEvents.current) {
            let modelSource = modelSources.find(modelSource => modelSource.id === uploadEvent.modelSourceId);
            if (!modelSource) {
                continue;
            }
            uploadEvent.id = uploadEvent.uploadId;
            if (uploadEvent.status === "COMPLETED") {
                if (modelSource.uploads) {
                    modelSource.uploads = modelSource.uploads.filter(u => u.id !== uploadEvent.uploadId);
                }
            } else {
                if (!modelSource.uploads) {
                    modelSource.uploads = [uploadEvent];
                } else {
                    let index = modelSource.uploads.findIndex((u) => {
                        return u.id === uploadEvent.uploadId
                    });
                    if (index === -1) {
                        modelSource.uploads.push(uploadEvent);
                    } else {
                        modelSource.uploads[index] = uploadEvent;
                    }
                }
            }
        }
        setModelSources([...modelSources]);
        uploadEvents.current = [];
    }, [modelSources, uploadEventReceived]);

    useEffect(() => {
        setModelsDeletedEventReceived(false);
        if (!modelsDeletedEventReceived) {
            return;
        }
        for (let modelsDeletedEvent of modelsDeletedEvents.current) {
            let modelSource = modelSources.find(modelSource => modelSource.id === modelsDeletedEvent.modelSourceId);
            if (!modelSource) {
                continue;
            }
            modelSource.models = modelSource.models.filter((model) => !modelsDeletedEvent.modelIds.includes(model.id));
        }
        setModelSources([...modelSources]);
        modelsDeletedEvents.current = [];
    }, [modelSources, modelsDeletedEventReceived]);

    useEffect(() => {
        setModelSourceEventReceived(false);
        if (!modelSourceEventReceived) {
            return;
        }
        let ms = modelSources;
        for (let modelSourceEvent of modelSourceEvents.current) {
            if (modelSourceEvent.status === "DELETED") {
                ms = modelSources.filter((modelSource) => modelSource.id !== modelSourceEvent.modelSourceId)
            } else {
                let index = modelSources.findIndex(modelSource => modelSource.id === modelSourceEvent.modelSourceId);
                if (index === -1) {
                    modelSourceEvent.id = modelSourceEvent.modelSourceId;
                    modelSourceEvent.models = [];
                    modelSources.push(modelSourceEvent);
                    sortModelSources(modelSources);
                } else {
                    modelSources[index].name = modelSourceEvent.name;
                }
            }
        }
        setModelSources([...ms]);
        modelSourceEvents.current = [];
    }, [modelSources, modelSourceEventReceived]);


    useEffect(() => {
        let unsubscribeModelUpdated = subscribeToEventLocally("MODEL_UPDATED", (modelUpdate) => {
            modelEvents.current.push(modelUpdate);
            setModelEventReceived(true);
        });
        let unsubscribeModelsDeleted = subscribeToEventLocally("MODELS_DELETED", (modelsDeleted) => {
            modelsDeletedEvents.current.push(modelsDeleted);
            setModelsDeletedEventReceived(true);
        });
        let unsubscribeUploadUpdated = subscribeToEventLocally("UPLOAD_UPDATED", (uploadUpdate) => {
            uploadEvents.current.push(uploadUpdate);
            setUploadEventReceived(true);
        });
        let unsubscribeModelSource = subscribeToEventLocally("MODEL_SOURCE_UPDATED", (modelSourceUpdate) => {
            modelSourceEvents.current.push(modelSourceUpdate);
            setModelSourceEventReceived(true);
        });
        return () => {
            unsubscribeModelUpdated();
            unsubscribeModelsDeleted();
            unsubscribeUploadUpdated();
            unsubscribeModelSource();
        }
    }, [subscribeToEventLocally])

    function onModelSourceAdded(integration) {
        modelSources.push(integration);
        sortModelSources(modelSources);
        setModelSources([...modelSources]);
    }

    async function deleteModelSource(modelSourceId) {
        try {
            await VrexApi.deleteModelSource(project.id, modelSourceId);
            const sources = modelSources.filter(modelSource => modelSource.id !== modelSourceId)
            setModelSources([...sources]);
        } catch (e) {
            setToast({message: t('project.models.modelSource.delete.error'), error: e, severity: "error"})
        }
    }

    function onModelAdded(modelSourceId, model) {
        let index = modelSources.findIndex(modelSource => modelSource.id === modelSourceId);
        if (!modelSources[index].models) {
            modelSources[index].models = [model];
        } else if (!modelSources[index].models.find(m => m.id === model.id)) {
            modelSources[index].models.push(model);
        }
        setModelSources([...modelSources]);
    }

    async function onSyncIntegration(integrationId) {
        function canProcess(integration) {
            if (integration)
                return new Date(integration.lastSynced).getTime() < Date.now() - 60000;
            return false;
        }

        try {
            let integration = modelSources.find(modelSource => modelSource.id === integrationId);
            if (canProcess(integration)) {
                let response = await VrexApi.postProcessRequest(project.id, integrationId);
                integration.lastSynced = response.lastSynced;
                setModelSources([...modelSources]);
                setToast({message: t('project.models.integration.models.sync.success'), severity: "success"});
            } else {
                setToast({message: t('project.models.integration.models.sync.error'), severity: "error"});
            }
        } catch (e) {
            setToast({message: t('project.models.integration.models.sync.error'), severity: "error"});
        }
    }

    async function deleteModel(modelSourceId, modelId) {
        try {
            await VrexApi.deleteModel(project.id, modelSourceId, modelId);
            let index = modelSources.findIndex(modelSource => modelSource.id === modelSourceId);
            modelSources[index].models = modelSources[index].models.filter(m => m.id !== modelId);
            setModelSources([...modelSources]);
        } catch (e) {
            setToast({message: t('project.modelSource.model.delete.error'), error: e, severity: "error"});
        }
    }

    async function deleteModels(modelSourceId) {
        try {
            await VrexApi.deleteModels(project.id, modelSourceId);
            let index = modelSources.findIndex(modelSource => modelSource.id === modelSourceId);
            modelSources[index].models = [];
            setModelSources([...modelSources]);
        } catch (e) {
            setToast({message: t('project.modelSource.model.delete.error'), error: e, severity: "error"});
        }
    }

    let inactive = project.status === "INACTIVE"

    async function renameModelSource(modelSourceId, modelSourceName) {
        try {
            await VrexApi.patchModelSource(project.id, modelSourceId, {name: modelSourceName});
            let index = modelSources.findIndex(modelSource => modelSource.id === modelSourceId);
            modelSources[index].name = modelSourceName;
            setModelSources([...modelSources]);
        } catch (e) {
            setToast({message: t('project.settings.error'), error: e, severity: "error"});
        }
    }

    function renderContent() {
        return (
            <React.Fragment>
                {modelSources.map(modelSource => {
                        if (modelSource.type === "LOCAL" || modelSource.type === "LOCAL_CONVERTED") {
                            return (
                                <LocalModelSource key={modelSource.id} projectId={project.id}
                                                  disabled={inactive}
                                                  modelSource={modelSource}
                                                  onDeleteModelSource={() => deleteModelSource(modelSource.id)}
                                                  onRenameModelSource={renameModelSource}
                                                  onDeleteModel={deleteModel}
                                                  onDeleteModels={() => deleteModels(modelSource.id)}
                                                  converters={converters}/>);
                        }
                        return (
                            <Integration key={modelSource.id} projectId={project.id}
                                         disabled={inactive}
                                         integration={modelSource}
                                         onDeleteIntegration={() => deleteModelSource(modelSource.id)}
                                         onRenameModelSource={renameModelSource}
                                         onDeleteModel={deleteModel}
                                         onDeleteModels={() => deleteModels(modelSource.id)}
                                         onSyncIntegration={() => onSyncIntegration(modelSource.id)}
                                         origin={window.location.href}
                                         converters={converters}/>
                        );
                    }
                )}
            </React.Fragment>
        )
    }

    return (
        modelSources ?
            <Routes>
                <Route index
                       element={
                           <ProjectPage>
                               <Box display={"flex"} flexDirection={"row-reverse"}>
                                   <CircularButton
                                       title={t('project.models.addSource')}
                                       component={Link}
                                       disabled={inactive}
                                       shadow
                                       spacing={2}
                                       to={`/projects/${project.id}/models/add-source`}>
                                       <Add/>
                                   </CircularButton>
                               </Box>

                               <Box display={"flex"} flexDirection={"row-reverse"} alignItems={"stretch"} mt={2}>
                                   <Typography
                                       color={"textSecondary"}
                                       variant={"body2"}
                                       sx={{
                                           marginTop: theme.spacing(2),
                                           flexGrow: 1
                                       }}>
                                       {t('project.models.description')}
                                   </Typography>
                               </Box>
                               {renderContent()}
                           </ProjectPage>}
                />

                <Route path={"add-source/*"}
                       element={<AddSource projectId={project.id} color={"secondary"}
                                           onModelSourceAdded={onModelSourceAdded}/>}/>
                <Route path={`aconex/:integrationId`}
                       element={<AconexBrowser projectId={project.id}
                                               onModelAdded={onModelAdded}/>}/>
                <Route path={`bim360/:integrationId`}
                       element={<Bim360Browser projectId={project.id}
                                               onModelAdded={onModelAdded}/>}/>
                <Route path={`bimsync/:integrationId`}
                       element={<BimsyncBrowser projectId={project.id}
                                                onModelAdded={onModelAdded}/>}/>
                <Route path={`bimtrack/:integrationId`}
                       element={<BimtrackBrowser projectId={project.id}
                                                 onModelAdded={onModelAdded}/>}/>
                <Route path={`streambim/:integrationId`}
                       element={<StreamBimBrowser projectId={project.id}
                                                  onModelAdded={onModelAdded}/>}/>
                <Route path={`buildagil/:integrationId`}
                       element={<BuildagilBrowser projectId={project.id}
                                                  onModelAdded={onModelAdded}/>}/>
                <Route path={`bimplus/:integrationId`}
                       element={<BimplusBrowser projectId={project.id}
                                             onModelAdded={onModelAdded} />} />

            </Routes> : ""
    )
}
