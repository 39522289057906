import {get} from "./vrexAuth";
import {proxyEncode} from "../common/util";

class AconexApi {

    static async getProjects() {
        let response = await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/aconex/api/projects");
        return await response.json();
    }

    static async getDocuments(projectId) {
        let response =  await get(proxyEncode(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/aconex/api/projects/" + projectId + "/register?search_query=filetype:ifc OR filetype:nwc OR filetype:nwd OR filetype:rvt OR filetype:e57&return_fields=filename"), { Accept: "application/xml" });
        return await response.text();
    }
}

export default AconexApi;
