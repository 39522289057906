import React from "react";
import BimtrackApi from "../../../api/bimtrackApi";
import { BimtrackConfig } from "../../../common/integrationConfig";
import {IntegrationBrowser} from "../IntegrationBrowser";

export function BimtrackBrowser({projectId, onModelAdded}) {

    async function fetchRootNodes(integration) {
        const response = await BimtrackApi.getModels(integration.externalData, integration.externalProjectId);
        return response.map(item => ({
            id: item.Id.toString(),
            name: item.Name,
            selected: false,
            disabled: integration.models.find(model => model.externalId === item.Id.toString()),
            isLeaf: true
        }));
    }

    return (
        <IntegrationBrowser projectId={projectId}
                            integrationConfig={BimtrackConfig}
                            fetchRootNodes={fetchRootNodes}
                            onModelAdded={onModelAdded}/>
    )
}
