import React from "react";
import Box from "@mui/material/Box";
import {alpha, CircularProgress, Typography} from "@mui/material";
import {CheckBoxOutlineBlank, CheckBoxOutlined} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

export function Node({title, expanded, loading, onClick, icon, ...props}) {
    const theme = useTheme()
    return (
        <Box style={{cursor: "pointer"}}>
            <Box display={"flex"} pl={1} py={0.5} onClick={onClick} borderBottom={1} boxSizing={"border-box"}
                 style={{borderBottomColor: theme.palette.text.disabled}}>
                {icon}
                <Typography sx={{pl: theme.spacing(1)}} color={"textPrimary"}>{title}</Typography>{loading ?
                <Box pl={1}><CircularProgress color={"secondary"} size={20}/></Box> : ""}
            </Box>
            <Box pl={1}>{props.children}</Box>
        </Box>);
}

export function EmptyNode() {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <Box display={"flex"} pl={4} py={0.5} borderBottom={1} boxSizing={"border-box"}
             style={{borderBottomColor: theme.palette.text.disabled, cursor: "default"}}>
            <Box clone pl={1}><Typography color={"textPrimary"}
                                          style={{color: theme.palette.text.disabled}}>{t('general.noContent')}</Typography></Box>
        </Box>
    );
}

export function LeafNode({selected, disabled, title, onClick}) {
    const theme = useTheme()
    let backgroundStyle = disabled ? {
        cursor: "default",
        borderBottomColor: theme.palette.text.disabled
    } : {cursor: "pointer", borderBottomColor: theme.palette.text.disabled};
    let textStyle = disabled ? {color: theme.palette.text.disabled} : {color: theme.palette.text.primary};
    return (
        <Box pl={1} style={backgroundStyle} onClick={disabled ? null : onClick} display={"flex"} alignItems={"center"}
             borderBottom={1}
             boxSizing={"border-box"}>
            {selected ? <CheckBoxOutlined/> : <CheckBoxOutlineBlank style={textStyle}/>}
            <Box clone pl={1} py={0.5} flexGrow={1}
                 bgcolor={selected ? alpha(theme.palette.primary.main, 0.2) : "inherit"}><Typography
                style={textStyle}>{title}</Typography>
            </Box>
        </Box>);
}

