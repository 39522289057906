import IconButton from "@mui/material/IconButton";
import {HighlightOffOutlined} from "@mui/icons-material";
import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";
import Box from "@mui/material/Box";
import {ConfirmedAction} from "./ConfirmedAction";
import {useAppContext} from "../common/context";
import {useTranslation} from "react-i18next";
import {RoleDescriptions} from "./HelpText";
import {tableStyles} from "../common/sharedStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {Capacity, getRole, getStatusIcon, Roles} from "../common/util";
import {useTheme} from "@mui/material/styles";


export function OrgUserList({orgUsers, capacity, onRoleChange, onRemoveUser, role}) {
    const classes = tableStyles();
    const {user} = useAppContext();
    const {t} = useTranslation();
    const theme = useTheme();


    return (
        <Box bgcolor={"background.paper"} my={2} p={2} boxShadow={1}>
            <Typography
                variant={"h6"} display="inline"
                style={{paddingRight: theme.spacing(2)}}>{t('organization.users.title')}</Typography>
            {capacity.users < Capacity.UNLIMITED &&
                <Typography color={"textSecondary"} variant={"body2"} display="inline"
                            style={{paddingRight: theme.spacing(2)}}>
                    {t('organization.users.list.userCount', {current: orgUsers.length, max: capacity.users})}
                </Typography>}
            <TableContainer className={classes.tableContainer}>
                <Table size="small" aria-label="model table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell} style={{width: "30%"}}>
                                {t('general.users.name')}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{width: "5%"}}>
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{width: "35%"}}>
                                {t('general.users.email')}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{width: "15%"}}>
                                {t('organization.users.list.role.title')}
                                <RoleDescriptions/>
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{width: "15%"}} align="right">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orgUsers.map((ou) =>
                            <TableRow key={"u_" + ou.id} className={classes.tableDataRow}>
                                <TableCell
                                    className={classes.tableCell}>{ou.user.name + " " + ou.user.lastName}</TableCell>
                                <TableCell
                                    className={`${classes.tableCell} ${classes.iconCell}`}>{getStatusIcon(ou.status, t)}</TableCell>
                                <TableCell className={classes.tableCell}>{ou.user.email}</TableCell>
                                <TableCell className={classes.tableCell}>
                                    {role.accessLevel < Roles.ADMIN.accessLevel ?
                                        getRole(ou.role.accessLevel).title
                                        :
                                        <Select
                                            labelId="select-organization-label"
                                            disableUnderline
                                            sx={{fontSize: 12,}}
                                            value={ou.role.accessLevel}
                                            onChange={(event) => onRoleChange(ou.id, getRole(event.target.value))}
                                            disabled={ou.user.email === user.email}
                                            variant={"standard"}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        borderRadius: 0,
                                                    }
                                                }
                                            }}
                                        >
                                            {Object.values(Roles).map((r, index) => {
                                                    return (
                                                        <MenuItem key={index} id={"accessLevel"} dense
                                                                  classes={{root: classes.selectItem}}
                                                                  value={r.accessLevel}>
                                                            {r.title}
                                                        </MenuItem>)
                                                }
                                            )}
                                        </Select>
                                    }
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.iconCell}`} align="right">
                                    <ConfirmedAction title={t('organization.users.list.delete.dialog.title')}
                                                     description={t('organization.users.list.delete.dialog.description', {
                                                         type: "organization",
                                                         name: ou.user.name ? ou.user.name + " " + ou.user.lastName : ou.user.email,
                                                     })}
                                                     onConfirmed={() => onRemoveUser(ou.id)}>
                                        <IconButton size={"small"}
                                                    disabled={role.accessLevel < Roles.COORDINATOR.accessLevel || (ou.role.accessLevel > Roles.USER.accessLevel && role.accessLevel < Roles.ADMIN.accessLevel) || ou.user.email === user.email}
                                                    color={"secondary"}>
                                            <HighlightOffOutlined/>
                                        </IconButton>
                                    </ConfirmedAction>
                                </TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
