import React, {useEffect, useState} from 'react';
import {useAppContext} from "../common/context";
import Typography from "@mui/material/Typography";
import {Box, Grid, MenuItem, Select} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {Organization} from "./Organization";

const useStyles = makeStyles(theme => (
    {
        select: {
            color: theme.palette.primary.contrastText,
            paddingLeft: theme.spacing(2)
        },
        icon: {
            fill: theme.palette.primary.contrastText,
        },
        paper:
            {
                backgroundColor: theme.palette.primary.main,
                left: theme.spacing(1),
                borderRadius: 0
            },
    }))

export default function Organizations() {
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState("");
    const {user} = useAppContext();
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    useEffect(() => {
        let parts = location.pathname.split("/");
        if (parts.length !== 4) {
            if (organizations.length > 0) {
                navigate("/organizations/" + organizations[0].id + "/users", {replace: true});
            } else {
                setSelectedOrganization("");
            }
        } else {
            if (organizations.length === 0) {
                navigate("/organizations", {replace: true});
                setSelectedOrganization("");

            } else {
                if (organizations.some(org => org.id === parseInt(parts[2]))) {
                    setSelectedOrganization(parts[2]);
                } else {
                    navigate("/organizations/" + organizations[0].id + "/users", {replace: true});
                }
            }
        }
    }, [location.pathname, navigate, organizations]);


    useEffect(() => {
        if (!user?.organizations) {
            return;
        }
        setOrganizations(user.organizations);
    }, [user]);

    function handleSelectedOrganizationChanged(event) {
        setSelectedOrganization("" + event.target.value);
        navigate("/organizations/" + event.target.value + "/users");
    }

    function renderOrganizations() {
        return (
            organizations.length > 1 ?
                <Select value={selectedOrganization}
                        disableUnderline
                        classes={{select: classes.select, icon: classes.icon}}
                        onChange={handleSelectedOrganizationChanged}
                        variant={"standard"}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            marginThreshold: 1,
                            classes: {paper: classes.paper}
                        }}
                >
                    {organizations.map((organization, i) =>
                        <MenuItem key={i} value={organization.id}><Typography variant={"h6"}
                                                                              style={{color: theme.palette.primary.contrastText}}>{organization.name}</Typography></MenuItem>
                    )}
                </Select> :
                <Typography variant={"h6"}
                            style={{
                                color: theme.palette.primary.contrastText,
                                paddingLeft: theme.spacing(2)
                            }}>{organizations.length > 0 ? organizations[0].name : t('organizations.title')}</Typography>
        )
    }

    return (
        <Box flexGrow={1} display={"flex"} flexDirection={"column"}>
            <Typography variant={"h6"}>
                <Box
                    display={"flex"}
                    alignItems="center"
                    bgcolor="primary.main"
                    px={2}
                    height={"48px"}
                    color="primary.contrastText">
                    {renderOrganizations()}
                </Box>
            </Typography>
            {
                user && organizations.length === 0 &&
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      style={{flexGrow: '1'}}>
                    <Grid item xs={12} sm={10} md={7} lg={7} xl={5}
                          style={{
                              paddingLeft: "6vw",
                              paddingRight: "6vw",
                              paddingTop: theme.spacing(4),
                              backgroundColor: theme.palette.background.list
                          }}>
                        <Typography variant={"body2"}
                                    color={"textSecondary"}>
                            {t('organizations.empty')}</Typography>
                    </Grid>
                </Grid>
            }
            {
                user && organizations.length > 0 && selectedOrganization !== "" &&
                <Organization organizationId={selectedOrganization}/>
            }
        </Box>)


}
