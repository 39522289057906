import i18n from 'i18next'
// import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
// import XHR from 'i18next-xhr-backend'
import languageEN from './locate/en/translate.json'

i18n
    //TODO lazy loading and language detector
    // .use(XHR)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: languageEN
        },
        lng: "en",
        fallbackLng: "en",
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            useSuspense: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    }).then()

export default i18n;
