import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {IconButton} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {useEffect, useRef, useState} from "react";

export default function Accordion({defaultExpanded, summary, ...props}) {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const theme = useTheme();
    const contentRef = useRef();
    const contentTransition = theme.transitions.create(['height']);

    useEffect(() => {
        contentRef.current.style.height = defaultExpanded ? "auto" : "0px";
    }, [defaultExpanded])

    function handleExpand() {
        if (expanded) {
            contentRef.current.style.transition = '';
            requestAnimationFrame(function () {
                contentRef.current.style.height = contentRef.current.scrollHeight + 'px';
                contentRef.current.style.transition = contentTransition;
                requestAnimationFrame(function () {
                    contentRef.current.style.height = 0 + 'px';
                });
            });
            setExpanded(false);
        } else {
            contentRef.current.style.transition = contentTransition;
            contentRef.current.style.height = contentRef.current.scrollHeight + 'px';
            contentRef.current.addEventListener('transitionend', () => {
                contentRef.current.style.height = "auto";
            }, {once: true});
            setExpanded(true);
        }
    }

    return (
        <Box display={"flex"} flexDirection={"column"} sx={{
            marginTop: theme.spacing(2),
            background: theme.palette.background.paper,
            boxShadow: 1,
        }}>
            <Box display={"flex"} alignItems={"center"} padding={1} paddingLeft={2}
                 sx={{
                     borderBottom: '1px solid rgba(0, 0, 0, .125)',
                 }}>
                <Box display={"flex"} alignItems={"center"} flexGrow={1}>
                    {summary}
                </Box>
                <IconButton onClick={handleExpand}
                            sx={{
                                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                                transition: theme.transitions.create(['transform'])
                            }}>
                    <ExpandMore/>
                </IconButton>
            </Box>
            <Box overflow={"hidden"} ref={contentRef}>
                {props.children}
            </Box>
        </Box>
    )
}
