import React from "react";
import {IntegrationBrowser} from "../IntegrationBrowser";
import AconexApi from "../../../api/aconexApi";
import { AconexConfig } from "../../../common/integrationConfig";

export function AconexBrowser({projectId, onModelAdded}) {

    async function fetchRootNodes(integration) {
        const response = await AconexApi.getDocuments(integration.externalProjectId);
        let xml = new window.DOMParser().parseFromString(response, "text/xml");
        let documents = xml.getElementsByTagName("Document");
        let items = []
        for (let document of documents) {
            items.push(mapItem(document, integration.models))
        }
        return items;
    }

    function mapItem(document, existingModels) {
        let id = document.getAttribute("DocumentId");
        return {
            id: id,
            name: document.getElementsByTagName("Filename")[0].textContent,
            isLeaf: true,
            selected: false,
            disabled: existingModels.find(model => model.externalId === id),
        }
    }

    return (
        <IntegrationBrowser projectId={projectId}
                            integrationConfig={AconexConfig}
                            fetchRootNodes={fetchRootNodes}
                            onModelAdded={onModelAdded}/>
    )
}
