import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Auth} from "aws-amplify";
import {CircularProgress, InputAdornment, Typography} from "@mui/material";
import {errorString, requiredField, validatePasswordField} from "../common/util";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export function ChangePasswordDialog({open, onClose}) {
    function initialFields() {
        return {
            currentPassword: {value: "", visited: false, valid: false, errors: []},
            newPassword: {value: "", visited: false, valid: false, errors: []},
        };
    }

    const theme = useTheme();
    const [fields, setFields] = useState(initialFields());
    const [valid, setValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const {t} = useTranslation();
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setFields(initialFields());
        setError(null);
        setSuccess(false);
    }, [open])


    function validateFields() {
        requiredField(fields.currentPassword, t('topBar.changePassword.validations.required'));
        validatePasswordField(fields.newPassword);
        if(fields.currentPassword.value === fields.newPassword.value && fields.newPassword.valid){
            console.log("equal passwords");
            fields.newPassword.errors.push(t('topBar.changePassword.validations.match'))
            fields.newPassword.valid = false
        }
        setValid(fields.currentPassword.valid && fields.newPassword.valid);
    }


    async function handleSubmit() {
        setIsSubmitting(true);
        try {
            let user = await Auth.currentUserPoolUser();
            await Auth.changePassword(user, fields.currentPassword.value, fields.newPassword.value);
            setIsSubmitting(false);
            setSuccess(true);
        } catch (e) {
            setIsSubmitting(false);
            setError(errorString(e));
        }
    }

    function handleFieldChange(event) {
        fields[event.target.name].value = event.target.value;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function handleBlur(event) {
        fields[event.target.name].visited = true;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function inputContent() {
        return <>
            <DialogContent>
                <form>
                    <Box display={"flex"} flexDirection={"column"}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            required
                            error={fields.currentPassword.errors.length > 0}
                            helperText={fields.currentPassword.errors}
                            value={fields.currentPassword.value}
                            label={t('topBar.changePassword.current')}
                            name="currentPassword"
                            autoComplete="off"
                            type={showCurrentPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    (<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowCurrentPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showCurrentPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>)
                            }}
                            autoFocus
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                        <TextField
                            variant="outlined"
                            margin="dense"
                            required
                            error={fields.newPassword.errors.length > 0}
                            helperText={fields.newPassword.errors}
                            value={fields.newPassword.value}
                            label={t('topBar.changePassword.new')}
                            name="newPassword"
                            autoComplete="off"
                            type={showNewPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    (<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowNewPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>)
                            }}
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                    </Box>
                </form>
            </DialogContent>
            <DialogActions style={{flexDirection: "row-reverse", justifyContent: "flex-start"}} disableSpacing>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!valid && !isSubmitting}
                    onClick={handleSubmit}
                >
                    {t('general.save')}
                </Button>
                <Button onClick={onClose} variant={"outlined"} color={"secondary"}
                        style={{marginRight: theme.spacing(1)}}>
                    {t('general.cancel')}
                </Button>
            </DialogActions>
        </>;
    }


    function errorContent() {
        return <>
            <DialogContent>
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography>{t('topBar.changePassword.error')}</Typography>
                    <Typography>{error}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setError(null)}>
                    {t('general.ok')}
                </Button>
            </DialogActions>
        </>
    }

    function successContent() {
        return <>
            <DialogContent>
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography>{t('topBar.changePassword.success')}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('general.ok')}
                </Button>
            </DialogActions>
        </>
    }

    function loadingContent() {
        return <>
            <DialogContent>
                <Box display={"flex"} justifyContent={"center"} py={4}>
                    <CircularProgress color={"secondary"}/>
                </Box>
            </DialogContent>
        </>
    }

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('topBar.changePassword.title')}</DialogTitle>
            {success ? successContent() : error ? errorContent() : isSubmitting ? loadingContent() : inputContent()}
        </Dialog>
    )
}
