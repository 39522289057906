import React from "react";
import {IntegrationBrowser} from "../IntegrationBrowser";
import BuildagilApi from "../../../api/buildagilApi";
import { BuildagilConfig } from "../../../common/integrationConfig";

export function BuildagilBrowser({projectId, onModelAdded}) {

    async function fetchRootNodes(integration) {
        const response = await BuildagilApi.getModels(integration.externalProjectId);
        return response.map(item => ({
            id: item.guid,
            name: item.filename,
            selected: false,
            disabled: integration.models.find(model => model.externalId === item.guid),
            isLeaf: true,
        }));
    }

    return (
        <IntegrationBrowser projectId={projectId}
                            integrationConfig={BuildagilConfig}
                            onModelAdded={onModelAdded}
                            fetchRootNodes={fetchRootNodes}/>
    )
}
