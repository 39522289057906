import React, {useEffect, useState} from "react";
import {useAppContext} from "../common/context";
import VrexApi from "../api/vrexApi";
import {Box, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import List from "@mui/material/List";
import {PeopleOutlined} from "@mui/icons-material";
import {MenuButton} from "../component/MenuButton";
import OrganizationUsers from "./OrganizationUsers";
import {useTranslation} from "react-i18next";


export function Organization({organizationId}) {
    const [organization, setOrganization] = useState(null);
    const {isAuthenticated, setToast} = useAppContext();
    const theme = useTheme();
    const {t} = useTranslation();

    useEffect(() => {
        async function onLoad() {
            try {
                setOrganization(null);
                const loadedOrg = await VrexApi.getOrganization(organizationId);
                setOrganization(loadedOrg);
            } catch (e) {
                setToast({message: t('organization.error'), error: e, severity: "error"})
            }
        }

        if (!isAuthenticated) {
            return;
        }
        if (!organizationId) {
            return;
        }
        onLoad().then();
    }, [organizationId, isAuthenticated, setToast, t]);

    return (
        <Grid container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              style={{flexGrow: '1'}}>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}
                  style={{backgroundColor: theme.palette.background.default}}>
                <Box pt={5}>
                    <List>
                        <MenuButton name={t('organization.users.title')}
                                    path={`/organizations/${organizationId}/users`}>
                            <PeopleOutlined style={{color: theme.palette.text.secondary}}/>
                        </MenuButton>
                    </List>
                </Box>
            </Grid>
            <OrganizationUsers organization={organization}/>
        </Grid>
    )
}

