import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import React from "react";

export default function MainPage({title, ...props}) {

    return (
        <Box flexGrow={1} display={"flex"} flexDirection={"column"}>
            <Typography variant={"h6"}>
                <Box
                    display={"flex"}
                    alignItems="center"
                    bgcolor="primary.main"
                    px={4}
                    height={"48px"}
                    color="primary.contrastText">
                    {title}
                </Box>
            </Typography>
            {props.children}
        </Box>);

}
