import React from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";
import MainPage from "../component/MainPage";
import {useAppContext} from "../common/context";

export default function Downloads() {
    const theme = useTheme();
    const {t} = useTranslation();
    const {vrexUrl, nwPluginUrl} = useAppContext()

    return (
        <MainPage title={t('downloads.title')}>
            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  style={{flexGrow: '1'}}>
                <Grid item xs={12} sm={10} md={7} lg={7} xl={5}
                      style={{
                          paddingLeft: "6vw",
                          paddingRight: "6vw",
                          paddingTop: theme.spacing(4),
                          backgroundColor: theme.palette.background.list
                      }}>
                    <Box display={"flex"} flexDirection={"column"} py={4}>
                        <Box bgcolor={"background.paper"} p={2} my={2} boxShadow={1}>
                            <Typography color={"textPrimary"} variant={"h6"}
                                        style={{marginBottom: theme.spacing(2)}}>
                                {t('downloads.vrexViewer.title')}
                            </Typography>
                            <Typography color={"textPrimary"} variant={"body2"}
                                        style={{marginBottom: theme.spacing(2)}}>
                                {t('downloads.vrexViewer.description')}
                            </Typography>
                            <Link href={vrexUrl} target="_blank" variant={"body2"}
                                  underline="hover"> {t('downloads.vrexViewer.link')}</Link>
                        </Box>
                        <Box bgcolor={"background.paper"} p={2} my={2} boxShadow={1}>
                            <Typography color={"textPrimary"} variant={"h6"}
                                        style={{marginBottom: theme.spacing(2)}}>
                                {t('downloads.nwPlugin.title')}
                            </Typography>
                            <Typography color={"textPrimary"} variant={"body2"}
                                        style={{marginBottom: theme.spacing(2)}}>
                                {t('downloads.nwPlugin.description')}
                            </Typography>
                            <Link href={nwPluginUrl} variant={"body2"} target="_blank"
                                  underline="hover">{t('downloads.nwPlugin.link')}</Link>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </MainPage>
    );
}
