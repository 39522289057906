import React, {useCallback, useEffect, useState} from 'react';
import {useAppContext} from "../common/context";
import VrexApi from "../api/vrexApi";
import Typography from "@mui/material/Typography";
import {CreateProjectDialog} from "../component/CreateProjectDialog";
import {Box, FormControlLabel, Grid, Link, ListItemButton, ListItemText, Switch} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import {useTheme} from '@mui/material/styles';
import {Link as RouterLink} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {HelpText} from "../component/HelpText";
import MainPage from "../component/MainPage";
import {Roles} from "../common/util";
import SearchField from "../component/SearchField";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -theme.spacing(2),
        marginBottom: -theme.spacing(2),
    }
}));


export default function Projects() {
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const {isAuthenticated, user, setToast} = useAppContext();
    const [showAll, setShowAll] = useState(false);
    const theme = useTheme();
    const classes = useStyles();
    const {t} = useTranslation();

    const loadProjects = useCallback(async (showAll) => {
        async function loadProjects() {
            try {
                return await VrexApi.getProjects(showAll)
            } catch (e) {
                setToast({message: t('projects.error'), error: e, severity: "error"})
                return [];
            }
        }

        const p = await loadProjects();
        setProjects(p);
        setLoading(false);
    }, [setToast, t]);

    useEffect(() => {
        const words = filter.split(" ")
        let filteredProjects = projects;
        words.forEach((word) => filteredProjects = filteredProjects.filter(p => (p.organization.name + " " + p.name).toLowerCase().includes(word.trim().toLowerCase())))
        setFilteredProjects(filteredProjects);
    }, [projects, filter])

    useEffect(() => {
        function filterOrganizations() {
            if (!user?.organizations) {
                return [];
            }
            return user.organizations.filter(org => org.role.accessLevel >= Roles.CREATOR.accessLevel);
        }

        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            let lp = loadProjects(showAll)
            await lp;
        }

        onLoad().then();
        setOrganizations(filterOrganizations());
    }, [isAuthenticated, loadProjects, showAll, user]);

    function renderProjects() {
        return filteredProjects.map((project, i) =>
            <Box key={i} clone bgcolor={"background.paper"} mb={2} boxShadow={1} color={"text.secondary"}>
                <ListItemButton
                    component={RouterLink}
                    to={"/projects/" + project.id + "/"}>
                    <ListItemText
                        primary={project.name}
                        primaryTypographyProps={{color: "textPrimary"}}
                        secondary={project.organization.name}
                    />
                </ListItemButton>
            </Box>
        )
    }

    function renderContents() {
        if (user?.organizations.length === 0) {
            return (
                <Box>
                    <Typography variant={"body2"}
                                color={"textPrimary"}>{t('projects.description.noProjects.main')}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"} style={{marginTop: theme.spacing(2)}}>
                        <Trans i18nKey={"projects.description.noProjects.noOrg.help"}>
                            {"Go to the "}<Link component={RouterLink} to={"/help"}
                                                underline="hover">{"Help page"}</Link>
                            {" to learn more about how to get started."}
                        </Trans>
                    </Typography>
                </Box>
            );
        }
        if (projects.length === 0 && organizations.length === 0) {
            return (
                <Box>
                    <Typography variant={"body2"}
                                color={"textPrimary"}>{t('projects.description.noProjects.main')}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"} style={{marginTop: theme.spacing(2)}}>
                        {t("projects.description.noProjects.canNotCreate.help1")}
                    </Typography>
                    <Typography variant={"body2"} color={"textSecondary"} style={{marginTop: theme.spacing(2)}}>
                        <Trans i18nKey={"projects.description.noProjects.canNotCreate.help2"}>
                            {"If your are unsure about who your account admin is, go to "}
                            <Link component={RouterLink} to={"/organizations"}
                                  underline="hover">{"Organizations"}</Link>{"."}
                        </Trans>
                    </Typography>
                </Box>
            );
        }
        if (projects.length === 0 && organizations.some(org => org.role.accessLevel >= Roles.ADMIN.accessLevel)) {
            return (
                <Box>
                    <Typography variant={"body2"}
                                color={"textPrimary"}>{t('projects.description.noProjects.main')}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"} style={{marginTop: theme.spacing(2)}}>
                        {showAll ?
                            <Trans i18nKey={t("projects.description.noProjects.isAdmin.help")}>
                                {"Your organization have no projects. Start by creating a new one."}
                            </Trans> :
                            <Trans i18nKey={t("projects.description.noProjects.isAdmin.help")}>
                                {"Start by creating a new project, or "}
                                <Link
                                    component="button"
                                    variant={"body2"}
                                    style={{verticalAlign: "inherit"}}
                                    onClick={() => setShowAll(true)}
                                    underline="hover">{"enable Show All"}</Link>
                                {" to see all projects in your organization."}
                            </Trans>
                        }
                    </Typography>
                </Box>
            );
        }
        if (projects.length === 0 && organizations.length > 0) {
            return (
                <Box>
                    <Typography variant={"body2"}
                                color={"textPrimary"}>{t('projects.description.noProjects.main')}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"} style={{marginTop: theme.spacing(2)}}>
                        {t("projects.description.noProjects.canCreate.help1")}
                    </Typography>
                    <Typography variant={"body2"} color={"textSecondary"} style={{marginTop: theme.spacing(2)}}>
                        <Trans i18nKey={"projects.description.noProjects.canCreate.help2"}>
                            {"If your are unsure about who your account admin is, go to "}
                            <Link component={RouterLink} to={"/organizations"}
                                  underline="hover">{"Organizations"}</Link>{"."}
                        </Trans>
                    </Typography>
                </Box>
            );
        }
        return (
            <List>
                {renderProjects()}
            </List>
        )
    }

    return (
        <MainPage title={t('projects.title')}>
            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  style={{flexGrow: '1'}}>
                <Grid item xs={12} sm={10} md={7} lg={7} xl={5}
                      style={{
                          paddingLeft: "6vw",
                          paddingRight: "6vw",
                          paddingTop: theme.spacing(4),
                          backgroundColor: theme.palette.background.list
                      }}>
                    <CreateProjectDialog organizations={organizations}/>

                    {!loading &&
                        <Box display={"flex"} mt={1} flexDirection={"column"}>
                            <Box display={"flex"} flexDirection={"row-reverse"} alignItems={"center"}>
                                {organizations.some(org => org.role.accessLevel >= Roles.ADMIN.accessLevel) &&
                                    <FormControlLabel
                                        control={<Switch checked={showAll}
                                                         classes={{root: classes.root}}
                                                         color={"primary"}
                                                         onChange={() => setShowAll(!showAll)}
                                                         name="showAdmin"/>}
                                        label={
                                            <Box display={"flex"} alignItems={"center"}>
                                                <Typography variant={"body2"}
                                                            color={"textSecondary"}>
                                                    {t('projects.showAll.title')}</Typography>
                                                <HelpText text={t('projects.showAll.description')}/>
                                            </Box>}
                                        labelPlacement={"start"}
                                        color={"textSecondary"}
                                    />}
                                {projects.length > 0 &&
                                    <SearchField value={filter} flexGrow={1}
                                                 onChange={(event) => setFilter(event.target.value)}/>
                                }
                            </Box>
                            {renderContents()}
                        </Box>
                    }
                </Grid>
            </Grid>
        </MainPage>
    )
}
