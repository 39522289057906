import React from "react";
import {IntegrationBrowser} from "../IntegrationBrowser";
import BimplusApi from "../../../api/bimplusApi";
import {BimplusConfig} from "../../../common/integrationConfig";

export function BimplusBrowser({projectId, onModelAdded}) {

    async function fetchRootNodes(integration) {
        const response = await BimplusApi.getModels(integration.externalData, integration.externalProjectId);
        return response
            .map(item => ({
                id: item.id,
                name: item.importFileName ? item.importFileName : item.name,
                selected: false,
                disabled: integration.models.find(model => model.externalId === item.id) || item.inputType !== "IFC_IMPORT",
                isLeaf: true,
            }));
    }

    return (
        <IntegrationBrowser projectId={projectId} onModelAdded={onModelAdded}
                            integrationConfig={BimplusConfig} fetchRootNodes={fetchRootNodes}/>
    )
}
