import Typography from "@mui/material/Typography";
import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import {Bim360AddIntegration} from "./integrations/bim360/Bim360AddIntegration";
import {BimsyncAddIntegration} from "./integrations/bimsync/BimsyncAddIntegration";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import NAVISWORKS_IMAGE from "../images/navisworks.png";
import ProjectPage from "../component/ProjectPage";
import {GrainOutlined} from "@mui/icons-material";
import {AddLocalSource} from "./AddLocalSource";
import {AddLocalConvertedSource} from "./AddLocalConvertedSource";
import {useTranslation} from "react-i18next";
import {BimtrackAddIntegration} from "./integrations/bimtrack/BimtrackAddIntegration";
import {StreamBimAddIntegration} from "./integrations/streambim/StreamBimAddIntegration";
import {AconexAddIntegration} from "./integrations/aconex/AconexAddIntegration";
import {LocalSource} from "../component/Icons";
import {BuildagilAddIntegration} from "./integrations/buildagil/BuildagilAddIntegration";
import {
    AconexConfig,
    BimsyncConfig,
    Bim360Config,
    BimtrackConfig,
    StreambimConfig,
    BuildagilConfig,
    BimplusConfig
} from "../common/integrationConfig";
import {BimplusAddIntegration} from "./integrations/bimplus/BimplusAddIntegration";

export default function AddSource({projectId, onModelSourceAdded}) {
    const {t} = useTranslation();

    function getModelSourceItem(key, to, icon, name, desc) {
        return (
            <Box key={key} clone bgcolor={"background.paper"} my={2} boxShadow={1}
            >
                <ListItemButton
                    component={Link}
                    to={to}
                >
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={name} secondary={desc}/>
                </ListItemButton>
            </Box>
        );
    }

    const getIntegrationItem = (key, integration) =>
        getModelSourceItem(
            key,
            `/projects/${projectId}/models/add-source/${integration.slug}`,
            <img src={integration.icon} alt={""} />,
            integration.name,
            t(`project.models.${integration.slug}.add.description`)
        );

    return (
        <ProjectPage>
            <Box display={"flex"} flexDirection={"column"} py={4}>
                <Routes>
                    <Route path={""} element={
                        <React.Fragment>
                            <Typography variant={"body2"}
                                        color={"textSecondary"}>{t('project.models.modelSource.add.description')}</Typography>
                            <List>
                                {getModelSourceItem(1, `/projects/${projectId}/models/add-source/local-vrx`,
                                    <LocalSource/>
                                    , t('project.models.local.vrx.add.title'), t('project.models.local.vrx.add.description'))}
                                {getModelSourceItem(2, `/projects/${projectId}/models/add-source/local-vrp`,
                                    <GrainOutlined/>, t('project.models.local.vrp.add.title'), t('project.models.local.vrp.add.description'))}
                                {getIntegrationItem(3, AconexConfig)}
                                {getIntegrationItem(4, Bim360Config)}
                                {getIntegrationItem(5, BimsyncConfig)}
                                {getIntegrationItem(6, BimtrackConfig)}
                                {getIntegrationItem(7, StreambimConfig)}
                                {getIntegrationItem(8, BuildagilConfig)}
                                {getIntegrationItem(9, BimplusConfig)}
                                {getModelSourceItem(10, `/projects/${projectId}/models/add-source/plugin`,
                                    <img src={NAVISWORKS_IMAGE} alt={""}/>, "Navisworks",
                                    t('project.models.localConverted.navisworks.add.description'))}
                            </List>
                        </React.Fragment>
                    }/>
                    <Route path={AconexConfig.slug}
                           element={<AconexAddIntegration projectId={projectId}
                                                          onIntegrationAdded={onModelSourceAdded}/>}/>
                    <Route path={Bim360Config.slug}
                           element={<Bim360AddIntegration projectId={projectId}
                                                          onIntegrationAdded={onModelSourceAdded}/>}/>
                    <Route path={BimsyncConfig.slug}
                           element={<BimsyncAddIntegration projectId={projectId}
                                                           onIntegrationAdded={onModelSourceAdded}/>}/>
                    <Route path={BimtrackConfig.slug}
                           element={<BimtrackAddIntegration projectId={projectId}
                                                            onIntegrationAdded={onModelSourceAdded}/>}/>
                    <Route path={StreambimConfig.slug}
                           element={<StreamBimAddIntegration projectId={projectId}
                                                             onIntegrationAdded={onModelSourceAdded}/>}/>
                    <Route path={BuildagilConfig.slug}
                           element={<BuildagilAddIntegration projectId={projectId}
                                                             onIntegrationAdded={onModelSourceAdded}/>}/>
                    <Route path={BimplusConfig.slug}
                        element={<BimplusAddIntegration projectId={projectId}
                                                        onIntegrationAdded={onModelSourceAdded} />} />

                    <Route path={"local-vrx"}
                           element={<AddLocalSource projectId={projectId}
                                                    type={"VRX"}
                                                    onModelSourceAdded={onModelSourceAdded}/>}/>
                    <Route path={"local-vrp"}
                           element={<AddLocalSource projectId={projectId}
                                                    type={"VRP"}
                                                    title={t('project.models.local.vrp.add.dialog.title')}
                                                    description={t('project.models.local.vrp.add.dialog.description')}
                                                    defaultSourceName={t('project.models.local.vrp.add.dialog.defaultName')}
                                                    onModelSourceAdded={onModelSourceAdded}/>}/>
                    <Route path={"plugin"}
                           element={<AddLocalConvertedSource projectId={projectId}
                                                             onModelSourceAdded={onModelSourceAdded}/>}/>
                </Routes>
            </Box>
        </ProjectPage>
    )
}
