import {
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {
    Circle,
    CircleOutlined,
    ErrorOutlined,
    GrainOutlined,
    HighlightOffOutlined,
    InsertDriveFileOutlined,
    SettingsSuggestOutlined,
    WarningOutlined
} from "@mui/icons-material";
import {ConfirmedAction} from "./ConfirmedAction";
import {useTranslation} from "react-i18next";
import {tableStyles} from "../common/sharedStyles";
import {LocalSource, VR} from "./Icons";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {HelpText} from "./HelpText";
import {getName} from "../common/integrationConfig";


const compare = (a, b, orderBy, converters) => {
    switch (orderBy) {
        case "type":
            return compareType(a.type, b.type);
        case "status":
            return getStatusPriority(a.status) - getStatusPriority(b.status);
        case "version":
            return getVersionPriority(a, converters) - getVersionPriority(b, converters);
        case "completed":
            return compareDate(a.completed, b.completed);
        default:
            return a.name.localeCompare(b.name);

    }
}

const compareDate = (a, b) => {
    if (!a && !b)
        return 0;
    if (a && !b)
        return -1;
    if (!a)
        return 1;
    return new Date(a) - new Date(b);
}

const compareType = (a, b) => {
    if (!a && !b)
        return 0;
    if (a && !b)
        return -1;
    if (!a)
        return 1;
    return a.localeCompare(b);
}

const getStatusPriority = (status) => {
    switch (status) {
        case "FAILED":
            return 2;
        case "COMPLETED":
            return 0;
        default:
            return 1;

    }
}

const getVersionPriority = (model, converters) => {
    if (model.completed) {
        let converter = converters.find(converter => converter.name === model.converter.name);
        if (!converter || converter.currentVersion === model.converter.version) {
            return 0;
        }
        if (converter.supportedVersions.includes(model.converter.version)) {
            return 1;
        }
        return 3;
    }
    return 2;
}

export function ModelTable({models, converters, modelSourceType, onDeleteModels, onDeleteModel, disabledDelete}) {
    const classes = tableStyles();
    const {t} = useTranslation();
    const [orderBy, setOrderBy] = useState("name");
    const [order, setOrder] = useState('desc');
    const [sortedModels, setSortedModels] = useState([]);
    const theme = useTheme();
    const animations = useRef([]);

    function syncAnimation(event) {
        for (let a of event.target.getAnimations()) {
            animations.current.push(a);
            if (animations.current[0].currentTime === null) {
                animations.current = animations.current.filter(an => an.currentTime !== null);
            }
            a.currentTime = animations.current[0].currentTime;
        }
    }

    function handleSort(id) {
        const isAsc = orderBy === id && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
    }

    useEffect(() => {
        if (!models) {
            setSortedModels([]);
            return;
        }
        if (order === 'desc') {
            models.sort((a, b) => -compare(a, b, orderBy, converters))
        } else {
            models.sort((a, b) => compare(a, b, orderBy, converters))
        }
        setSortedModels([...models]);
    }, [models, order, orderBy, converters])

    const headerCells = [
        {
            id: "type",
            label: <InsertDriveFileOutlined fontSize={"small"}/>,
            width: "60px",
            minWidth: "40px",
            align: "left"
        },
        {
            id: "name",
            label: t('project.models.modelSource.model.name'),
            width: "auto",
            minWidth: "160px",
            align: "left"
        },
        {
            id: "status",
            label: <Box display={"flex"} alignItems={"center"}><SettingsSuggestOutlined fontSize={"small"}/><HelpText
                text={t('project.models.modelSource.model.status.description')}/></Box>,
            width: "88px",
            minWidth: "40px",
            align: "center"
        },
        {
            id: "version",
            label: <Box display={"flex"} alignItems={"center"}><VR fontSize={"small"}/><HelpText
                text={t('project.models.modelSource.model.version.description')}/></Box>,
            width: "88px",
            minWidth: "40px",
            align: "center"
        },
        {
            id: "completed",
            label: t('project.models.modelSource.model.lastProcessed.title'),
            width: "125px",
            minWidth: "45px",
            align: "right"
        }
    ]

    return (
        <TableContainer className={classes.tableContainer} sx={{maxHeight: "600px"}}>
            <Table size="small" aria-label="model table" stickyHeader style={{maxWidth: "100%"}}>
                <TableHead>
                    <TableRow>
                        {headerCells.map(headerCell => (
                            <TableCell
                                key={headerCell.id}
                                className={classes.tableCell}
                                align={headerCell.align}
                                sx={{
                                    minWidth: headerCell.minWidth,
                                    width: headerCell.width,
                                    maxWidth: headerCell.width,
                                    background: theme.palette.background.paper
                                }}>
                                <TableSortLabel
                                    active={headerCell.id === orderBy}
                                    direction={order}
                                    onClick={() => handleSort(headerCell.id)}
                                    sx={{flexDirection: headerCell.align === "left" ? "row" : "row-reverse"}}>
                                    {headerCell.label}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                        <TableCell className={`${classes.tableCell} ${classes.iconCell}`}
                                   align="right" width={"50px"}
                                   sx={{background: theme.palette.background.paper}}>
                            <ConfirmedAction title={t('project.models.modelSource.models.delete.dialog.title')}
                                             description={t('project.models.modelSource.models.delete.dialog.description')}
                                             tooltip={t('project.models.modelSource.models.delete.tooltip')}
                                             onConfirmed={onDeleteModels}>
                                <IconButton size={"small"} color={"secondary"} disabled={disabledDelete}>
                                    <HighlightOffOutlined/>
                                </IconButton>
                            </ConfirmedAction>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedModels.map((model) => {
                        return (
                            <ModelRow key={"m_" + model.id} model={model}
                                      converters={converters}
                                      modelSourceType={modelSourceType}
                                      disabled={disabledDelete}
                                      onDeleteModel={onDeleteModel}
                                      syncAnimation={syncAnimation}/>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


const smallIconSize = 10;

const largeIconSize = 18;

function ModelRow({disabled, model, converters, modelSourceType, onDeleteModel, syncAnimation}) {
    const classes = tableStyles();
    const {t} = useTranslation();
    const theme = useTheme();
    const tooltipPlacement = "right-start";
    const integrationName = getName(modelSourceType);

    function getFailedReasonTooltip() {
        switch (model.failedReason) {
            case "NO_GEOMETRY":
                return t('project.models.modelSource.model.status.failed.noGeometry');
            case "MODEL_NOT_FOUND":
                return t('project.models.modelSource.model.status.failed.modelNotFound', {integrationName: integrationName});
            case "ACCESS_DENIED":
                return t('project.models.modelSource.model.status.failed.accessDenied', {integrationName: integrationName});
            case "INTEGRATION_ERROR":
                return t('project.models.modelSource.model.status.failed.integrationError', {integrationName: integrationName});
            case "UNAUTHORIZED":
                return t('project.models.modelSource.model.status.failed.unauthorized', {integrationName: integrationName});
            default:
                return t('project.models.modelSource.model.status.failed.internalError');
        }
    }

    function getProcessingStatus() {
        if (model.status === "COMPLETED") {
            return "";
        }
        if (model.status === "FAILED") {
            return (
                <Tooltip title={getFailedReasonTooltip()} placement={tooltipPlacement}>
                    <ErrorOutlined
                        sx={{fontSize: largeIconSize, color: theme.palette.error.main, verticalAlign: "middle"}}/>
                </Tooltip>
            )
        }
        return (
            <Tooltip title={t('project.models.modelSource.model.status.processing')} placement={tooltipPlacement}>
                <CircularProgress onAnimationStart={syncAnimation}
                                  color={"secondary"} size={largeIconSize}
                                  sx={{verticalAlign: "middle"}}/>
            </Tooltip>
        )
    }

    function getVersionStatus() {
        if (model.completed) {
            if (!model.converter) {
                return (
                    <Tooltip title={t('project.models.modelSource.model.version.notFound')}
                             placement={tooltipPlacement}>
                        <ErrorOutlined
                            sx={{fontSize: largeIconSize, color: theme.palette.error.main, verticalAlign: "middle"}}/>
                    </Tooltip>
                )
            }
            let converter = converters.find(converter => converter.name === model.converter.name);
            if (!converter || converter.currentVersion === model.converter.version) {
                return (
                    <Tooltip title={t('project.models.modelSource.model.version.ok')} placement={tooltipPlacement}>
                        <Circle
                            sx={{fontSize: smallIconSize, color: theme.palette.success.main, verticalAlign: "middle"}}/>
                    </Tooltip>
                );
            }
            if (converter.supportedVersions.includes(model.converter.version)) {
                return (
                    <Tooltip title={t('project.models.modelSource.model.version.supported')}
                             placement={tooltipPlacement}>
                        <WarningOutlined
                            sx={{fontSize: largeIconSize, color: theme.palette.warning.main, verticalAlign: "middle"}}/>
                    </Tooltip>
                );
            }
            return (
                <Tooltip title={t('project.models.modelSource.model.version.outdated')} placement={tooltipPlacement}>
                    <ErrorOutlined
                        sx={{fontSize: largeIconSize, color: theme.palette.error.main, verticalAlign: "middle"}}/>
                </Tooltip>
            );
        }
        return (
            <Tooltip title={t('project.models.modelSource.model.version.notCompleted')} placement={tooltipPlacement}>
                <CircleOutlined sx={{fontSize: smallIconSize, verticalAlign: "middle"}}/>
            </Tooltip>
        );
    }

    function getTypeIcon(type) {
        switch (type) {
            case "VRX":
                return <LocalSource sx={{fontSize: largeIconSize, verticalAlign: "middle"}}/>;
            case "VRP":
                return <GrainOutlined sx={{fontSize: largeIconSize, verticalAlign: "middle"}}/>;
            default:
                return "";
        }
    }

    return (
        <TableRow className={classes.tableDataRow}>
            <TableCell className={`${classes.tableCell} ${classes.iconCell}`}>
                {getTypeIcon(model.type)}
            </TableCell>
            <TableCell className={classes.tableCell} align={"left"}>{model.name}</TableCell>
            <TableCell className={`${classes.tableCell} ${classes.iconCell}`}
                       align={"center"}>{getProcessingStatus()}</TableCell>
            <TableCell className={`${classes.tableCell} ${classes.iconCell}`}
                       align={"center"}>{getVersionStatus()}</TableCell>
            <TableCell align={"right"}
                       className={classes.tableCell}>{model.completed ? new Date(model.completed).toLocaleString([], {
                dateStyle: 'short',
                timeStyle: 'short'
            }) : ""}</TableCell>
            <TableCell className={`${classes.tableCell} ${classes.iconCell}`} align="center">
                <ConfirmedAction title={t('project.models.modelSource.model.delete.dialog.title')}
                                 description={t('project.models.modelSource.model.delete.dialog.description', {name: model.name})}
                                 onConfirmed={() => onDeleteModel(model.modelSourceId, model.id)}>
                    <IconButton color={"secondary"} disabled={disabled} sx={{padding: "4px"}}>
                        <HighlightOffOutlined sx={{fontSize: largeIconSize}}/>
                    </IconButton>
                </ConfirmedAction>
            </TableCell>
        </TableRow>)
}

