import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";
import {Box, Switch, Typography} from "@mui/material";
import {HelpText} from "./HelpText";
import {useTheme} from "@mui/material/styles";
import VrexApi from "../api/vrexApi";
import {useAppContext} from "../common/context";
import {ReportProblemOutlined} from "@mui/icons-material";
import {Capacity} from "../common/util";

export function ProjectStatus({project, handleChange}) {
    const [open, setOpen] = useState(false);
    const [organization, setOrganization] = useState(null);
    const {t} = useTranslation();
    const theme = useTheme();
    const {isAuthenticated, setToast} = useAppContext();


    useEffect(() => {
        async function loadOrganization() {
            try {
                let org = await VrexApi.getOrganization(project.organization.id);
                setOrganization(org);
            } catch (e) {
                setToast({message: t('project.settings.status.error'), error: e, severity: "error"})
            }
        }

        if (open && isAuthenticated) {
            loadOrganization().then();
        }
    }, [isAuthenticated, open, project.organization.id, setToast, t]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmed = () => {
        setOpen(false);
        handleChange();
    }
    let disabled = project.status === "INACTIVE" && organization && organization.activeProjectCount >= organization.capacity.projects
    return (
        <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
            <Typography variant={"h6"}
                        color={"textPrimary"}
                        style={{marginBottom: theme.spacing(2)}}>{t('project.settings.status.title')}<HelpText
                text={t('project.settings.status.description')}/></Typography>
            <Box display={"flex"} alignItems={"center"}>
                <Typography>{t('project.settings.status.inactive')}</Typography>

                <Switch color={"primary"} checked={project.status === "ACTIVE"} onChange={handleClickOpen}/>
                <Typography>{t('project.settings.status.active')}</Typography>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{project.status === "ACTIVE" ?
                    t('project.settings.status.dialog.active.title') :
                    t('project.settings.status.dialog.inactive.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box display={"flex"} marginBottom={2} flexDirection={"column"}>
                            { organization && organization.capacity.projects < Capacity.UNLIMITED &&
                            <Typography variant={"body2"} style={{marginBottom: theme.spacing(1)}}>
                                {t('projects.create.projectCount', {
                                    max: organization.capacity.projects,
                                    current: organization.activeProjectCount
                                })}
                            </Typography>
                            }
                            {disabled ?
                                <Box display={"flex"} alignItems={"center"}>
                                    <ReportProblemOutlined style={{marginRight: theme.spacing(2)}}/>
                                    <Typography variant={"body2"}>{t('projects.create.limitReached')}</Typography>
                                </Box>
                                :
                                <Typography variant={"body2"}>{project.status === "ACTIVE" ?
                                    t('project.settings.status.dialog.active.description') :
                                    t('project.settings.status.dialog.inactive.description')
                                }</Typography>
                            }
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={"outlined"} color="secondary" autoFocus>
                        NO
                    </Button>
                    <Button onClick={handleConfirmed} variant={"contained"} color="primary" disabled={disabled}>
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
