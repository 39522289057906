import {SearchOutlined} from "@mui/icons-material";
import React from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import styled from '@emotion/styled'

const Input = styled.input`
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  color: ${props => props.theme.palette.text.secondary};
  font: ${props => props.theme.typography.body2};

  ::placeholder {
    color: ${props => props.theme.palette.text.disabled};
  }
`

const InputContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: inline-flex;
  &:after {
    border-bottom: 1px solid ${props => props.theme.palette.text.secondary};
    left: 0;
    bottom: 0;
    right: 0;
    content: " ";
    position: absolute;
  }
  &:hover::after {
    border-bottom: 2px solid ${props => props.theme.palette.text.secondary};
  }
  &:focus-within::after {
    border-bottom: 2px solid ${props => props.theme.palette.text.secondary};
  }
`

export default function SearchField({value, onChange, ...props}) {
    const theme = useTheme();
    return (
        <Box display={"flex"} alignItems={"center"} {...props}>
            <SearchOutlined sx={{color: theme.palette.text.secondary}}/>
            <InputContainer theme={theme}>
                <Input theme={theme} placeholder={"Search"} value={value} onChange={onChange}/>
            </InputContainer>
        </Box>
    )
}
