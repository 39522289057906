import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import {Box} from "@mui/material";
import LoaderButton from "./LoaderButton";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

export function RoomDialog({
                               open,
                               title,
                               validateRoomName,
                               onSubmit,
                               onClose,
                               initialName = "",
                               initialDescription = "",
                               submitText,
                           }) {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({
        name: "",
        description: ""
    });
    const {t} = useTranslation();

    useEffect(() => {
        if (open) {
            setFields({
                name: initialName,
                description: initialDescription,
            })
        }
    }, [initialDescription, initialName, open]);

    function handleFieldChange(event) {
        setFields({
            ...fields,
            [event.target.name]: event.target.value
        });
    }

    function validateForm() {
        return fields.name.length > 0 && (fields.name !== initialName || fields.description !== initialDescription) && validateRoomName(fields.name);
    }

    async function handleSubmit() {
        setIsLoading(true);
        await onSubmit({name: fields.name, description: fields.description});
        setIsLoading(false);
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <form>
                    <Box display={"flex"} flexDirection={"column"}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            required
                            label={t('project.rooms.dialog.name')}
                            name="name"
                            type="name"
                            autoFocus
                            defaultValue={fields.name}
                            onChange={handleFieldChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="dense"
                            multiline
                            maxRows={4}
                            label={t('project.rooms.dialog.description')}
                            name="description"
                            type="description"
                            defaultValue={fields.description}
                            onChange={handleFieldChange}
                        />
                    </Box>
                </form>
            </DialogContent>
            <DialogActions style={{flexDirection: "row-reverse", justifyContent: "flex-start"}} disableSpacing>
                <LoaderButton
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                    onClick={handleSubmit}
                >
                    {submitText}
                </LoaderButton>
                <Button color={"secondary"} variant={"outlined"} onClick={onClose}  style={{marginRight: theme.spacing(1)}}>
                    {t('general.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
