import React from 'react';
import {IconButton, Popover, Typography} from "@mui/material";
import {Help} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";


export function HelpText({text, element}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const handlePopoverOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton color={"primary"} size={"small"} onClick={handlePopoverOpen} style={{}}><Help
                style={{fontSize: 16}}/></IconButton>
            <Popover open={open}
                     anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: 'center',
                         horizontal: 'right',
                     }}
                     transformOrigin={{
                         vertical: 'center',
                         horizontal: 'left',
                     }}
                     PaperProps={{
                         style: {width: '100%', maxWidth: '300px'},
                     }}
                     onClose={handlePopoverClose}
                     onClick={event => event.stopPropagation()}>
                {text ? <Typography variant={"body2"} color={"textSecondary"}
                                    style={{padding: theme.spacing(1)}}>{text}</Typography> : element}

            </Popover>
        </React.Fragment>)
}

export function HelpTextButton({title, helpText, ...props}) {

    return (
        <Box ml={1} mr={1}>
            <Button {...props}
                    color={"secondary"}
                    style={{
                        textTransform: 'none',
                    }}>
                <Typography variant={"caption"}>{title}</Typography>

            </Button>
            <HelpText text={helpText}/>
        </Box>
    )
}


export function RoleDescriptions() {
    const {t} = useTranslation();

    function getRoleDescription(title, description) {
        return <Box mb={1}>
            <Typography variant={"subtitle2"} color={"textSecondary"} display={"inline"} style={{fontWeight: "bold"}}>
                {title}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"} display={"inline"}>
                {description}
            </Typography>
        </Box>;
    }

    return (
        <HelpText element={
            <Box p={1}>
                {getRoleDescription(t('general.users.roles.user.title'), t('general.users.roles.user.description'))}
                {getRoleDescription(t('general.users.roles.coordinator.title'), t('general.users.roles.coordinator.description'))}
                {getRoleDescription(t('general.users.roles.creator.title'), t('general.users.roles.creator.description'))}
                {getRoleDescription(t('general.users.roles.admin.title'), t('general.users.roles.admin.description'))}
            </Box>}
        />
    );
}
