import {mustBeSuccessful} from "../common/util";

async function get(url, headers = null) {
    return mustBeSuccessful(await fetch(url, {
        headers: {
            Accept: "application/json",
            ...headers,
        }
    }));
}

export class OpenApi {
    static async getConverters() {
        let response = await get(process.env.REACT_APP_OPEN_API_URL + "/converters");
        return await response.json()
    }
}
