import {get} from "./vrexAuth";
import {proxyEncode} from "../common/util";

class BuildagilApi {

    static async getProjects() {
        let response =  await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/buildagil/bcf/3.0/projects");
        return await response.json();
    }

    static async getModels(projectId) {
        let response =  await get(proxyEncode(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/buildagil/bcf/3.0/projects/" + projectId + "/documents?$filter=(file_type eq 'ifc' or file_type eq 'e57')"));
        return await response.json();
    }
}

export default BuildagilApi;
