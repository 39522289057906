import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import IconButton from "@mui/material/IconButton";
import React from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";


const useStyles = makeStyles(theme => ({
    circularButton: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        "&:hover, &.Mui-focusVisible": {backgroundColor: theme.palette.action.hover},
    },
    shadow: {
        boxShadow: theme.shadows[2]
    }
}))


export function CircularButton({
                                   pl = 0,
                                   pr = 0,
                                   spacing = 0,
                                   shadow = false,
                                   reverse = false,
                                   title = "",
                                   ...props
                               }) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Box display={"flex"} flexDirection={reverse ? "row-reverse" : "row"} alignItems={"center"} pl={pl} pr={pr}>
            <Typography color={"textSecondary"} variant={"caption"} style={{
                paddingRight: theme.spacing(reverse ? 0 : spacing),
                paddingLeft: theme.spacing(reverse ? spacing : 0),
                textTransform: "uppercase"
            }}>{title}</Typography>
            <IconButton
                className={`${classes.circularButton} ${shadow ? classes.shadow : ""}`}
                {...props}
                size="large">
                {props.children}
            </IconButton>
        </Box>
    );
}
