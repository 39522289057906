import {EmptyNode, LeafNode, Node} from "../../component/Nodes";
import {ClearOutlined, FolderOpenOutlined, FolderOutlined, SelectAllOutlined} from "@mui/icons-material";
import ProjectPage from "../../component/ProjectPage";
import {Box, CircularProgress, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQuery} from "../../common/hooks";
import {AuthError} from "../../component/AuthError";
import {useAppContext} from "../../common/context";
import HTTPError from "../../common/util";
import IntegrationsApi from "../../api/integrationsApi";
import {HelpTextButton} from "../../component/HelpText";
import VrexApi from "../../api/vrexApi";

function visibleUnselectedNodeExist(nodes) {
    for (const node of nodes) {
        if (node.isLeaf && !node.disabled && !node.selected) {
            return true;
        } else if (node.expanded) {
            if (visibleUnselectedNodeExist(node.children)) {
                return true;
            }
        }
    }
    return false;
}

function defaultFilename(model) {
    return model.name;
}

export function IntegrationBrowser({
                                       projectId,
                                       integrationConfig,
                                       integrationType,
                                       fetchRootNodes,
                                       expandNode,
                                       fetchIntegration = VrexApi.getIntegration,
                                       getFileName = defaultFilename,
                                       onModelAdded,
                                   }) {
    const theme = useTheme();
    const params = useParams();
    const [nodes, setNodes] = useState([]);
    const {setToast} = useAppContext();
    const [loading, setLoading] = useState(true);
    const [rootNodesFetched, setRootNodesFetched] = useState(false);
    const {t} = useTranslation();
    const [selectedCount, setSelectedCount] = useState(0);
    const [visibleUnselected, setVisibleUnselected] = useState(false);
    const [saving, setSaving] = useState(false);
    const query = useQuery();
    const navigate = useNavigate();
    const integrationId = parseInt(params.integrationId, 10);
    const [integration, setIntegration] = useState(null);
    const [integrationFetched, setIntegrationFetched] = useState(false);
    const image = integrationConfig.icon;
    integrationType = integrationType ?? integrationConfig.slug;

    useEffect(() => {
        async function onLoad() {
            try {
                setIntegrationFetched(true);
                let integration = await fetchIntegration(projectId, integrationId);
                setIntegration(integration);
            } catch (e) {
                setToast({message: t('project.models.integration.models.error'), error: e, severity: "error"});
            }
        }

        if (!integrationFetched && projectId && integrationId) {
            onLoad().then();
        }
    }, [fetchIntegration, integrationFetched, integrationId, projectId, setToast, t])

    useEffect(() => {
        async function onLoad() {
            try {
                setRootNodesFetched(true);
                let rootNodes = await fetchRootNodes(integration);
                setVisibleUnselected(visibleUnselectedNodeExist(rootNodes));
                setNodes(rootNodes);
                setLoading(false);
            } catch (e) {
                if (e instanceof HTTPError && e.statusCode === 401) {
                    try {
                        let response = await IntegrationsApi.authorize(integrationType, window.location.href);
                        window.location.href = response.url;
                        return;
                    } catch (e) {
                    }
                }
                setToast({message: t('project.models.integration.models.error'), error: e, severity: "error"});
            }
        }

        if (!rootNodesFetched && integration && !query.has("error")) {
            onLoad().then();
        }
    }, [fetchRootNodes, integration, integrationType, query, rootNodesFetched, setToast, t])

    function toggleSelect(node) {
        node.selected = !node.selected;
        if (node.selected) {
            setSelectedCount(selectedCount + 1);
        } else {
            setSelectedCount(selectedCount - 1);
        }
        setVisibleUnselected(visibleUnselectedNodeExist(nodes));
        setNodes([...nodes]);
    }

    function selectAll() {
        let count = selectNodes(nodes);
        setNodes([...nodes]);
        setVisibleUnselected(false);
        setSelectedCount(count + selectedCount);
    }

    function selectNodes(nodes) {
        let count = 0;
        nodes.forEach(node => {
            if (node.isLeaf && !node.selected && !node.disabled) {
                node.selected = true;
                count++;
            } else if (node.expanded) {
                count += selectNodes(node.children);
            }
            return count;
        })
        return count;
    }

    function selectNone() {
        unselectNodes(nodes);
        setVisibleUnselected(visibleUnselectedNodeExist(nodes));
        setNodes([...nodes]);
        setSelectedCount(0);
    }

    function unselectNodes(nodes) {
        nodes.forEach(node => {
            if (node.isLeaf) {
                node.selected = false;
            } else {
                unselectNodes(node.children);
            }
        })
    }

    async function toggleExpand(node) {
        if (node.loading) {
            return
        }
        if (!node.visited) {
            node.loading = true;
            node.visited = true;
            setNodes([...nodes]);
            try {
                await expandNode(node, integration);
            } catch (e) {
                if (e instanceof HTTPError && e.statusCode === 401) {
                    try {
                        let response = await IntegrationsApi.authorize(integrationType, window.location.href);
                        window.location.href = response.url;
                        return;
                    } catch (e) {
                    }
                    setToast({message: t('project.models.integration.models.error'), error: e, severity: "error"});
                }
            }
            node.loading = false
        }
        node.expanded = !node.expanded;
        setVisibleUnselected(visibleUnselectedNodeExist(nodes));
        setNodes([...nodes]);
    }

    function renderNode(node) {
        if (node.isLeaf) {
            return (
                <LeafNode key={node.id} title={node.name} selected={node.selected} disabled={node.disabled}
                          onClick={() => toggleSelect(node)}/>)
        }

        return (
            <Node key={node.id} title={node.name} icon={node.expanded ? <FolderOpenOutlined/> : <FolderOutlined/>}
                  loading={node.loading}
                  onClick={() => toggleExpand(node)}>
                {node.expanded ? renderNodes(node.children) : ""}
            </Node>
        )
    }

    function renderNodes(nodes) {
        if (nodes.length === 0) {
            return (<EmptyNode/>);
        }
        return nodes.map((node) => {
                return renderNode(node);
            }
        )
    }

    async function handleSave() {
        let models = [];

        function getSelectedModels(nodes, models) {
            nodes.forEach((node) => {
                if (node.isLeaf) {
                    if (node.selected) {
                        models.push(node);
                    }
                } else {
                    getSelectedModels(node.children, models);
                }
            })
        }

        setSaving(true);
        getSelectedModels(nodes, models);
        try {
            await saveModels(models);
            setSaving(false);
            navigate("/projects/" + projectId + "/models")
        } catch (e) {
            setSaving(false);
            setToast({message: t('project.models.integration.models.add.dialog.error'), error: e, severity: "error"})
        }
    }

    async function saveModels(models) {
        for (let newModel of models) {
            let model = await VrexApi.postIntegrationModel(projectId, integrationId, {
                externalId: newModel.id,
                name: getFileName(newModel),
            });
            onModelAdded(integrationId, model);
        }
    }

    return (
        <ProjectPage>
            <Typography variant={"body2"}
                        color={"textSecondary"}
                        style={{
                            marginTop: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}>
                {t('project.models.integration.models.add.dialog.description')}
            </Typography>
            <Box bgcolor={"background.paper"} p={2} boxShadow={1}>
                <Box display={"flex"}>
                    <img src={image} alt={""}/>
                    <Typography display='inline' variant={"h6"}>
                        <Box px={1}>
                            {integration?.name ? integration.name : <CircularProgress color={"secondary"} size={16}
                                                                                      style={{
                                                                                          marginLeft: theme.spacing(2),
                                                                                          marginTop: theme.spacing(1)
                                                                                      }}/>}
                        </Box>
                    </Typography>
                </Box>
                {query.has("error") ?
                    <AuthError backUrl={"/projects/" + projectId + "/models"}/>
                    :
                    <React.Fragment>
                        <Box pt={3} display={"flex"} alignItems={"center"}>
                            <Button color={"primary"} variant={"contained"} disabled={selectedCount <= 0 || saving}
                                    onClick={handleSave}
                                    style={{marginRight: theme.spacing(2)}}>{t('general.ok')}</Button>
                            <Button color={"secondary"} variant={"outlined"} component={Link}
                                    to={`/projects/${projectId}/models`}
                                    style={{marginRight: theme.spacing(3)}}
                                    disabled={saving}>{t('general.cancel')}</Button>
                            <Typography>{t('project.models.integration.models.add.dialog.selected', {count: selectedCount})}</Typography>
                            <HelpTextButton title={t('project.models.integration.models.add.dialog.select.all.title')}
                                            helpText={t('project.models.integration.models.add.dialog.select.all.description')}
                                            startIcon={<SelectAllOutlined/>}
                                            disabled={saving || !visibleUnselected}
                                            onClick={selectAll}/>
                            <HelpTextButton title={t('project.models.integration.models.add.dialog.select.none.title')}
                                            helpText={t('project.models.integration.models.add.dialog.select.none.description')}
                                            startIcon={<ClearOutlined/>}
                                            disabled={saving || selectedCount <= 0}
                                            onClick={selectNone}/>
                        </Box>
                        <Box py={2}>
                            {!loading && !saving &&
                                renderNodes(nodes)
                            }
                            {!loading && saving &&
                                <Box>
                                    <CircularProgress color={"secondary"} size={20}
                                                      style={{
                                                          marginLeft: theme.spacing(2),
                                                          marginTop: theme.spacing(1)
                                                      }}/>
                                    <Typography>{t('project.models.integration.models.add.dialog.saving')}</Typography>
                                </Box>
                            }
                            {loading &&
                                <CircularProgress color={"secondary"} size={20}
                                                  style={{
                                                      marginLeft: theme.spacing(2),
                                                      marginTop: theme.spacing(1)
                                                  }}/>}
                        </Box>
                    </React.Fragment>
                }

            </Box>
        </ProjectPage>
    )
}
