import {get} from "./vrexAuth"

class Bim360Api {

    static async getHubs() {
        let response =  await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bim360/project/v1/hubs");
        return await response.json();
    }

    static async getProjects(hubId) {
        let response =  await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bim360/project/v1/hubs/" + hubId + "/projects");
        return await response.json();
    }

    static async getTopFolders(hubId, projectId) {
        let response =  await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bim360/project/v1/hubs/" + hubId + "/projects/" + projectId + "/topFolders");
        return await response.json();
    }

    static async getFolderContent(projectId, folderId) {
        let response =  await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bim360/data/v1/projects/" + projectId + "/folders/" + encodeURIComponent(folderId) + "/contents");
        return await response.json();
    }
}

export default Bim360Api;
