import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Add, ReportProblemOutlined} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import LoaderButton from "./LoaderButton";
import {Box, Typography} from "@mui/material";
import {CircularButton} from "./CircularButton";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Autocomplete } from '@mui/material';
import VrexApi from "../api/vrexApi";
import {useAppContext} from "../common/context";
import {validateEmail} from "../common/util";

export function AddProjectUserDialog({
                                         onUserAdded,
                                         disabled,
                                         organizationId,
                                         projectId,
                                         currentUsers,
                                         capacity
                                     }) {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [users, setUsers] = useState([]);
    const [orgUserCount, setOrgUserCount] = useState(0);
    const {t} = useTranslation();
    const {isAuthenticated, setToast} = useAppContext();

    useEffect(() => {
        setEmail("");
    }, [open]);

    useEffect(() => {
        async function loadOrganization() {
            try {
                const org = await VrexApi.getOrganization(organizationId);
                setOrgUserCount(org.users.length);
                const users = org.users.filter(user => currentUsers.every(u => u.id !== user.id));
                setUsers(users);
            } catch (e) {
                setToast({message: t('project.users.error'), error: e, severity: "error"})
            }
        }

        if (open && isAuthenticated) {
            loadOrganization().then();
        }
    }, [open, currentUsers, isAuthenticated, organizationId, setToast, t]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function addUser(user) {
        try {
            await VrexApi.postProjectUser(projectId, user);
            onUserAdded(user);
        } catch (e) {
            setToast({message: t('project.users.add.error'), error: e, severity: "error"})
        }
    }

    async function handleAdd() {
        setIsLoading(true);
        const user = users.find(ou => ou.user.email === email);
        await addUser(user);
        setIsLoading(false);
        setOpen(false);
    }

    async function handleInvite() {
        setIsLoading(true);
        try {
            let user = await VrexApi.postOrganizationInvitation(organizationId, {
                email: email,
            });
            await addUser(user);
        } catch (e) {
            setToast({message: t("organization.users.invitation.error"), error: e, severity: "error"})
        }
        setIsLoading(false);
        setOpen(false);
    }

    let existingUser = users.some(ou => ou.user.email === email);
    let invitingNewUser = !(existingUser || !email);
    return (
        <Box display={"flex"} flexDirection={"row-reverse"}>
            <CircularButton
                disabled={disabled}
                title={t('project.users.add.title')}
                shadow
                spacing={2}
                onClick={handleClickOpen}>
                <Add/>
            </CircularButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">{t('project.users.add.dialog.title')}</DialogTitle>
                <DialogContent>
                    {capacity.projectUsers <= currentUsers.length ?
                        <Box display={"flex"} marginTop={1} marginBottom={2} alignItems={"flex-end"}>
                            <ReportProblemOutlined style={{marginRight: theme.spacing(2)}}/>
                            <Typography variant={"body2"}>{t('project.users.add.limitReached')}</Typography>
                        </Box>
                        :
                        <Box display={"flex"} flexDirection={"column"} marginTop={1}>
                            <Autocomplete
                                onInputChange={(event, newValue) => {
                                    if (validateEmail(newValue) && !currentUsers.some(ou => ou.user.email === newValue)) {
                                        setEmail(newValue);
                                    } else if (email) {
                                        setEmail("");
                                    }
                                }}
                                options={users.map((ou) => ou.user.email)}
                                selectOnFocus
                                handleHomeEndKeys
                                freeSolo
                                renderInput={(params) => <TextField {...params}
                                                                    label={t('project.users.add.dialog.label')}
                                                                    variant="outlined"/>}
                            />
                        </Box>
                    }
                    {invitingNewUser && capacity.users <= orgUserCount &&
                    <Box display={"flex"} marginTop={1} marginBottom={2} alignItems={"flex-end"}>
                        <ReportProblemOutlined style={{marginRight: theme.spacing(2)}}/>
                        <Typography
                            variant={"body2"}>{t('organization.users.invitation.dialog.capacityReached')}</Typography>
                    </Box>
                    }
                    {invitingNewUser && capacity.users > orgUserCount &&
                    <Box display={"flex"} marginTop={1} marginBottom={2} alignItems={"flex-end"}>
                        <Typography
                            variant={"body2"}>{t('project.users.add.dialog.inviteNew')}</Typography>
                    </Box>
                    }
                </DialogContent>
                <DialogActions style={{flexDirection: "row-reverse", justifyContent: "flex-start"}} disableSpacing>
                    <LoaderButton
                        variant="contained"
                        color="primary"
                        isLoading={isLoading}
                        disabled={!email || (invitingNewUser && capacity.users <= orgUserCount)}
                        onClick={existingUser ? handleAdd : handleInvite}
                    >
                        {invitingNewUser ? t('general.users.invite.submit') : t('project.users.add.dialog.submit')}
                    </LoaderButton>
                    <Button onClick={handleClose} variant={"outlined"} color={"secondary"}
                            style={{marginRight: theme.spacing(1)}}>
                        {t('general.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
