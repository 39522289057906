import React, {useEffect, useState} from 'react';
import {Box, IconButton, TextField, Typography} from "@mui/material";
import {CloseOutlined, DoneOutlined, EditOutlined} from "@mui/icons-material";

export function EditableLabel({initialText, onValueChanged, disabled, variant}) {
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [text, setText] = useState(initialText);

    useEffect(() => {
        setText(initialText)
    }, [initialText]);

    function cancel(event) {
        event.stopPropagation();
        setIsEditing(false);
        setText(initialText);
    }

    async function save(event) {
        event.stopPropagation();
        setIsEditing(false);
        if (initialText !== text) {
            setIsSaving(true);
            await onValueChanged(text);
            setIsSaving(false);
        }
    }

    return (
        <Box pl={2}>
            {disabled ?
                <Box display={"flex"} alignItems={"center"}>
                    <Box clone pr={1}><Typography>{text}</Typography></Box>
                </Box>
                :
                isEditing ?
                    <Box display={"flex"} alignItems={"center"}>
                        <TextField variant={"standard"} defaultValue={initialText} onClick={event => event.stopPropagation()}
                                   onChange={(event) => setText(event.target.value)}/>
                        <IconButton size={"small"} onClick={save}><DoneOutlined sx={{fontSize: 16}}/></IconButton>
                        <IconButton size={"small"} onClick={cancel}><CloseOutlined sx={{fontSize: 16}}/></IconButton>
                    </Box> :
                    <Box display={"flex"} alignItems={"center"}>
                        <Box clone pr={1}><Typography variant={variant}>{text}</Typography></Box>
                        <IconButton
                            size={"small"} onClick={(event) => {
                            event.stopPropagation();
                            setIsEditing(true);
                        }} disabled={isSaving}>
                            <EditOutlined sx={{fontSize: 16}}/>
                        </IconButton>
                    </Box>
            }
        </Box>)
}
