import React from "react";
import {useNavigate} from "react-router-dom";
import VrexApi from "../../../api/vrexApi";
import {AddIntegration} from "../AddIntegration";
import AconexApi from "../../../api/aconexApi";
import {AconexConfig} from "../../../common/integrationConfig";
import {Box} from "@mui/material";

export function AconexAddIntegration({projectId, onIntegrationAdded}) {
    const navigate = useNavigate();


    async function fetchRootNodes() {
        const response = await AconexApi.getProjects();
        return response.searchResults.map(project => mapProject(project));
    }

    function mapProject(project) {
        return ({
            id: project.projectID.toString(),
            name: project.projectShortName,
            selected: false,
            isLeaf: true,
        });
    }

    async function handleSave(project) {
        let aconex = await VrexApi.postIntegration(projectId, {
            type: "ACONEX",
            externalProjectId: project.id,
            name: project.name
        })
        onIntegrationAdded(aconex);
        navigate("/projects/" + projectId + "/models/aconex/" + aconex.id);
    }

    return (
        <Box>
            <React.Fragment>
                <AddIntegration projectId={projectId}
                                integrationConfig={AconexConfig}
                                fetchRootNodes={fetchRootNodes}
                                saveIntegration={handleSave}/>
            </React.Fragment>
        </Box>
    );
}
