import React from "react";
import {useNavigate} from "react-router-dom";
import VrexApi from "../../../api/vrexApi";
import BimsyncApi from "../../../api/bimsyncApi";
import { BimsyncConfig } from "../../../common/integrationConfig";
import {AddIntegration} from "../AddIntegration";


export function BimsyncAddIntegration({projectId, onIntegrationAdded}) {
    const navigate = useNavigate();

    async function fetchRootNodes() {
        const response = await BimsyncApi.getProjects();
        return response.map(project => mapProject(project));
    }

    function mapProject(project) {
        return ({
            id: project.id,
            name: project.name,
            selected: false,
            isLeaf: true,
        });
    }

    async function handleSave(project) {
        let bimsync = await VrexApi.postIntegration(projectId, {
            type: "BIMSYNC",
            externalProjectId: project.id,
            name: project.name
        })
        onIntegrationAdded(bimsync);
        navigate("/projects/" + projectId + "/models/bimsync/" + bimsync.id);
    }

    return (
        <AddIntegration projectId={projectId}
                        integrationConfig={BimsyncConfig}
                        fetchRootNodes={fetchRootNodes}
                        saveIntegration={handleSave}/>
    )
}
