import React, {useEffect, useState} from "react";
import {InviteOrgUserDialog} from "../component/InviteOrgUserDialog";
import VrexApi from "../api/vrexApi";
import {OrgUserList} from "../component/OrgUserList";
import ProjectPage from "../component/ProjectPage";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {useAppContext} from "../common/context";
import {useTranslation} from "react-i18next";
import {Roles} from "../common/util";

export default function OrganizationUsers({organization}) {
    const [users, setUsers] = useState([]);
    const {setToast} = useAppContext();
    const theme = useTheme();
    const {t} = useTranslation();

    useEffect(() => {
        if (!organization || !organization.users) {
            setUsers([]);
        } else {
            setUsers(organization.users);
        }
    }, [organization])

    async function removeUser(userId) {
        try {
            await VrexApi.deleteOrganizationUser(organization.id, userId);
            setUsers(users.filter(user => user.id !== userId));
        } catch (e) {
            setToast({message: t("organization.users.list.delete.error"), error: e, severity: "error"})
        }
    }

    async function handleRoleChange(userId, role) {
        try {
            let user = await VrexApi.patchOrganizationUser(organization.id, userId, {role: role})
            updateUser(userId, user);
        } catch (e) {
            setToast({message: t("organization.users.list.role.error"), error: e, severity: "error"})
        }
    }

    function updateUser(userId, user) {
        let index = users.findIndex(u => u.id === userId);
        users[index].role = user.role;
        setUsers([...users])
    }

    async function handleSendInvitation(email) {
        try {
            let user = await VrexApi.postOrganizationInvitation(organization.id, {
                email: email,
            });
            users.push(user);
            setUsers([...users])
        } catch (e) {
            setToast({message: t("organization.users.invitation.error"), error: e, severity: "error"})
        }
    }

    return (
        <ProjectPage>
            <Box display={"flex"} flexDirection={"column"}>
                {organization && (
                    <React.Fragment>
                        <InviteOrgUserDialog onInvite={handleSendInvitation}
                                             disabled={organization.role.accessLevel < Roles.COORDINATOR.accessLevel}
                                             capacityReached={organization.users.length >= organization.capacity.users}
                                             currentUsers={users}/>
                        <Typography color={"textSecondary"} variant={"body2"}
                                    style={{
                                        marginTop: theme.spacing(2),
                                    }}>
                            {t('organization.users.description')}
                        </Typography>
                        <OrgUserList type={"organization"} orgUsers={users} capacity={organization.capacity}
                                     onRoleChange={handleRoleChange} onRemoveUser={removeUser}
                                     role={organization.role}/>
                    </React.Fragment>
                )}
            </Box>
        </ProjectPage>
    )
}
