import React from "react";
import {IntegrationBrowser} from "../IntegrationBrowser";
import StreamBimApi from "../../../api/streamBimApi";
import { StreambimConfig } from "../../../common/integrationConfig";

export function StreamBimBrowser({projectId, onModelAdded}) {

    async function fetchRootNodes(integration) {
        const response = await StreamBimApi.getBuildings(integration.externalProjectId);

        const buildings = response.data.filter(building => building.attributes.inactive === false)
        if (buildings.length > 1) {
            return buildings.map(item => mapBuilding(item));
        } else if (buildings.length === 1) {
            return await fetchModels(buildings[0].id, integration);
        }
    }

    async function fetchModels(buildingId, integration) {
        let response = await StreamBimApi.getModels(integration.externalProjectId, buildingId);
        const activeModels = response.data.filter(model => model.attributes["currently-active"] && !model.attributes["marked-for-deletion"]);
        let models = []
        for (let model of activeModels) {
            models.push({id: model.id, name: model.attributes["layer"] + ".ifc"});
        }
        return models.map(item => mapModel(item, integration.models));
    }

    function mapBuilding(item) {
        return ({
            id: item.id,
            name: item.attributes.name,
            isLeaf: false,
            loading: false,
            children: [],
            visited: false,
            expanded: false,
        })
    }

    function mapModel(item, existingModels) {
        return ({
            id: item.id,
            name: item.name,
            isLeaf: true,
            selected: false,
            disabled: existingModels.find(model => model.externalId === item.id),
        })
    }


    async function expandNode(node, integration) {
        node.children = await fetchModels(node.id, integration);
        node.loading = false;
        node.visited = true;
    }

    return (
        <IntegrationBrowser projectId={projectId}
                            integrationConfig={StreambimConfig}
                            fetchRootNodes={fetchRootNodes}
                            expandNode={expandNode}
                            onModelAdded={onModelAdded}/>
    )
}
