import {get} from "./vrexAuth";

class IntegrationsApi {
    static async authorize(integrationName, origin) {
        let response =  await get(process.env.REACT_APP_INTEGRATIONS_URL + "/" + integrationName + "/authorize?origin=" + origin);
        return await response.json();
    }

    static async deauthorize(integrationName) {
        await get(process.env.REACT_APP_INTEGRATIONS_URL + "/" + integrationName + "/deauthorize");
    }

    static async get(integrationName) {
        let response =  await get(process.env.REACT_APP_INTEGRATIONS_URL + "/" + integrationName);
        return await response.json();
    }

    static async getAuthorizations() {
        let response =  await get(process.env.REACT_APP_INTEGRATIONS_URL + "/authorizations");
        return await response.json();
    }
}

export default IntegrationsApi
