import React from "react";
import {useNavigate} from "react-router-dom";
import VrexApi from "../../../api/vrexApi";
import {AddIntegration} from "../AddIntegration";
import StreamBimApi from "../../../api/streamBimApi";
import { StreambimConfig } from "../../../common/integrationConfig";


export function StreamBimAddIntegration({projectId, onIntegrationAdded}) {
    const navigate = useNavigate();

    async function fetchRootNodes() {
        const response = await StreamBimApi.getProjects();
        return response.data.map(project => mapProject(project));
    }

    function mapProject(project) {
        return ({
            id: project.id,
            name: project.attributes.name,
            selected: false,
            isLeaf: true,
        })
    }

    async function handleSave(integration) {
        let streamBim = await VrexApi.postIntegration(projectId, {
            type: "STREAMBIM",
            externalProjectId: integration.id,
            name: integration.name
        })
        onIntegrationAdded(streamBim);
        navigate("/projects/" + projectId + "/models/streambim/" + streamBim.id);
    }

    return (
        <AddIntegration projectId={projectId}
                        integrationConfig={StreambimConfig}
                        fetchRootNodes={fetchRootNodes}
                        saveIntegration={handleSave}/>
    )
}
