import {EmptyNode, LeafNode, Node} from "../../component/Nodes";
import {ChevronRightOutlined, ExpandMoreOutlined} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Box, CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {AuthError} from "../../component/AuthError";
import {useQuery} from "../../common/hooks";
import {useAppContext} from "../../common/context";
import HTTPError from "../../common/util";
import IntegrationsApi from "../../api/integrationsApi";


export function AddIntegration({
    projectId,
    integrationConfig,
    integrationType,
    fetchRootNodes,
    expandNode,
    saveIntegration
}) {
    const {t} = useTranslation();
    const title = t(`project.models.integration.add.title`, { name: integrationConfig.name });
    const image = integrationConfig.icon;
    integrationType = integrationType ?? integrationConfig.slug;
    const theme = useTheme();
    const [nodes, setNodes] = useState([]);
    const [rootNodesFetched, setRootNodesFetched] = useState(false);
    const [selected, setSelected] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const query = useQuery();
    const {setToast} = useAppContext();


    useEffect(() => {
        async function onLoad() {
            try {
                setRootNodesFetched(true);
                let nodes = await fetchRootNodes();
                setNodes(nodes);
                setLoading(false);
            } catch (e) {
                if (e instanceof HTTPError && e.statusCode === 401) {
                    try {
                        let response = await IntegrationsApi.authorize(integrationType, window.location.href);
                        window.location.href = response.url;
                        return;
                    } catch (e) {
                    }
                }
                setToast({message: t('project.models.integration.add.error'), error: e, severity: "error"});
            }
        }

        if (!rootNodesFetched && !query.has("error")) {
            onLoad().then();
        }
    }, [fetchRootNodes, integrationType, query, rootNodesFetched, setToast, t])

    async function toggleExpand(node) {
        if (node.loading) {
            return
        }
        if (!node.visited) {
            node.loading = true;
            node.visited = true;
            setNodes([...nodes]);
            try {
                await expandNode(node);
            } catch (e) {
                if (e instanceof HTTPError && e.statusCode === 401) {
                    try {
                        let response = await IntegrationsApi.authorize(integrationType, window.location.href);
                        window.location.href = response.url;
                        return;
                    } catch (e) {
                        setToast({message: t('project.models.integration.add.error'), error: e, severity: "error"});
                    }
                }
            }
        }
        node.expanded = !node.expanded;
        setNodes([...nodes]);
    }

    function toggleSelect(node) {
        function deselectAll(nodes) {
            nodes.forEach(node => {
                if (!node.isLeaf) {
                    deselectAll(node.children);
                } else {
                    node.selected = false;
                }
            })
        }

        if (node.selected) {
            node.selected = false;
            setSelected(null);
        } else {
            deselectAll(nodes);
            node.selected = true;
            setSelected(node);
        }
        setNodes([...nodes]);
    }

    function renderNode(node) {
        if (node.isLeaf) {
            return (
                <LeafNode key={node.id} title={node.name} selected={node.selected} disabled={node.disabled}
                          onClick={() => toggleSelect(node)}/>)
        }
        return (
            <Node key={node.id} title={node.name} icon={node.expanded ? <ExpandMoreOutlined/> : <ChevronRightOutlined/>}
                  loading={node.loading}
                  onClick={() => toggleExpand(node)}>
                {node.expanded ? renderNodes(node.children) : ""}
            </Node>
        )
    }

    function renderNodes(nodes) {
        if (nodes.length === 0) {
            return (<EmptyNode/>);
        }
        return nodes.map((node) => {
                return renderNode(node);
            }
        )
    }

    async function handleSave() {
        setSaving(true);
        try {
            await saveIntegration(selected);
        } catch (e) {
            setToast({message: t('project.modelSource.add.error'), severity: "error"});
        }
        setSaving(false);
    }

    return (
        <React.Fragment>
            <Typography variant={"body2"}
                        color={"textSecondary"}>{t('project.models.integration.add.description')}</Typography>
            <Box bgcolor={"background.paper"} p={2} mt={1} boxShadow={1}>
                <Box display={"flex"} pt={1}>
                    <img src={image} alt={""}/>
                    <Typography variant={"h6"} color={"textPrimary"}>
                        <Box px={1}>{title}</Box></Typography>
                </Box>
                {query.has("error") ?
                    <AuthError backUrl={"/projects/" + projectId + "/models"}/>
                    :
                    <React.Fragment>
                        <Box pt={3} display={"flex"} alignItems={"center"}>
                            <Button color={"primary"} variant={"contained"} disabled={selected === null || saving}
                                    onClick={handleSave}
                                    style={{marginRight: theme.spacing(2)}}>{t('general.ok')}</Button>
                            <Button color={"secondary"} variant={"outlined"} component={Link}
                                    to={`/projects/${projectId}/models`}>{t('general.cancel')}</Button>
                        </Box>
                        <Box py={2}>
                            {loading ? <CircularProgress color={"secondary"} size={20}
                                                         style={{
                                                             marginLeft: theme.spacing(2),
                                                             marginTop: theme.spacing(1)
                                                         }}/> : renderNodes(nodes)}
                        </Box>
                    </React.Fragment>
                }
            </Box>
        </React.Fragment>
    )
}
