import React from "react";
import {useNavigate} from "react-router-dom";
import VrexApi from "../../../api/vrexApi";
import {AddIntegration} from "../AddIntegration";
import BuildagilApi from "../../../api/buildagilApi";
import { BuildagilConfig } from "../../../common/integrationConfig";


export function BuildagilAddIntegration({projectId, onIntegrationAdded}) {
    const navigate = useNavigate();

    async function fetchRootNodes() {
        const response = await BuildagilApi.getProjects();
        return response.map(project => mapProject(project));
    }

    function mapProject(project) {
        return ({
            id: project.project_id,
            name: project.name,
            selected: false,
            isLeaf: true,
        })
    }

    async function handleSave(integration) {
        let buildagil = await VrexApi.postIntegration(projectId, {
            type: "BUILDAGIL",
            externalProjectId: integration.id,
            name: integration.name
        })
        onIntegrationAdded(buildagil);
        navigate("/projects/" + projectId + "/models/buildagil/" + buildagil.id);
    }

    return (
        <AddIntegration projectId={projectId}
                        integrationConfig={BuildagilConfig}
                        fetchRootNodes={fetchRootNodes}
                        saveIntegration={handleSave}/>
    )
}
