import React, {useEffect, useState} from 'react';
import {Box, IconButton, TextField, Typography} from "@mui/material";
import {CloseOutlined, DoneOutlined, EditOutlined} from "@mui/icons-material";

export function EditableNumberLabel({initialValue, onValueChanged, disabled, variant}) {
    function sanitize(value) {
        if (isNaN(value))
            return 0;
        return value;
    }

    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [value, setValue] = useState(sanitize(initialValue));
    const [text, setText] = useState(sanitize(initialValue).toString());

    useEffect(() => {
        setText(sanitize(initialValue).toString())
        setValue(sanitize(initialValue))
    }, [initialValue]);


    function cancel(event) {
        event.stopPropagation();
        setIsEditing(false);
        setText(sanitize(initialValue).toString());
        setValue(sanitize(initialValue));
    }

    async function save(event) {
        event.stopPropagation();
        setIsEditing(false);
        setText(value.toString());
        if (initialValue !== value) {
            setIsSaving(true);
            await onValueChanged(value);
            setIsSaving(false);
        }
    }

    function handleChange(event) {
        let text = event.target.value;
        if (text === "") {
            setText(text);
            setValue(0);
            return;
        }
        let sanitizedText = "";
        let dotCount = 0;
        for (let i = 0; i < text.length; i++) {
            if (isCharNumber(text[i])) {
                sanitizedText += text[i];
            } else if (text[i] === "." && dotCount === 0) {
                sanitizedText += ".";
                dotCount++;
            } else {
                return;
            }
        }

        setText(sanitizedText);
        setValue(parseFloat(sanitizedText));
    }

    function isCharNumber(c) {
        return typeof c === 'string' && c.length === 1 && c >= '0' && c <= '9';
    }

    return (
        <Box pl={2}>
            {disabled ?
                <Box display={"flex"} alignItems={"center"}>
                    <Box clone pr={1}><Typography>{text}</Typography></Box>
                </Box>
                :
                isEditing ?
                    <Box display={"flex"} alignItems={"center"}>
                        <TextField variant={"standard"} value={text}
                                   onClick={event => event.stopPropagation()}
                                   onChange={handleChange}/>
                        <IconButton size={"small"} onClick={save}><DoneOutlined sx={{fontSize: 16}}/></IconButton>
                        <IconButton size={"small"} onClick={cancel}><CloseOutlined sx={{fontSize: 16}}/></IconButton>
                    </Box> :
                    <Box display={"flex"} alignItems={"center"}>
                        <Box clone pr={1}><Typography variant={variant}>{text}</Typography></Box>
                        <IconButton
                            size={"small"} onClick={(event) => {
                            event.stopPropagation();
                            setIsEditing(true);
                        }} disabled={isSaving}>
                            <EditOutlined sx={{fontSize: 16}}/>
                        </IconButton>
                    </Box>
            }
        </Box>)
}
