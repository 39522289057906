import React from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import MainPage from "../component/MainPage";
import Link from "@mui/material/Link";
import {useQuery} from "../common/hooks";


export default function IntegrationError() {
    const theme = useTheme();
    const {t} = useTranslation();
    const query = useQuery();

    return (
        <MainPage title={t('integrations.error.title')}>
            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  style={{flexGrow: '1'}}>
                <Grid item xs={12} sm={10} md={7} lg={7} xl={5}
                      style={{
                          paddingLeft: "6vw",
                          paddingRight: "6vw",
                          paddingTop: theme.spacing(4),
                          backgroundColor: theme.palette.background.list
                      }}>
                    <Box display={"flex"} flexDirection={"column"} py={4}>
                        <Box bgcolor={"background.paper"} p={2} my={2} boxShadow={1}>
                            <Typography color={"textPrimary"} variant={"h5"} style={{marginBottom: theme.spacing(2)}}>
                                {t('integrations.error.description')}
                            </Typography>
                            <Typography color={"textPrimary"} variant={"h6"} style={{marginBottom: theme.spacing(2)}}>
                                {query.get("error")}
                            </Typography>
                            <Typography color={"textPrimary"} variant={"body2"}>
                                Please contact support at <Link href={"mailto:support@vrex.no"} underline="hover">support@vrex.no</Link> if the problem persists.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </MainPage>
    );
}
