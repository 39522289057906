import ACONEX_IMAGE from "../images/aconex.png";
import BIMSYNC_IMAGE from "../images/catenda_hub.png";
import BIM360_IMAGE from "../images/acc.png";
import BIMTRACK_IMAGE from "../images/bimtrack.png";
import STREAMBIM_IMAGE from "../images/streambim.png";
import BUILDAGIL_IMAGE from "../images/buildagil.png";
import BIMPLUS_IMAGE from "../images/bimplus.png";
import NONE_IMAGE from "../images/none.png";

class IntegrationConfig {
    constructor(slug, name, icon) {
        this.slug = slug;
        this.name = name;
        this.icon = icon;
    }
}

export const AconexConfig = new IntegrationConfig('aconex', 'Aconex', ACONEX_IMAGE);
export const BimsyncConfig = new IntegrationConfig('bimsync', 'Catenda Hub', BIMSYNC_IMAGE);
export const Bim360Config = new IntegrationConfig('bim360', 'Autodesk Construction Cloud', BIM360_IMAGE);
export const BimtrackConfig = new IntegrationConfig('bimtrack', 'Newforma Konekt', BIMTRACK_IMAGE);
export const StreambimConfig = new IntegrationConfig('streambim', 'StreamBIM', STREAMBIM_IMAGE);
export const BuildagilConfig = new IntegrationConfig('buildagil', 'buildagil', BUILDAGIL_IMAGE);
export const BimcollabConfig = new IntegrationConfig('bimcollab', 'BIMcollab', NONE_IMAGE);
export const BimplusConfig = new IntegrationConfig('bimplus', 'Bimplus', BIMPLUS_IMAGE);

const Integrations = {
    aconex: AconexConfig,
    bimsync: BimsyncConfig,
    bim360: Bim360Config,
    bimtrack: BimtrackConfig,
    streambim: StreambimConfig,
    buildagil: BuildagilConfig,
    bimplus: BimplusConfig,
    bimcollab: BimcollabConfig
}

export function getIcon(integrationType) {
  const parts = integrationType.split('/')
  const i = Integrations[parts[0].toLowerCase()];
  return i === undefined ? NONE_IMAGE : i.icon;
}

export function getName(integrationType) {
  const parts = integrationType.split('/')
  const i = Integrations[parts[0].toLowerCase()];
  return i === undefined ? integrationType : i.name + (parts.length === 2 ? ' - ' + parts[1] : '');
}
