import React from 'react';
import {useAppContext} from "../common/context";
import IntegrationsApi from "../api/integrationsApi";
import {useTranslation} from "react-i18next";
import VrexApi from "../api/vrexApi";

export default function AuthorizedAction({integration, projectId, onAuthorizedClick, origin, ...props}) {
    const {setToast} = useAppContext();
    const {t} = useTranslation();

    async function handleClick() {
        if (await authorize()) {
            onAuthorizedClick();
        }
    }

    async function authorize() {
        try {
            let integrationType = integration.type.toLowerCase();
            if (integrationType === "aconex"){
                let i = await VrexApi.getIntegration(projectId, integration.id, false);
                integrationType = i.type.toLowerCase()+"/"+i.externalData;
            }
            let response = await IntegrationsApi.get(integrationType);
            if (response.status !== "AUTHORIZED") {
                let response = await IntegrationsApi.authorize(integrationType, origin);
                window.location.href = response.url;
                return false;
            }
            return true;
        } catch (e) {
            setToast({message: t('integrations.error.authorize', {integration: integration.type}), severity: "error"})
            return false;
        }
    }

    return (
        <>
            {React.cloneElement(props.children, {onClick: handleClick})}
        </>
    );
}
