import {Link, useLocation} from "react-router-dom";
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    selected: {
        "&$selected": {
            backgroundColor: theme.palette.background.list,
            "&:hover": {
                backgroundColor: theme.palette.background.list,
            }
        },
        backgroundColor: theme.palette.background.list,
    },
    primaryText: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));

export function MenuButton({path, name, ...props}) {
    const theme = useTheme();
    const location = useLocation();
    const classes = useStyles();

    return <ListItemButton
        component={Link} to={path}
        selected={location.pathname.includes(path)}
        classes={{selected: classes.selected}}>
        <ListItemIcon style={{minWidth: theme.spacing(4)}}>
            {props.children}
        </ListItemIcon>
        <ListItemText
            classes={{
                primary: classes.primaryText
            }}
            primary={name.toUpperCase()}
            primaryTypographyProps={{color: "textSecondary"}}/>
    </ListItemButton>;
}
