import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Tooltip} from "@mui/material";

export function ConfirmedAction({
                                    title,
                                    description,
                                    onConfirmed,
                                    tooltip,
                                    shouldConfirmOnClick = () => true,
                                    ...props
                                }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (shouldConfirmOnClick()) {
            setOpen(true);
        } else {
            onConfirmed();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmed = () => {
        setOpen(false);
        onConfirmed();
    }

    return (
        <>{tooltip ?
            <Tooltip title={tooltip}>
                <span>
                    {React.cloneElement(props.children, {onClick: handleClickOpen})}
                </span>
            </Tooltip>
            :
            React.cloneElement(props.children, {onClick: handleClickOpen})
        }
            {renderDialog(open, handleClose, title, description, handleConfirmed)}
        </>
    );
}


function renderDialog(open, handleClose, title, description, handleConfirmed) {
    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {description}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} variant={"outlined"} color="secondary" autoFocus>
                NO
            </Button>
            <Button onClick={handleConfirmed} variant={"contained"} color="primary">
                YES
            </Button>
        </DialogActions>
    </Dialog>;
}
