import {v4 as uuidv4} from 'uuid';

class LiveUpdates {
    constructor() {
        this.topics = {}
    }

    connect(url, token, onAuthenticated, onClosed) {
        this.ws = new WebSocket(url);
        this.userInitiatedClose = false;
        this.ws.onopen = () => {
            this.ws.send(JSON.stringify({action: "authenticate", data: {token: token}}));
        };
        this.ws.onmessage = (event) => {
            let msg = JSON.parse(event.data);
            if (msg.type === "AUTHENTICATION_RESULT") {
                if (msg.data.result === "AUTHENTICATED") {
                    onAuthenticated();
                    this.timerId = setInterval(() => {
                        this.ws.send(JSON.stringify({action: "keepAlive", data: ""}));
                    }, 9 * 60 * 1000);
                }
                return;
            }
            this.publish(msg.type, msg.data);
        };
        this.ws.onclose = () => {
            if (this.timerId) {
                clearInterval(this.timerId);
            }
            if(!this.userInitiatedClose)
                onClosed();
        }
        this.ws.onerror = (event) => {
            console.log(event);
        }
    }

    subscribeToProject(projectId) {
        if(!this.ws || this.ws.readyState !== this.ws.OPEN){
            console.error("websockets not initialized");
            return;
        }
        this.ws.send(JSON.stringify({action: "subscribeProject", data: {projectId: projectId}}));
    }

    publish(event, data) {
        if (!this.topics[event]) return;
        Object.values(this.topics[event]).forEach(callback => {
            if (callback) {
                callback(data);
            }
        });
    }

    subscribeToEventLocally(event, callback) {
        if (!this.topics[event])
            this.topics[event] = {};
        const id = uuidv4();
        this.topics[event][id] = callback;
        return () => {
            this.topics[event][id] = null;
            delete this.topics[event][id];
        }
    }

    close(){
        if(!this.ws){
            console.log("websocket not initialized");
            return;
        }
        this.userInitiatedClose = true;
        this.ws.close();
    }
}

export default LiveUpdates
