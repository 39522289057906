import React from "react";
import {useNavigate} from "react-router-dom";
import VrexApi from "../../../api/vrexApi";
import BimtrackApi from "../../../api/bimtrackApi";
import { BimtrackConfig } from "../../../common/integrationConfig";
import {AddIntegration} from "../AddIntegration";

export function BimtrackAddIntegration({projectId, onIntegrationAdded}) {
    const navigate = useNavigate();

    async function getProjectsForHub(hub){
        let response = await BimtrackApi.getProjects(hub.id);
        hub.children = response.map(project => mapProject(project, hub.id));
        hub.loading = false;
        hub.visited = true;
    }

    async function fetchRootNodes(){
        const response = await BimtrackApi.getHubs();
        const hubs = response.map(hub => mapHub(hub));
        if (hubs.length === 1) {
            await getProjectsForHub(hubs[0]);
            hubs[0].expanded = true;
        }
        return hubs;
    }

    function mapProject(project, hubId) {
        return ({
            id: project.Id.toString(),
            name: project.Name,
            hubId: hubId,
            selected: false,
            disabled: false,
            isLeaf: true,
        })
    }

    function mapHub(hub) {
        return ({
            id: hub.Id,
            name: hub.Name,
            loading: false,
            children: [],
            visited: false,
            expanded: false,
            isLeaf: false,
        })
    }

    async function handleSave(integration) {
        let bimtrack = await VrexApi.postIntegration(projectId, {
            type: "BIMTRACK",
            externalData: integration.hubId,
            externalProjectId: integration.id,
            name: integration.name
        })
        onIntegrationAdded(bimtrack);
        navigate("/projects/" + projectId + "/models/bimtrack/" + bimtrack.id);
    }

    return (
        <AddIntegration projectId={projectId}
                        integrationConfig={BimtrackConfig}
                        fetchRootNodes={fetchRootNodes}
                        saveIntegration={handleSave}
                        expandNode={getProjectsForHub}/>
    )
}
