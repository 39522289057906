import React from "react";
import Bim360Api from "../../../api/bim360Api";
import { Bim360Config } from "../../../common/integrationConfig";
import {IntegrationBrowser} from "../IntegrationBrowser";


const supportedFileTypes = ["IFC", "JT", "E57", "NWD", "NWC", "RVT"];

function supportedFileType(filename) {
    let extension = filename.split('.').pop();
    if (extension.length === filename.length)
        return false;
    return supportedFileTypes.includes(extension.toUpperCase());
}

export function Bim360Browser({projectId, onModelAdded}) {

    async function fetchRootNodes(integration) {
        const response = await Bim360Api.getTopFolders(integration.externalData, integration.externalProjectId);
        return response.data.map(item => mapFolder(item, integration.models));
    }

    function mapFolder(item) {
        return ({
            id: item.id,
            name: item.attributes.name,
            isLeaf: false,
            loading: false,
            children: [],
            visited: false,
            expanded: false,
        })
    }

    function mapItem(item, existingModels) {
        return ({
            id: item.relationships.item.data.id,
            name: item.attributes.name,
            isLeaf: true,
            selected: false,
            disabled: existingModels.find(model => model.externalId === item.relationships.item.data.id) || !supportedFileType(item.attributes.displayName),
        })
    }


    async function expandNode(node, integration) {
        let response = await Bim360Api.getFolderContent(integration.externalProjectId, node.id);
        let children = response.data.filter(item => item.type === 'folders').map(item => mapFolder(item));
        if(response.included){
            let items = response.included.map(item => mapItem(item, integration.models));
            children = [...children, ...items];
        }
        node.children = children;
        node.loading = false;
        node.visited = true;
    }

    return (
        <IntegrationBrowser projectId={projectId}
                            integrationConfig={Bim360Config}
                            fetchRootNodes={fetchRootNodes}
                            expandNode={expandNode}
                            onModelAdded={onModelAdded}/>
    )
}
