import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function ProjectPage({sm, md, lg, xl, ...props}) {
    return (
        <Grid item xs={12} sm={9} md={10} lg={10} xl={10} flex={1}>
            <Box p={4} bgcolor={"background.list"} boxSizing={"border-box"} height={1}
                 {...props} sx={{width: "fit-content", maxWidth: "100%", minWidth: {xs:"100%", sm: "90%", md: "70%", lg:"60%", xl:"50%"}}}
            >
                {props.children}
            </Box>
        </Grid>
    );
}
