import React, {useEffect, useState} from "react";
import {useAppContext} from "../common/context";
import VrexApi from "../api/vrexApi";
import {ProjectUserList} from "../component/ProjectUserList";
import ProjectPage from "../component/ProjectPage";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {AddProjectUserDialog} from "../component/AddProjectUserDialog";
import {Roles} from "../common/util";

export default function ProjectUsers({project}) {
    const [users, setUsers] = useState([]);
    const {isAuthenticated, setToast} = useAppContext();
    const theme = useTheme();
    const {t} = useTranslation();

    useEffect(() => {
        async function loadProjectUsers() {
            try {
                return await VrexApi.getProjectUsers(project.id)

            } catch (e) {
                setToast({message: t('project.users.error'), error: e, severity: "error"})
                return null;
            }
        }

        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            const u = await loadProjectUsers();
            setUsers(u);
        }

        onLoad().then();
    }, [isAuthenticated, project.id, setToast, t]);


    async function removeUser(orgUserId) {
        try {
            await VrexApi.deleteProjectUser(project.id, orgUserId);
            let u = users.filter(user => user.id !== orgUserId);
            setUsers(u);
        } catch (e) {
            setToast({message: t('project.users.list.remove.error'), error: e, severity: "error"})
        }
    }

    async function handleRoleChange(user, role) {
        try {
            let u = await VrexApi.patchOrganizationUser(project.organization.id, user.id, {role: role})
            updateUser(user.id, u);
        } catch (e) {
            setToast({message: t("organization.users.list.role.error"), error: e, severity: "error"})
        }
    }

    function updateUser(userId, user){
        let index = users.findIndex(u => u.id === userId);
        users[index].role = user.role;
        setUsers([...users])
    }

    function handleUserAdded(user) {
        users.push(user);
        setUsers([...users]);
    }

    return (
        <ProjectPage>
            {users && (
                <Box display={"flex"} flexDirection={"column"}>
                    <AddProjectUserDialog onUserAdded={handleUserAdded}
                                          disabled={project.status === "INACTIVE" || project.role.accessLevel < Roles.COORDINATOR.accessLevel}
                                          type={"project"}
                                          currentUsers={users}
                                          organizationId={project.organization.id}
                                          projectId={project.id}
                                          capacity={project.organization.capacity}/>
                    <Typography color={"textSecondary"} variant={"body2"}
                                style={{
                                    marginTop: theme.spacing(2),
                                }}>
                        {t('project.users.description')}
                    </Typography>
                    <ProjectUserList
                        disabled={project.status === "INACTIVE" || project.role.accessLevel < Roles.COORDINATOR.accessLevel}
                        project={project} users={users} onRemoveUser={removeUser} onRoleUpdate={handleRoleChange}/>
                </Box>)}
        </ProjectPage>
    )
}
