import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";
import MainPage from "../component/MainPage";
import {useAppContext} from "../common/context";
import Accordion from "../component/Accordion";
import {getRole} from "../common/util";

const noneRole = {name: "NONE", accessLevel: 0, title: "None"};
export default function Help() {
    const theme = useTheme();
    const {t} = useTranslation();
    const {vrexUrl, nwPluginUrl, user} = useAppContext();
    const [maxRole, setMaxRole] = useState(noneRole)

    useEffect(() => {
        let role = {name: "NONE", accessLevel: 0, title: "None"};
        if (user?.organizations) {
            user.organizations.forEach((org) => {
                if (org.role.accessLevel > role.accessLevel) {
                    role = getRole(org.role.accessLevel);
                }
            })
        }
        setMaxRole(role);
    }, [user])

    function getRoleBasedInfo() {
        if (maxRole.name === "ADMIN" || maxRole.name === "CREATOR") {
            return (
                <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                    <Typography variant={"h6"} style={{marginBottom: theme.spacing(2)}}>
                        {"How to get started"}
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(1)}}>
                        1. From the top left menu ≡, navigate to Projects and create or enter a project.
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(3)}}>
                        {"2. Once in the project, you can add models, set up integrations and invite other users."}
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(2)}}>
                        {"Additionally you can:"}
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(1)}}>
                        Download and install <Link href={nwPluginUrl} target="_blank" underline="hover">Vrex plugin for
                        Navisworks</Link>.
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(2)}}>
                        Download and install <Link href={vrexUrl} target="_blank" underline="hover">Vrex viewer</Link>.
                        From there you can view your
                        projects.
                    </Typography>
                </Box>
            );
        }
        if (maxRole.name === "COORDINATOR") {
            return (
                <Box bgcolor={"background.paper"} p={2} my={2} boxShadow={1}>
                    <Typography variant={"h6"} style={{marginBottom: theme.spacing(2)}}>
                        {"How to get started"}
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(1)}}>
                        1. From the top left menu, navigate to Projects and enter a
                        project.
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(3)}}>
                        {"2. Once in your project, you can add models, set up integrations and invite other users."}
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(2)}}>
                        {"Additionally you can:"}
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(1)}}>
                        Download and install <Link href={nwPluginUrl} target="_blank" underline="hover">Vrex
                        plugin</Link> for Navisworks.
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(2)}}>
                        Download and install <Link href={vrexUrl} target="_blank" underline="hover">Vrex viewer</Link>.
                        From there you can access your
                        projects.
                    </Typography>
                </Box>
            );
        }
        if (maxRole.name === "USER") {
            return (
                <Box bgcolor={"background.paper"} p={2} my={2} boxShadow={1}>
                    <Typography variant={"h6"} style={{marginBottom: theme.spacing(2)}}>
                        {"How to get started"}
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(1)}}>
                        1. Download and install <Link href={vrexUrl} target="_blank" underline="hover">Vrex
                        viewer</Link>.
                    </Typography>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(2)}}>
                        {"2. Open Vrex. There you will can view your projects!"}
                    </Typography>
                </Box>
            );
        }
    }

    return (
        <MainPage title={t('help.title')}>
            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  style={{flexGrow: '1'}}>
                <Grid item xs={12} sm={10} md={7} lg={7} xl={5}
                      style={{
                          paddingLeft: "6vw",
                          paddingRight: "6vw",
                          paddingTop: theme.spacing(4),
                          backgroundColor: theme.palette.background.list
                      }}>
                    <Box display={"flex"} flexDirection={"column"} py={4} alignItems={"stretch"}>
                        {user && (user.organizations.length === 0 && user.invitations.length > 0) &&
                            <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                                <Typography color={"textPrimary"} variant={"h6"}
                                            style={{marginBottom: theme.spacing(2)}}>
                                    Pending invitation!
                                </Typography>
                                <Typography color={"textPrimary"} variant={"body2"}
                                            style={{marginBottom: theme.spacing(2)}}>
                                    Please check your invitations to join an organization.
                                </Typography>

                            </Box>
                        }
                        {user && (user.organizations.length === 0 && user.invitations.length === 0) &&
                            <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                                <Typography color={"textPrimary"} variant={"h6"}
                                            style={{marginBottom: theme.spacing(2)}}>
                                    How to get started
                                </Typography>
                                <Typography color={"textPrimary"} variant={"body2"}>
                                    It looks like you are not part of a Vrex account yet.
                                </Typography>
                                <Typography color={"textPrimary"} variant={"body2"}
                                            style={{marginBottom: theme.spacing(2)}}>
                                    If your organization already has a Vrex account, contact one of your Vrex admins and
                                    request access.
                                </Typography>
                                <Typography color={"textPrimary"} variant={"body2"}
                                            style={{marginBottom: theme.spacing(2)}}>
                                    If your organization do not have an account, or you are unsure, please get in touch
                                    with us at <Link href={"mailto:support@vrex.no"}
                                                     underline="hover">support@vrex.no</Link>, and we will help you.
                                </Typography>
                            </Box>
                        }
                        {user && (user.organizations.length > 0) &&
                            getRoleBasedInfo()
                        }
                        <Accordion summary={
                            <React.Fragment>
                                <Typography variant={"h6"}>
                                    {"I have a room code"}
                                </Typography>
                            </React.Fragment>}>
                            <Typography variant={"body2"} style={{
                                marginTop: theme.spacing(1),
                                marginLeft: theme.spacing(2),
                                marginBottom: theme.spacing(2)
                            }}>
                                1. Download and install <Link href={vrexUrl} target="_blank" underline="hover">Vrex
                                viewer</Link>.
                            </Typography>
                            <Typography variant={"body2"}
                                        style={{marginLeft: theme.spacing(2), marginBottom: theme.spacing(2)}}>
                                {"2. Open Vrex viewer and enter your room code"}
                            </Typography>

                        </Accordion>
                        <Box bgcolor={"background.paper"} p={2} my={2} boxShadow={1}>
                            <Typography color={"textPrimary"} variant={"h6"}
                                        style={{marginBottom: theme.spacing(2)}}>
                                Need help?
                            </Typography>
                            <Typography color={"textPrimary"} variant={"body2"}
                                        style={{marginBottom: theme.spacing(1)}}>
                                Visit our <Link
                                href={process.env.REACT_APP_ACADEMY_URL}
                                target="_blank"
                                underline="hover">learning center</Link> for more information and
                                guides on how VREX works.
                            </Typography>
                            <Typography color={"textPrimary"} variant={"body2"}>
                                If you have any further questions, please contact us at <Link
                                href={"mailto:support@vrex.no"} underline="hover">support@vrex.no</Link>.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </MainPage>
    );
}
