import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Projects from "./containers/Projects";
import Login from "./containers/Login";
import SignUp from "./containers/Signup";
import {Project} from "./containers/Project";
import Organizations from "./containers/Organizations";
import Reset from "./containers/Reset";
import Help from "./containers/Help";
import Downloads from "./containers/Downloads";
import Confirm from "./containers/Confirm";
import IntegrationError from "./containers/IntegrationError";
import {useAppContext} from "./common/context";

function RequireUnAuth({children}) {
    const {isAuthenticated} = useAppContext();
    const {search} = useLocation();
    const [redirect, setRedirect] = useState("");
    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        let r = queryParams.get("redirect");
        if (r) {
            setRedirect(r);
        } else {
            setRedirect("/projects")
        }
    }, [search])
    return isAuthenticated ? <Navigate to={redirect} replace/> : children;
}

function DefaultRedirect() {
    const {isAuthenticated} = useAppContext();
    return isAuthenticated ? <Navigate to={'/projects'} replace/> : <Navigate to={'/login'} replace/>;
}

export function RequireAuth({children}) {
    const {pathname, search} = useLocation();
    const {isAuthenticated} = useAppContext();
    return isAuthenticated ? children : <Navigate to={`/login?redirect=${pathname}${search}`} replace/>;
}

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/projects" element={
                <RequireAuth>
                    <Projects/>
                </RequireAuth>
            }/>
            <Route path="/projects/:projectId/*" element={
                <RequireAuth>
                    <Project/>
                </RequireAuth>
            }/>
            <Route path="/organizations/*" element={
                <RequireAuth>
                    <Organizations/>
                </RequireAuth>
            }/>
            <Route path="/help" element={
                <RequireAuth>
                    <Help/>
                </RequireAuth>
            }/>
            <Route path="/downloads" element={
                <RequireAuth>
                    <Downloads/>
                </RequireAuth>
            }/>

            <Route path="/login" element={
                <RequireUnAuth>
                    <Login/>
                </RequireUnAuth>
            }/>
            <Route path="/login/reset" element={
                <RequireUnAuth>
                    <Reset/>
                </RequireUnAuth>
            }/>
            <Route path="/signup" element={
                <RequireUnAuth>
                    <SignUp/>
                </RequireUnAuth>
            }/>
            <Route path="/confirm" element={
                <RequireUnAuth>
                    <Confirm/>
                </RequireUnAuth>
            }/>
            <Route path="/integration-error" element={<IntegrationError/>}/>
            <Route path={"*"} element={<DefaultRedirect/>}/>
        </Routes>
    );
}

