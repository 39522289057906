import React from "react";
import {Box, Typography} from "@mui/material";
import {CloudUploadOutlined, HighlightOffOutlined, Sync} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {ModelTable} from "./Model";
import {useTheme} from "@mui/material/styles";
import {ConfirmedAction} from "./ConfirmedAction";
import AuthorizedAction from "./AuthorizedAction";
import {HelpText, HelpTextButton} from "./HelpText";
import {useTranslation} from "react-i18next";
import Accordion from "./Accordion";
import {getIcon, getName} from "../common/integrationConfig";

export default function Integration({
                                        disabled,
                                        projectId,
                                        integration,
                                        onDeleteIntegration,
                                        onDeleteModel,
                                        onDeleteModels,
                                        onSyncIntegration,
                                        origin,
                                        converters,
                                    }) {
    const theme = useTheme();
    const {t} = useTranslation();
    const name = getName(integration.type);

    return (
        <Accordion defaultExpanded={true}
                   summary={
                       <React.Fragment>
                           <img src={getIcon(integration.type)} alt={""}/>
                           <Typography variant={"h6"} style={{marginLeft: theme.spacing(2)}}>
                               {integration.name}
                           </Typography>
                           <HelpText text={t('project.models.integration.description', { name })}/>
                       </React.Fragment>}>
            <Box display={"flex"} px={2} mt={1}>
                <HelpTextButton title={t('project.models.integration.models.add.title')} component={Link}
                                disabled={disabled}
                                to={`/projects/${projectId}/models/${integration.type.toLowerCase()}/${integration.id}`}
                                startIcon={<CloudUploadOutlined/>}
                    helpText={t(`project.models.${integration.type.toLowerCase()}.models.add.description`, { name })}/>
                <AuthorizedAction
                    integration={integration} projectId={projectId}
                    onAuthorizedClick={onSyncIntegration} origin={origin}>
                    <HelpTextButton title={t('project.models.integration.models.sync.title')} startIcon={<Sync/>}
                                    disabled={disabled || !integration.models || integration.models.length === 0}
                        helpText={t('project.models.integration.models.sync.description', { name })} />
                </AuthorizedAction>
                <ConfirmedAction title={t('project.models.modelSource.delete.dialog.title')}
                                 description={t('project.models.modelSource.delete.dialog.description', { name: integration.name })}
                                 onConfirmed={onDeleteIntegration}>
                    <HelpTextButton title={t('project.models.modelSource.delete.title')}
                                    disabled={disabled}
                                    helpText={t('project.models.modelSource.delete.description')}
                                    startIcon={<HighlightOffOutlined/>}/>
                </ConfirmedAction>
            </Box>
            <Box  sx={{px: 2, pt: 1, pb: 2}}>
                <ModelTable models={integration.models}
                            converters={converters}
                            modelSourceType={integration.type}
                            disabledDelete={disabled || !integration.models || integration.models.length === 0}
                            onDeleteModels={onDeleteModels}
                            onDeleteModel={onDeleteModel}>
                </ModelTable>
            </Box>
        </Accordion>
    )
}
