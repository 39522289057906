import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import VrexApi from "../api/vrexApi";
import {useAppContext} from "../common/context";
import {RoomDialog} from "../component/RoomDialog";
import {Add} from "@mui/icons-material";
import ProjectPage from "../component/ProjectPage";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {CircularButton} from "../component/CircularButton";
import Room from "../component/Room";
import {useTranslation} from "react-i18next";
import {Roles} from "../common/util";

export default function Rooms({project}) {
    const {isAuthenticated, setToast} = useAppContext();
    const [rooms, setRooms] = useState(null);
    const [creatingRoom, setCreatingRoom] = useState(false);
    const theme = useTheme();
    const {t} = useTranslation();

    useEffect(() => {
        async function loadProjectRooms() {
            try {
                return await VrexApi.getProjectRooms(project.id);
            } catch (e) {
                setToast({message: t('project.rooms.error'), error: e, severity: "error"})
                return null;
            }
        }

        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            const r = await loadProjectRooms();
            setRooms(r);
        }

        onLoad().then();
    }, [isAuthenticated, project.id, setToast, t]);

    async function createRoom(room) {
        try {
            let createdRoom = await VrexApi.postProjectRoom(project.id, room);
            setCreatingRoom(false);
            setRooms([createdRoom, ...rooms]);
        } catch (e) {
            setToast({message: t('project.rooms.create.error'), error: e, severity: "error"});
        }
    }

    async function removeRoom(roomId) {
        try {
            await VrexApi.deleteProjectRoom(project.id, roomId);
            setRooms(rooms.filter(room => room.id !== roomId));
        } catch (e) {
            setToast({message: t('project.rooms.delete.error'), error: e, severity: "error"})
            return null;
        }
    }

    async function unShareRoom(roomId) {
        try {
            await VrexApi.deleteExternalAccess(project.id, roomId);
            let room = rooms.find(room => room.id === roomId);
            room.externalAccess = null;
            setRooms([...rooms]);
        } catch (e) {
            setToast({message: t('project.rooms.code.remove.error'), error: e, severity: "error"})
            return null;
        }
    }

    async function shareRoom(roomId) {
        try {
            let externalAccess = await VrexApi.postExternalAccess(project.id, roomId);
            let room = rooms.find(room => room.id === roomId);
            room.externalAccess = externalAccess;
            setRooms([...rooms]);
        } catch (e) {
            setToast({message: t('project.rooms.code.add.error'), error: e, severity: "error"})
            return null;
        }
    }

    async function editRoom(roomId, room) {
        try {
            await VrexApi.patchRoom(project.id, roomId, room);
            let roomIndex = rooms.findIndex(room => room.id === roomId);
            rooms[roomIndex].name = room.name;
            rooms[roomIndex].description = room.description;
            setRooms([...rooms]);
        } catch (e) {
            setToast({message: t('project.rooms.edit.error'), error: e, severity: "error"})
            return null;
        }
    }

    let inactive = project.status === "INACTIVE";

    return (
        <ProjectPage>
            {rooms ?
                <Box display={"flex"} flexDirection={"column"}>
                    <Box display={"flex"} flexDirection={"row-reverse"}>
                        <CircularButton
                            title={t('project.rooms.create.title')}
                            shadow
                            spacing={2}
                            onClick={() => setCreatingRoom(true)}
                            disabled={inactive || project.role.accessLevel < Roles.COORDINATOR.accessLevel}>
                            <Add/>
                        </CircularButton>
                        <RoomDialog open={creatingRoom} title={t('project.rooms.create.title')}
                                    submitText={t('general.create')}
                                    validateRoomName={(name) => !rooms.some(room => room.name === name)}
                                    onSubmit={createRoom} onClose={() => setCreatingRoom(false)}/>
                    </Box>
                    <Typography
                        color={"textSecondary"}
                        variant={"body2"}
                        style={{
                            marginTop: theme.spacing(2),
                        }}>
                        {t('project.rooms.description')}
                    </Typography>
                    {rooms.map(
                        (room) => <Room key={room.id}
                                        disabled={inactive || project.role.accessLevel < Roles.COORDINATOR.accessLevel}
                                        room={room}
                                        validateRoomName={(name) => !rooms.filter(r => r.id !== room.id).some(r => r.name === name)}
                                        editRoom={editRoom}
                                        shareRoom={shareRoom}
                                        unShareRoom={unShareRoom}
                                        removeRoom={removeRoom}/>)}
                </Box> : ""}
        </ProjectPage>
    )
}
