import React from "react";
import { useNavigate } from "react-router-dom";
import VrexApi from "../../../api/vrexApi";
import { AddIntegration } from "../AddIntegration";
import BimplusApi from "../../../api/bimplusApi";
import {BimplusConfig} from "../../../common/integrationConfig";



export function BimplusAddIntegration({ projectId, onIntegrationAdded }) {
  const navigate = useNavigate();

  async function fetchRootNodes() {
    const response = await BimplusApi.getProjects();
    return response.map(project => mapProject(project));
  }

  function mapProject(project) {
    return ({
      id: project.id,
      teamId: project.teamSlug,
      name: project.name,
      selected: false,
      isLeaf: true,
    })
  }

  async function handleSave(integration) {
    let bimplus = await VrexApi.postIntegration(projectId, {
      type: "BIMPLUS",
      externalProjectId: integration.id,
      externalData: integration.teamId,
      name: integration.name
    })
    onIntegrationAdded(bimplus);
    navigate("/projects/" + projectId + "/models/bimplus/" + bimplus.id);
  }

  return (
    <AddIntegration
        projectId={projectId}
        integrationConfig={BimplusConfig}
        fetchRootNodes={fetchRootNodes}
        saveIntegration={handleSave}/>
  )
}
