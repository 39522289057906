import React from "react";
import Bim360Api from "../../../api/bim360Api";
import { Bim360Config } from "../../../common/integrationConfig";
import {useNavigate} from "react-router-dom";
import VrexApi from "../../../api/vrexApi";
import {AddIntegration} from "../AddIntegration";


export function Bim360AddIntegration({projectId, onIntegrationAdded}) {
    const navigate = useNavigate();

    async function getProjectsForHub(hub) {
        let response = await Bim360Api.getProjects(hub.id);
        hub.children = response.data.map(project => mapProject(project, hub.id));
        hub.loading = false;
        hub.visited = true;
    }

    async function fetchRootNodes() {
        const response = await Bim360Api.getHubs();
        const hubs = response.data.map(hub => mapHub(hub));
        if (hubs.length === 1) {
            await getProjectsForHub(hubs[0]);
            hubs[0].expanded = true;
        }
        return hubs;
    }

    function mapProject(project, hubId) {
        return ({
            id: project.id,
            name: project.attributes.name,
            hubId: hubId,
            selected: false,
            disabled: false,
            isLeaf: true,
        })
    }

    function mapHub(hub) {
        return ({
            id: hub.id,
            name: hub.attributes.name,
            loading: false,
            children: [],
            visited: false,
            expanded: false,
            isLeaf: false,
        })
    }

    async function handleSave(project) {
        let bim360 = await VrexApi.postIntegration(projectId, {
            type: "BIM360",
            externalData: project.hubId,
            externalProjectId: project.id,
            name: project.name
        })
        onIntegrationAdded(bim360);
        navigate("/projects/" + projectId + "/models/bim360/" + bim360.id);
    }

    return (
        <AddIntegration projectId={projectId}
                        integrationConfig={Bim360Config}
                        fetchRootNodes={fetchRootNodes}
                        saveIntegration={handleSave}
                        expandNode={getProjectsForHub}/>
    )
}
