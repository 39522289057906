import React, {useState} from 'react';
import {useAppContext} from "../../common/context";
import Typography from "@mui/material/Typography";
import {Box, DialogActions, DialogContent, IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IntegrationsApi from "../../api/integrationsApi";
import {Clear} from "@mui/icons-material";
import {getName} from "../../common/integrationConfig";

export default function IntegrationsDialog({open, onClose}) {
    const {authorizations, setAuthorizations} = useAppContext();
    const {setToast} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    async function deauthorize(integrationType) {
        setIsLoading(true);
        try {
            await IntegrationsApi.deauthorize(integrationType);
            setAuthorizations(authorizations.filter(name => name !== integrationType));
        } catch (e) {
            setToast({
                message: t('integrations.error.deauthorize', {integration: integrationType}),
                error: e,
                severity: "error"
            })
        }
        setIsLoading(false);
    }

    function getIntegration(integrationType) {
        return (
            <Box key={integrationType} bgcolor={"background.list"} my={1} p={1} color={"text.secondary"} boxShadow={1}
                 display={"flex"}
                 alignItems={"center"}>
                <Typography variant={"subtitle2"}
                            style={{flexGrow: 1}}>{getName(integrationType)}</Typography>
                <IconButton size={"small"}
                            disabled={isLoading}
                            onClick={() => deauthorize(integrationType)}><Clear/>
                </IconButton>
            </Box>
        )
    }

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('integrations.title')}</DialogTitle>
            <DialogContent>
                <Typography variant={"body2"}>
                    {t('integrations.description')}
                </Typography>
                <Box display={"flex"} flexDirection={"column"}>
                    {authorizations.length > 0 ?
                        authorizations.map((integration => getIntegration(integration)))
                        :
                        <Box bgcolor={"background.list"} my={1} p={1} color={"text.secondary"} boxShadow={1}
                             display={"flex"}
                             alignItems={"center"}>
                            <Typography variant={"subtitle2"}
                                        style={{
                                            flexGrow: 1
                                        }}>{t('integrations.empty')}</Typography>
                        </Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={"outlined"} color={"secondary"}>
                    {t('general.close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
