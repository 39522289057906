import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {Auth} from "aws-amplify";
import LoaderButton from "../component/LoaderButton";
import {useAppContext} from "../common/context";
import UnauthenticatedPage from "../component/UnauthenticatedPage";
import {Trans, useTranslation} from "react-i18next";
import {Checkbox, InputAdornment} from "@mui/material";
import Box from "@mui/material/Box";
import {
    requiredField,
    validateEmailField,
    validatePasswordField
} from "../common/util";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)
    const {setToast} = useAppContext();
    const [fields, setFields] = useState({
        firstName: {value: "", visited: false, valid: false, errors: []},
        lastName: {value: "", visited: false, valid: false, errors: []},
        email: {value: "", visited: false, valid: false, errors: []},
        password: {value: "", visited: false, valid: false, errors: []},
        terms: {value: false},
    });
    const [queryEmail, setQueryEmail] = useState("");
    const [valid, setValid] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {search} = useLocation();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        let email = queryParams.get("email");
        if (email) {
            setQueryEmail(email);
        }
    }, [search])

    useEffect(() => {
        if (!fields.email.visited && queryEmail) {
            fields.email.value = queryEmail;
            fields.email.visited = true;
            setFields({...fields})
        }
    }, [fields, queryEmail])

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.signUp({
                username: fields.email.value,
                password: fields.password.value,
                attributes: {
                    email: fields.email.value,
                    name: fields.firstName.value,
                    family_name: fields.lastName.value,
                }
            });
            setIsLoading(false);
            navigate("/confirm", {state: {email: fields.email.value, password: fields.password.value}});
        } catch (e) {
            setToast({message: t('signup.error'), error: e, severity: "error"});
            setIsLoading(false);
        }
    }

    function handleFieldChange(event) {
        fields[event.target.name].value = event.target.value;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function handleBlur(event) {
        fields[event.target.name].visited = true;
        validateFields();
        setFields({
            ...fields,
        });
    }

    function validateFields() {
        validateEmailField(fields.email);
        requiredField(fields.firstName, [t('general.validation.firstName.required')]);
        requiredField(fields.lastName, [t('general.validation.lastName.required')]);
        validatePasswordField(fields.password);
        setValid(fields.email.valid && fields.password.valid && fields.firstName.valid && fields.lastName.valid && fields.terms.value);
    }

    return (
        <UnauthenticatedPage>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="given-name"
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            label={t('signup.firstname')}
                            value={fields.firstName.value}
                            error={fields.firstName.errors.length > 0}
                            helperText={fields.firstName.errors}
                            autoFocus
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            label={t('signup.lastname')}
                            name="lastName"
                            autoComplete="family-name"
                            value={fields.lastName.value}
                            error={fields.lastName.errors.length > 0}
                            helperText={fields.lastName.errors}
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            label={t('general.email')}
                            name="email"
                            autoComplete="email"
                            value={fields.email.value}
                            error={fields.email.errors.length > 0}
                            helperText={fields.email.errors}
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label={t('general.password')}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    (<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>)
                            }}
                            autoComplete="off"
                            value={fields.password.value}
                            error={fields.password.errors.length > 0}
                            helperText={fields.password.errors}
                            onChange={handleFieldChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Checkbox
                                color={"primary"}
                                required
                                checked={fields.terms.value}
                                onChange={(event) => handleFieldChange({
                                    target: {
                                        name: "terms",
                                        value: event.target.checked
                                    }
                                })}
                            />
                            <Typography>
                                <Trans i18nKey="signup.terms">
                                    I agree to Vrex <Link
                                    href="https://www.vrex.no/terms-of-service/"
                                    target={"_blank"}
                                    underline="hover">Terms</Link> and <Link
                                    href={"https://www.vrex.no/privacy-policy-full/"}
                                    target={"_blank"}
                                    underline="hover">Privacy
                                    Policy</Link>
                                </Trans>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    className={classes.submit}
                    disabled={!valid}
                >
                    {t('signup.submit')}
                </LoaderButton>
                <Grid container>
                    <Grid item xs>
                        <Link
                            color={"textSecondary"}
                            component={RouterLink}
                            to={"/login"}
                            variant="body2"
                            underline="hover">
                            {t('signup.haveAccount')}
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </UnauthenticatedPage>
    );
}
