import React, {useState} from "react";
import VrexApi from "../api/vrexApi";
import TextField from "@mui/material/TextField";
import LoaderButton from "./LoaderButton";
import {useFormFieldsName} from "../common/hooks";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Add, ReportProblemOutlined} from "@mui/icons-material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {useNavigate} from "react-router-dom";
import {CircularButton} from "./CircularButton";
import Box from "@mui/material/Box";
import {useAppContext} from "../common/context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {Capacity} from "../common/util";

export function CreateProjectDialog({organizations}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const {setToast} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [fields, handleFieldChange] = useFormFieldsName({
        name: "",
        organizationIndex: 0,
    });
    const {t} = useTranslation();

    function validateForm() {
        return fields.name.length > 0 && organizations.length > 0 && organizations[fields.organizationIndex].capacity.projects > organizations[fields.organizationIndex].activeProjectCount;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleSubmit() {
        setIsLoading(true);
        try {
            const project = await VrexApi.postProject(organizations[fields.organizationIndex].id, {
                name: fields.name,
                organization: {
                    id: organizations[fields.organizationIndex].id
                }
            });
            setIsLoading(false);
            setOpen(false);
            navigate("/projects/" + project.id + "/");
        } catch (e) {
            setToast({message: t('projects.create.error'), error: e, severity: "error"})
            setIsLoading(false);
        }
    }

    return (
        <Box display={"flex"} flexDirection={"row-reverse"}>
            <CircularButton
                title={t('projects.create.title')}
                shadow
                spacing={2}
                disabled={organizations.length === 0}
                onClick={handleClickOpen}>
                <Add/>
            </CircularButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create a new project</DialogTitle>
                <DialogContent>
                    <form>
                        <Box display={"flex"} flexDirection={"column"} marginTop={2}>
                            {organizations.length === 1 ?
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    label="Organization"
                                    name="organization"
                                    disabled={true}
                                    value={organizations[0].name}
                                />
                                :
                                <FormControl variant={"outlined"}>
                                    <InputLabel id="select-organization-label">Organization</InputLabel>
                                    <Select
                                        labelId="select-organization-label"
                                        name="organizationIndex"
                                        value={fields.organizationIndex}
                                        onChange={handleFieldChange}
                                        label={"organization"}
                                    >
                                        {organizations.map((organization, i) =>
                                            <MenuItem key={i} id={"organizationIndex"}
                                                      value={i}>{organization.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            }
                            {organizations.length > 0 && organizations[fields.organizationIndex].capacity.projects < Capacity.UNLIMITED &&
                            <Box display={"flex"} marginTop={1} paddingX={2} alignItems={"flex-end"}>
                                <Typography variant={"body2"}>
                                    {t('projects.create.projectCount', {
                                        max: organizations[fields.organizationIndex].capacity.projects,
                                        current: organizations[fields.organizationIndex].activeProjectCount
                                    })}
                                </Typography>
                            </Box>
                            }
                            {organizations.length > 0 && organizations[fields.organizationIndex].capacity.projects <= organizations[fields.organizationIndex].activeProjectCount ?
                                <Box display={"flex"} marginTop={3} marginBottom={3} alignItems={"flex-end"}>
                                    <ReportProblemOutlined style={{marginRight: theme.spacing(2)}}/>
                                    <Typography variant={"body2"}>{t('projects.create.limitReached')}</Typography>
                                </Box>
                                :
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label="Project Name"
                                    name="name"
                                    autoFocus
                                    onChange={handleFieldChange}
                                />
                            }
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions style={{flexDirection: "row-reverse", justifyContent: "flex-start"}} disableSpacing>
                    <LoaderButton
                        variant="contained"
                        color="primary"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                        onClick={handleSubmit}
                    >
                        {t('general.create')}
                    </LoaderButton>
                    <Button color={"secondary"} variant={"outlined"} onClick={handleClose}
                            style={{marginRight: theme.spacing(1)}}>
                        {t('general.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
