import IconButton from "@mui/material/IconButton";
import {CheckOutlined, HighlightOffOutlined} from "@mui/icons-material";
import React, {useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ConfirmedAction} from "./ConfirmedAction";
import {useAppContext} from "../common/context";
import {useTranslation} from "react-i18next";
import {HelpText} from "./HelpText";
import {tableStyles} from "../common/sharedStyles";
import {Capacity, Roles} from "../common/util";
import {useTheme} from "@mui/material/styles";
import Link from "@mui/material/Link";
import {ProjectUserDialog} from "./ProjectUserDialog";


export function ProjectUserList({project, users, onRemoveUser, disabled, onRoleUpdate}) {
    const classes = tableStyles();
    const {user} = useAppContext();
    const {t} = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    function handleDialogClose() {
        setOpen(false);
    }

    return (
        <Box bgcolor={"background.paper"} my={2} p={2} boxShadow={1}>
            <Typography variant={"h6"}
                        display="inline">
                {t('project.users.title')}
                <HelpText text={t('project.users.list.description')}/>
            </Typography>
            {project.organization.capacity.projectUsers < Capacity.UNLIMITED &&
                <Typography color={"textSecondary"} variant={"body2"} display="inline"
                            style={{paddingRight: theme.spacing(1)}}>
                    {t('project.users.list.memberCount', {
                        current: users.length,
                        max: project.organization.capacity.projectUsers
                    })}
                </Typography>}
            <ProjectUserDialog open={open} handleClose={handleDialogClose} project={project}
                               onRoleChange={onRoleUpdate} projectUser={selectedUser}/>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" aria-label="model table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}
                                       style={{width: "70%"}}>{t('general.users.name')}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{width: "20%"}} align="center">
                                {t('project.users.list.admin.title')}
                                <HelpText text={t('project.users.list.admin.description')}/>
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{width: "10%"}} align="center">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((ou) =>
                            <TableRow key={"u_" + ou.id} className={classes.tableDataRow}>
                                <TableCell className={classes.tableCell}>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            setSelectedUser(ou);
                                            setOpen(true);
                                        }}
                                        underline="hover">
                                        {ou.user.name.length === 0 && ou.user.lastName.length === 0 ? ou.user.email : ou.user.name + " " + ou.user.lastName}
                                    </Link>
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.iconCell}`} align="center">
                                    {ou.role.accessLevel >= Roles.COORDINATOR.accessLevel &&
                                        <CheckOutlined style={{fontSize: "1.2rem"}}
                                        />}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.iconCell}`}
                                           align="right">
                                    <ConfirmedAction title={t('project.users.list.remove.dialog.title')}
                                                     description={t('project.users.list.remove.dialog.description', {
                                                         type: 'project',
                                                         name: ou.user.name ? ou.user.name + " " + ou.user.lastName : ou.user.email,
                                                     })}
                                                     onConfirmed={() => onRemoveUser(ou.id)}>
                                        <IconButton size={"small"}
                                                    disabled={disabled || ou.user.email === user.email}
                                                    color={"secondary"}>
                                            <HighlightOffOutlined/>
                                        </IconButton>
                                    </ConfirmedAction>
                                </TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
