import Typography from "@mui/material/Typography";
import React from "react";
import {useAppContext} from "../common/context";
import VrexApi from "../api/vrexApi";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import List from "@mui/material/List";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";

export default function InvitationsDialog({open, onClose}) {
    const {user, setUser, refreshUser, setToast} = useAppContext();
    const theme = useTheme();
    const {t} = useTranslation();


    function updateOrganizationInvitationsForUser(invitationId) {
        let invitations = user.invitations.filter(invitation => invitation.id !== invitationId);
        let newUser = {
            ...user,
            invitations: invitations,
        };
        setUser(newUser);
        if (newUser.invitations.length === 0) {
            onClose();
        }
    }

    async function acceptOrganizationInvitation(invitationId) {
        try {
            await VrexApi.postAcceptOrganizationInvitation(invitationId);
            updateOrganizationInvitationsForUser(invitationId);
            await refreshUser();
        } catch (e) {
            setToast({message: t('invitations.invitation.error.accept'), error: e, severity: "error"})
        }
    }

    async function declineOrganizationInvitation(invitationId) {
        try {
            await VrexApi.postDeclineOrganizationInvitation(invitationId);
            updateOrganizationInvitationsForUser(invitationId);
        } catch (e) {
            setToast({message: t('invitations.invitation.error.decline'), error: e, severity: "error"})
        }
    }

    function renderInvitations(invitations, accept, decline) {
        return invitations.map((invitation, i) =>
            <Box component={"li"} key={i} bgcolor={"background.list"} my={2} p={2} boxShadow={1} color={"text.primary"}
                 display={"flex"}
                 flexDirection={"column"}>
                <Typography
                    variant={"body2"}>{t('invitations.invitation.message', {
                    invitedBy: invitation.invitedBy,
                    invitedTo: invitation.invitedTo
                })}</Typography>
                <Box pt={2}>
                    <Button style={{marginRight: theme.spacing(2)}} color={"primary"} variant={"contained"}
                            onClick={() => accept(invitation.id)}>
                        {t('invitations.invitation.accept')}
                    </Button>
                    <Button color={"secondary"} variant={"outlined"} onClick={() => decline(invitation.id)}>
                        {t('invitations.invitation.decline')}
                    </Button>
                </Box>
            </Box>
        )
    }

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('invitations.title')}</DialogTitle>
            <DialogContent>
                {user && user.invitations && user.invitations.length > 0 ?
                    <Box>
                        <Typography variant={"body2"}
                                    color={"textSecondary"}
                                    style={{
                                        marginTop: theme.spacing(2),
                                    }}>
                            {t('invitations.organization')}
                        </Typography>
                        <List>
                            {renderInvitations(user.invitations, acceptOrganizationInvitation, declineOrganizationInvitation)}
                        </List>
                    </Box> : ""
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={"outlined"} color={"secondary"}>
                    {t('general.close')}
                </Button>
            </DialogActions>
        </Dialog>

    )
}
