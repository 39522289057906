import {Link, useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {useAppContext} from "../common/context";
import React, {useState} from "react";
import VrexApi from "../api/vrexApi";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";

export function AddLocalSource({projectId, type, onModelSourceAdded}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const {setToast} = useAppContext();
    const {t} = useTranslation();
    const [sourceName, setSourceName] = useState(type === "VRP"?t('project.models.local.vrp.add.dialog.defaultName'):t('project.models.local.vrx.add.dialog.defaultName'));
    const [saving, setSaving] = useState(false);


    async function handleSave() {
        try {
            setSaving(true);
            let modelSource = await VrexApi.postLocalModelSource(projectId, {
                name: sourceName,
                subtype: type,
            });
            onModelSourceAdded(modelSource);
            setSaving(false);
            navigate("/projects/" + projectId + "/models");
        } catch (e) {
            setSaving(false);
            setToast({message: t('project.models.modelSource.add.error'), severity: "error"});
        }
    }

    return (
        <Box bgcolor={"background.paper"} p={2} boxShadow={1}>
            <Typography variant={"h6"} color={"textPrimary"}>{type === "VRP"? t('project.models.local.vrp.add.dialog.title'):t('project.models.local.vrx.add.dialog.title')}</Typography>
            <Typography variant={"body2"} color={"textSecondary"}>{type === "VRP"? t('project.models.local.vrp.add.dialog.description'):t('project.models.local.vrx.add.dialog.description')}</Typography>
            <TextField
                variant="outlined"
                margin="normal"
                label={"Name"}
                defaultValue={sourceName}
                fullWidth
                autoFocus
                onChange={(event) => setSourceName(event.target.value)}
            />
            <Box pt={3} display={"flex"} alignItems={"center"}>
                <Button color={"primary"} variant={"contained"} disabled={sourceName === "" || saving}
                        onClick={handleSave} style={{marginRight: theme.spacing(2)}}>{t('general.ok')}</Button>
                <Button color={"secondary"} variant={"outlined"} component={Link}
                        to={`/projects/${projectId}/models`}>{t('general.cancel')}</Button>
            </Box>
        </Box>
    )
}
