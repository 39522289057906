import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Add, ReportProblemOutlined} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import LoaderButton from "./LoaderButton";
import {Box, Typography} from "@mui/material";
import {CircularButton} from "./CircularButton";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {validateEmail} from "../common/util";

export function InviteOrgUserDialog({onInvite, disabled, capacityReached, currentUsers}) {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const {t} = useTranslation();


    useEffect(() => {
        setEmail("");
    }, [open])

    function emailChanged(event) {
        setEmail(event.target.value);
    }

    function validateForm() {
        return validateEmail(email) && !currentUsers.some(orgUser => orgUser.user.email === email);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleSubmit() {
        setIsLoading(true);
        await onInvite(email);
        setIsLoading(false);
        setOpen(false);
    }

    return (
        <Box display={"flex"} flexDirection={"row-reverse"}>
            <CircularButton
                disabled={disabled}
                title={t('organization.users.invitation.title')}
                shadow
                spacing={2}
                onClick={handleClickOpen}>
                <Add/>
            </CircularButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">{t('organization.users.invitation.dialog.title')}</DialogTitle>
                <DialogContent>
                    <form>
                        {capacityReached ?
                            <Box display={"flex"} marginTop={3} marginBottom={3} alignItems={"flex-end"}>
                                <ReportProblemOutlined style={{marginRight: theme.spacing(2)}}/>
                                <Typography
                                    variant={"body2"}>{t('organization.users.invitation.dialog.capacityReached')}</Typography>
                            </Box>
                            :
                            <Box display={"flex"} flexDirection={"column"}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label={t('general.email')}
                                    name="email"
                                    type="email"
                                    autoFocus
                                    value={email}
                                    onChange={emailChanged}
                                />
                            </Box>}
                    </form>
                </DialogContent>
                <DialogActions style={{flexDirection: "row-reverse", justifyContent: "flex-start"}} disableSpacing>
                    <LoaderButton
                        variant="contained"
                        color="primary"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                        onClick={handleSubmit}
                    >
                        {t('general.users.invite.submit')}
                    </LoaderButton>
                    <Button onClick={handleClose} variant={"outlined"} color={"secondary"}
                            style={{marginRight: theme.spacing(1)}}>
                        {t('general.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
