import React, {useEffect, useState} from "react";
import {Box, Button, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ProjectPage from "../component/ProjectPage";
import {styled, useTheme} from "@mui/material/styles";
import withTheme from '@mui/styles/withTheme';
import {Trans, useTranslation} from "react-i18next";
import {HelpText} from "../component/HelpText";
import {EditableLabel} from "../component/EditableLabel";
import {ConfirmedAction} from "../component/ConfirmedAction";
import VrexApi from "../api/vrexApi";
import {useAppContext} from "../common/context";
import {useNavigate} from "react-router-dom";
import {ProjectStatus} from "../component/ProjectStatus";
import {Roles} from "../common/util";
import {
    AconexConfig,
    BimcollabConfig,
    BimplusConfig,
    BimsyncConfig,
    BimtrackConfig,
    BuildagilConfig,
    StreambimConfig
} from "../common/integrationConfig";
import {EditableNumberLabel} from "../component/EditableNumberLabel";
import Link from "@mui/material/Link";

const StyledRadio = withTheme(styled(Radio)({
        padding: "5px"
    }
));

function StyledFormControlLabelRaw({theme, label, value, disabled}) {
    return (<FormControlLabel style={{color: theme.palette.text.primary}}
                              disabled={disabled}
                              control={<StyledRadio size={"small"} color={"primary"}/>}
                              label={label} value={value}/>);

}

const StyledFormControlLabel = withTheme(StyledFormControlLabelRaw);


export default function ProjectSettings({
                                            project,
                                            onProjectChange,
                                            projectStatusChanged
                                        }) {
    const theme = useTheme();
    const {t} = useTranslation();
    const [orientation, setOrientation] = useState(project.rotation.x === -90 ? "z-up" : "y-up");
    const [usSurveyFeetConversion, setUsSurveyFeetConversion] = useState(project.modelProcessorSettings.pointCloudSettings.e57ScaleFactor === 0.999998);
    const {setToast} = useAppContext();
    const history = useNavigate();

    let inactiveProject = project.status === "INACTIVE";


    useEffect(() => {
        setOrientation(project.rotation.x === -90 ? "z-up" : "y-up");
    }, [project.rotation]);

    async function handleOrientationChange(event) {
        let orientation = event.target.value;
        let rotation = orientation === "z-up" ? {x: -90, y: 0, z: 0} : {x: 0, y: 0, z: 0};
        await onProjectChange({rotation: rotation});
    }

    async function deleteProject() {
        try {
            await VrexApi.deleteProject(project.id);
            history("/projects", {replace: true});
        } catch (e) {
            setToast({message: t('project.settings.delete.error'), error: e, severity: "error"})
        }
    }

    async function deactivateProject() {
        try {
            await VrexApi.deleteProjectActivation(project.id);
            projectStatusChanged("INACTIVE");
        } catch (e) {
            setToast({message: t('project.settings.status.error'), error: e, severity: "error"})
        }
    }

    async function activateProject() {
        try {
            await VrexApi.postProjectActivation(project.id);
            projectStatusChanged("ACTIVE");
        } catch (e) {
            setToast({message: t('project.settings.status.error'), error: e, severity: "error"})
        }
    }

    async function handleProjectStatusChange() {
        if (project.status === "ACTIVE") {
            await deactivateProject();
        } else {
            await activateProject();
        }
    }

    return (
        <ProjectPage>
            {project ?
                <Box display={"flex"} flexDirection={"column"} py={4} width={"auto"}>
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <Typography variant={"h6"}
                                    color={"textPrimary"}>{t('project.settings.name.title')}</Typography>
                        <EditableLabel initialText={project.name}
                                       onValueChanged={async (projectName) => onProjectChange({name: projectName})}
                                       disabled={inactiveProject}/>
                    </Box>
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <FormControl component="fieldset">
                            <Typography variant={"h6"}
                                        color={"textPrimary"}>{t('project.settings.issues.title')}<HelpText
                                text={t('project.settings.issues.description')}/></Typography>
                            <RadioGroup aria-label="issueIntegration" name="issueIntegration"
                                        value={project.issueIntegration.type.toLowerCase()}
                                        color={"primary"}
                                        onChange={async (event) => onProjectChange({issueIntegration: {type: event.target.value}})}
                                        style={{padding: theme.spacing(2)}}>
                                <StyledFormControlLabel disabled={inactiveProject} label="None" value="none"/>
                                <StyledFormControlLabel disabled={inactiveProject} label={BimsyncConfig.name}
                                                        value={BimsyncConfig.slug}/>
                                <StyledFormControlLabel disabled={inactiveProject} label={BimcollabConfig.name}
                                                        value={BimcollabConfig.slug}/>
                                <StyledFormControlLabel disabled={inactiveProject} label={BimtrackConfig.name}
                                                        value={BimtrackConfig.slug}/>
                                <StyledFormControlLabel disabled={inactiveProject} label={StreambimConfig.name}
                                                        value={StreambimConfig.slug}/>
                                <StyledFormControlLabel disabled={inactiveProject} label={AconexConfig.name}
                                                        value={AconexConfig.slug}/>
                                <StyledFormControlLabel disabled={inactiveProject} label={BuildagilConfig.name}
                                                        value={BuildagilConfig.slug}/>
                                <StyledFormControlLabel disabled={inactiveProject} label={BimplusConfig.name}
                                                        value={BimplusConfig.slug}/>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <FormControl component="fieldset">
                            <Typography variant={"h6"}
                                        color={"textPrimary"}>{t('project.settings.orientation.title')}<HelpText
                                text={t('project.settings.orientation.description')}/></Typography>
                            <RadioGroup aria-label="orientations" name="orientations"
                                        value={orientation}
                                        color={"primary"}
                                        onChange={handleOrientationChange}
                                        style={{padding: theme.spacing(2)}}>
                                <StyledFormControlLabel disabled={inactiveProject}
                                                        label={t('project.settings.orientation.default')}
                                                        value={"z-up"}/>
                                <StyledFormControlLabel disabled={inactiveProject}
                                                        label={t('project.settings.orientation.rotated')}
                                                        value={"y-up"}/>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {project.whitelistRanges.length > 0 &&
                        <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                            <FormControl component="fieldset">
                                <Typography variant={"h6"} style={{marginBottom: theme.spacing(2)}}
                                            color={"textPrimary"}>{t('project.settings.whitelist.title')}<HelpText
                                    text={t('project.settings.whitelist.description')}/></Typography>
                                {project.whitelistRanges.map(value => (
                                    <Typography key={value.id} variant={"body2"} color={"textPrimary"}>
                                        {value.range}
                                    </Typography>
                                ))}
                            </FormControl>
                        </Box>
                    }
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <Box display={"flex"} alignItems={"center"}>
                            <FormControl component="fieldset">
                                <Typography variant={"h6"}
                                            color={"textPrimary"}>{t('project.settings.pointCloudCellSize.title')}<HelpText
                                    text={t('project.settings.pointCloudCellSize.description')}/></Typography>
                                <RadioGroup aria-label="pointCloudCellSize" name="pointCloudCellSize"
                                            value={project.modelProcessorSettings.pointCloudSettings.cellSize}
                                            color={"primary"}
                                            onChange={async (event) => onProjectChange({modelProcessorSettings: {pointCloudSettings: {cellSize: Number(event.target.value)}}})}
                                            style={{padding: theme.spacing(2)}}>
                                    <StyledFormControlLabel disabled={inactiveProject}
                                                            label={t('general.low')}
                                                            value={4}/>
                                    <StyledFormControlLabel disabled={inactiveProject}
                                                            label={t('general.normal')}
                                                            value={2}/>
                                    <StyledFormControlLabel disabled={inactiveProject}
                                                            label={t('general.high')}
                                                            value={1}/>
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <Box display={"flex"} alignItems={"center"}>
                            <FormControl component="fieldset">
                                <Typography variant={"h6"}
                                            color={"textPrimary"}>{t('project.settings.pointCloudNoiseReduction.title')}<HelpText
                                    text={t('project.settings.pointCloudNoiseReduction.description')}/></Typography>
                                <RadioGroup aria-label="pointCloudNoiseReduction" name="pointCloudNoiseReduction"
                                            value={project.modelProcessorSettings.pointCloudSettings.noiseReductionLevel}
                                            color={"primary"}
                                            onChange={async (event) => onProjectChange({modelProcessorSettings: {pointCloudSettings: {noiseReductionLevel: Number(event.target.value)}}})}
                                            style={{padding: theme.spacing(2)}}>
                                    <StyledFormControlLabel disabled={inactiveProject}
                                                            label={t('general.off')}
                                                            value={0}/>
                                    <StyledFormControlLabel disabled={inactiveProject}
                                                            label={t('general.low')}
                                                            value={1}/>
                                    <StyledFormControlLabel disabled={inactiveProject}
                                                            label={t('general.medium')}
                                                            value={2}/>
                                    <StyledFormControlLabel disabled={inactiveProject}
                                                            label={t('general.high')}
                                                            value={3}/>
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <FormControl component="fieldset">
                            <Typography variant={"h6"}
                                        color={"textPrimary"}>{t('project.settings.usSurveyFeetConversion.title')}<HelpText
                                text={t('project.settings.usSurveyFeetConversion.description')}/></Typography>
                            <RadioGroup aria-label="usSurveyFeetConversion" name="usSurveyFeetConversion"
                                        value={usSurveyFeetConversion}
                                        color={"primary"}
                                        onChange={async (event) => {
                                            let value = event.target.value === "true";
                                            onProjectChange({modelProcessorSettings: {pointCloudSettings: {e57ScaleFactor: value ? 0.999998 : 1}}});
                                            setUsSurveyFeetConversion(value);
                                        }}
                                        style={{padding: theme.spacing(2)}}>
                                <StyledFormControlLabel disabled={inactiveProject}
                                                        label={t('general.on')}
                                                        value={true}/>
                                <StyledFormControlLabel disabled={inactiveProject}
                                                        label={t('general.off')}
                                                        value={false}/>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <FormControl component="fieldset">
                            <Typography variant={"h6"}
                                        color={"textPrimary"}>{t('project.settings.excludeMetadata.title')}<HelpText
                                text={t('project.settings.excludeMetadata.description')}/></Typography>
                            <RadioGroup aria-label="excludeMetadata" name="excludeMetadata"
                                        value={!project.modelProcessorSettings.metadataSettings.fullExtraction}
                                        color={"primary"}
                                        onChange={async (event) => {
                                            let value = event.target.value === "true";
                                            onProjectChange({modelProcessorSettings: {metadataSettings: {fullExtraction: !value}}});
                                        }}
                                        style={{padding: theme.spacing(2)}}>
                                <StyledFormControlLabel disabled={inactiveProject}
                                                        label={t('general.on')}
                                                        value={true}/>
                                <StyledFormControlLabel disabled={inactiveProject}
                                                        label={t('general.off')}
                                                        value={false}/>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <Box display={"flex"} alignItems={"center"}>
                            <FormControl component="fieldset">
                                <Typography variant={"h6"}
                                            color={"textPrimary"}>
                                    {t('project.settings.geometryReduction.title')}
                                    <HelpText
                                        element={<Typography variant={"body2"} color={"textSecondary"}
                                                             style={{padding: theme.spacing(1)}}>
                                            <Trans
                                                i18nKey="project.settings.geometryReduction.description"
                                                values={{email:"support@vrex.no"}}
                                                components={[<Link href={"mailto:support@vrex.no"} underline={'hover'}/>]}>
                                            </Trans>
                                        </Typography>}
                                    />
                                </Typography>

                                <EditableNumberLabel
                                    initialValue={project.modelProcessorSettings.geometrySettings.denseObjectRemovalThreshold.toString()}
                                    onValueChanged={async (threshold) => onProjectChange({modelProcessorSettings: {geometrySettings: {denseObjectRemovalThreshold: threshold}}})}
                                    disabled={inactiveProject}/>
                            </FormControl>
                        </Box>
                    </Box>
                    <ProjectStatus project={project} handleChange={handleProjectStatusChange}/>
                    <Box bgcolor={"background.paper"} p={2} mt={2} boxShadow={1}>
                        <Typography variant={"h6"}
                                    color={"textPrimary"}
                                    style={{marginBottom: theme.spacing(2)}}>{t('project.settings.delete.title')}<HelpText
                            text={t('project.settings.delete.description')}/></Typography>
                        <ConfirmedAction title={t('project.settings.delete.dialog.title')}
                                         description={t('project.settings.delete.dialog.description', {name: project.name})}
                                         onConfirmed={deleteProject}>
                            <Button variant={"contained"} color="primary"
                                    disabled={project.role.accessLevel <= Roles.COORDINATOR.accessLevel}>{t('project.settings.delete.action')}</Button>
                        </ConfirmedAction>
                    </Box>
                </Box> : ""}
        </ProjectPage>
    );
}
