import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {getRole, Roles} from "../common/util";
import {useTheme} from "@mui/material/styles";
import {DialogActions, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../common/context";
import {HelpText, RoleDescriptions} from "./HelpText";
import Button from "@mui/material/Button";

export function ProjectUserDialog({open, projectUser, project, handleClose, onRoleChange}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const {user} = useAppContext();

    function formatText(title, value) {
        return <Box display={"flex"}>
            <Typography variant={"body2"} color={"textSecondary"}
                        style={{width: "80px", marginBottom: theme.spacing(1)}}>{title}</Typography>
            <Typography variant={"body2"}>{value}</Typography>
        </Box>;
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle onClose={handleClose}>
                {t('project.users.list.details.title')}
            </DialogTitle>
            <DialogContent>
                {projectUser &&
                    <Box display={"flex"} flexDirection={"column"}>
                        {formatText(t('general.users.name') + ":", projectUser.user.name + " " + projectUser.user.lastName)}
                        {formatText(t('general.users.email') + ":", projectUser.user.email)}
                        <Box display={"flex"}>
                            <Typography variant={"body2"} color={"textSecondary"}
                                        style={{
                                            width: "80px",
                                            marginBottom: theme.spacing(1)
                                        }}>{t('general.users.status.title') + ":"}<HelpText
                                text={t('general.users.status.description')}/></Typography>
                            <Typography variant={"body2"}>{projectUser.status}</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"baseline"}>
                            <Typography variant={"body2"} color={"textSecondary"}
                                        style={{
                                            width: "80px",
                                            marginBottom: theme.spacing(2)
                                        }}>{"Role:"}<RoleDescriptions/></Typography>
                            {project.role.accessLevel === Roles.ADMIN.accessLevel ?
                                <Select style={{flexGrow: 1}}
                                        disabled={project.role.accessLevel < Roles.ADMIN.accessLevel || projectUser.user.email === user.email}
                                        value={projectUser.role.accessLevel}
                                        onChange={(event) => onRoleChange(projectUser, getRole(event.target.value))}
                                        variant={"standard"}>
                                    {Object.values(Roles).map((role, i) =>
                                        <MenuItem key={i} id={"accessLevel"}
                                                  value={role.accessLevel}>{role.title}</MenuItem>
                                    )}
                                </Select> :
                                <Typography variant={"body2"}>{getRole(projectUser.role.accessLevel).title}</Typography>
                            }
                        </Box>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"outlined"} color="secondary" autoFocus>
                    {t('general.close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
