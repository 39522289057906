import Accordion from "./Accordion";
import {CloudUploadOutlined, GrainOutlined, HighlightOffOutlined} from "@mui/icons-material";
import {Box} from "@mui/material";
import NAVISWORKS_IMAGE from "../images/navisworks.png";
import {LocalSource} from "./Icons";
import {EditableLabel} from "./EditableLabel";
import {HelpText, HelpTextButton} from "./HelpText";
import {ConfirmedAction} from "./ConfirmedAction";
import {ModelTable} from "./Model";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import UploadDialog from "./UploadDialog";
import {UploadTable} from "./Upload";


export default function LocalModelSource({
                                             disabled,
                                             projectId,
                                             modelSource,
                                             onDeleteModelSource,
                                             onRenameModelSource,
                                             onDeleteModel,
                                             onDeleteModels,
                                             converters,
                                         }) {
    const [openUpload, setOpenUpload] = useState(false);
    const {t} = useTranslation();

    return (
        <React.Fragment key={modelSource.id}>
            <UploadDialog open={openUpload} projectId={projectId} modelSourceId={modelSource.id}
                          type={modelSource.subType}
                          handleExit={() => setOpenUpload(false)}/>
            <Accordion defaultExpanded={true}
                       summary={
                           <React.Fragment>
                               {modelSource.type.toUpperCase() === "LOCAL" ? modelSource.subType.toUpperCase() === "VRP" ?
                                       <GrainOutlined/> : <LocalSource/> :
                                   <img src={NAVISWORKS_IMAGE} alt={""}/>}
                               <EditableLabel initialText={modelSource.name} variant={"h6"}
                                              onValueChanged={(modelSourceName) => onRenameModelSource(modelSource.id, modelSourceName)}/>
                               <HelpText
                                   text={modelSource.type.toUpperCase() === "LOCAL" ? modelSource.subType.toUpperCase() === "VRP" ?
                                       t('project.models.local.vrp.description') : t('project.models.local.vrx.description') : t('project.models.localConverted.navisworks.description')}/>
                           </React.Fragment>
                       }>
                <Box display={"flex"} px={2} mt={1}>
                    {modelSource.type.toUpperCase() === "LOCAL" &&
                        <HelpTextButton title={t('project.models.local.common.upload.title')}
                                        disabled={disabled}
                                        onClick={() => setOpenUpload(true)}
                                        startIcon={<CloudUploadOutlined/>}
                                        helpText={modelSource.subType.toUpperCase() === "VRP" ? t('project.models.local.vrp.upload.description') : t('project.models.local.vrx.upload.description')}/>}
                    <ConfirmedAction title={t('project.models.modelSource.delete.dialog.title')}
                                     description={t('project.models.modelSource.delete.dialog.description', {name: modelSource.name})}
                                     onConfirmed={() => onDeleteModelSource(modelSource.id)}>
                        <HelpTextButton title={t('project.models.modelSource.delete.title')}
                                        disabled={disabled}
                                        helpText={t("project.models.modelSource.delete.description")}
                                        startIcon={<HighlightOffOutlined/>}/>
                    </ConfirmedAction>
                </Box>
                <Box sx={{px: 2, pt: 1, pb: 2}}>
                    {modelSource.uploads && modelSource.uploads.length > 0 &&
                        <UploadTable uploads={modelSource.uploads}/>}
                    {modelSource.models.length > 0 && <ModelTable models={modelSource.models}
                                                                  converters={converters}
                                                                  disabledDelete={disabled || !modelSource.models || modelSource.models.length === 0}
                                                                  modelSourceType={modelSource.type}
                                                                  onDeleteModels={onDeleteModels}
                                                                  onDeleteModel={onDeleteModel}>

                    </ModelTable>}
                </Box>
            </Accordion>
        </React.Fragment>
    )
}
