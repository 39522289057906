import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import {Link as RouterLink, useNavigate, useLocation} from "react-router-dom";
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import {useFormFieldsId} from "../common/hooks";
import {Auth} from "aws-amplify";
import LoaderButton from "../component/LoaderButton";
import {useAppContext} from "../common/context";
import UnauthenticatedPage from "../component/UnauthenticatedPage";
import {useTranslation} from "react-i18next";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import {validateEmail} from "../common/util";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Confirm() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [open, setOpen] = useState(false);
    const {userHasAuthenticated, setToast} = useAppContext();
    const location = useLocation();
    const [fields, handleFieldChange] = useFormFieldsId({
        email: location.state?.email,
        password: location.state?.password,
        confirmationCode: "",
    });
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        if (!location.state?.email) {
            navigate("/login", {replace: true});
        }
    }, [navigate, location])

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.confirmSignUp(fields.email, fields.confirmationCode);
            await Auth.signIn(fields.email, fields.password);
            userHasAuthenticated(true);
            navigate("/help");
        } catch (e) {
            setToast({message: t('confirm.error'), error: e, severity: "error"})
            setIsLoading(false)
        }
    }

    async function resendCode(event) {
        event.preventDefault();
        if (isSendingCode) {
            return;
        }
        setIsSendingCode(true);
        try {
            await Auth.resendSignUp(fields.email);
            setOpen(true);
            setIsSendingCode(false);
        } catch (e) {
            setToast({message: t('confirm.error'), error: e, severity: "error"})
            setIsSendingCode(false)
        }
    }

    function validateForm() {
        return validateEmail(fields.email) && fields.confirmationCode.length > 0;
    }

    return (
        <UnauthenticatedPage>
            <form className={classes.form} noValidate
                  onSubmit={handleConfirmationSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label={t('general.email')}
                            name="email"
                            autoComplete="email"
                            value={fields.email}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="confirmationCode"
                            variant="outlined"
                            required
                            fullWidth
                            id="confirmationCode"
                            label={t('confirm.code')}
                            value={fields.confirmationCode}
                            onChange={handleFieldChange}
                        />
                        <Typography variant="body2" color="textSecondary" style={{marginTop: theme.spacing(1)}}>
                            {t('confirm.description')}
                        </Typography>
                    </Grid>
                </Grid>
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    className={classes.submit}
                    disabled={!validateForm()}
                >
                    {t('confirm.confirm')}
                </LoaderButton>
                <Grid container>
                    <Grid item xs>
                        <Link
                            color={"textSecondary"}
                            style={{cursor: "pointer"}}
                            onClick={resendCode}
                            variant="body2"
                            underline="hover">
                            {t('confirm.missingCode')}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            color={"textSecondary"}
                            component={RouterLink}
                            to={"/login"}
                            variant="body2"
                            underline="hover">
                            {t('signup.haveAccount')}
                        </Link>
                    </Grid>
                </Grid>
            </form>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t('confirm.dialog.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('confirm.dialog.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{flexDirection: "row-reverse", justifyContent: "flex-start"}} disableSpacing>
                    <Button color={"secondary"} variant={"outlined"} onClick={() => setOpen(false)}
                            style={{marginRight: theme.spacing(1)}}>
                        {t('general.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </UnauthenticatedPage>
    );
}
