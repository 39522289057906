import makeStyles from '@mui/styles/makeStyles';


export const tableStyles = makeStyles(theme => ({
    tableHeaderRow: {
        paddingLeft: theme.spacing(2),
    },
    tableDataRow: {
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.background.list,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.listAlt,
        },
    },
    tableCell: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '&:last-child': {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        '&:first-child': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: 12,
    },
    iconCell: {
        '&:last-child': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        textOverflow: "clip",
        paddingTop: 0,
        paddingBottom: 0,
    },
    tableContainer: {
        boxSizing: "border-box",
    },
    select: {
        fontSize: '12px',
        padding: 0
    },
    selectItem: {
        fontSize: '12px',
    }
}))
