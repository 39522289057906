import {SvgIcon} from "@mui/material";
import {ReactComponent as Cube} from "../images/cube.svg";
import {ReactComponent as VR_icon} from "../images/vr_icon.svg";

export function LocalSource(props) {
    return (
        <SvgIcon {...props} component={Cube} inheritViewBox/>
    );
}

export function VR(props) {
    return(
        <SvgIcon {...props} component={VR_icon} inheritViewBox/>
    );
}
