import React, {useState} from 'react';
import VREX_IMAGE from '../images/Vrex_white_small_h.png'
import VREX_ACADEMY_IMAGE from '../images/academy.png'
import {
    alpha,
    AppBar,
    Badge,
    Button,
    lighten,
    Menu,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {useAppContext} from "../common/context";
import {useTheme} from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import IconButton from "@mui/material/IconButton";
import {
    AccountCircle,
    GetAppOutlined,
    Info,
    MenuOutlined,
    NightsStayOutlined,
    SettingsOutlined,
    WbSunnyOutlined
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {ChangePasswordDialog} from "./ChangePasswordDialog";
import {useTranslation} from "react-i18next";
import IntegrationsDialog from "../containers/integrations/IntegrationsDialog";
import InvitationsDialog from "../containers/InvitationsDialog";
import {logout} from "../api/vrexAuth";

const useStyles = makeStyles(theme => ({
        topBarButton: {
            '&:hover': {
                backgroundColor: lighten(theme.palette.tertiary.main, 0.1)
            }
        },
        "@keyframes pulse": {
            "0%": {
                outlineColor: alpha(theme.palette.primary.main, 1),
                outlineWidth: "0px"
            },
            "100%": {
                outlineColor: alpha(theme.palette.primary.main, 0),
                outlineWidth: "6px"
            }
        },
        pulse: {
            outlineStyle: "solid",
            overflow: "visible",
            animationName: "$pulse",
            animationDuration: "1s",
            animationTimingFunction: "ease-out",
            animationIterationCount: "infinite"
        }
    })
)

export default function TopBar({appTheme, onAppThemeChanged}) {
    const {userHasAuthenticated, user, setToast, vrexUrl} = useAppContext();
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [integrationsOpen, setIntegrationsOpen] = useState(false);
    const [invitationsOpen, setInvitationsOpen] = useState(false);
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const userMenuOpen = Boolean(userAnchorEl);
    const [siteAnchorEl, setSiteAnchorEl] = useState(null);
    const siteMenuOpen = Boolean(siteAnchorEl);
    const pendingInvitationCount = user === null ? 0 : user.invitations.length;
    const theme = useTheme();
    const {t} = useTranslation();
    const classes = useStyles();

    async function handleLogout() {
        try {
            handleUserMenuClose();
            await logout();
            userHasAuthenticated(false);
        } catch (e) {
            setToast({message: t('topBar.logout.error'), error: e, severity: "error"})
        }
    }

    const handleSiteMenu = (event) => {
        setSiteAnchorEl(event.currentTarget);
    };

    const handleSiteMenuClose = () => {
        setSiteAnchorEl(null);
    };

    const handleUserMenu = (event) => {
        setUserAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserAnchorEl(null);
    };

    function handleAppThemeChanged(event, newAppTheme) {
        handleUserMenuClose();
        if(!newAppTheme){
            return;
        }
        onAppThemeChanged(newAppTheme);
    }

    function renderThemeMenuItem() {
        return (
            <MenuItem>
                <Box display={"flex"} flexDirection={"column"}>
                    <ToggleButtonGroup
                        value={appTheme}
                        exclusive
                        onChange={handleAppThemeChanged}
                        aria-label="text alignment"
                        size={"small"}
                    >
                        <ToggleButton value="light" aria-label="light" style={{padding: "4"}}>
                            <WbSunnyOutlined/>
                        </ToggleButton>
                        <ToggleButton value="dark" aria-label="dark" style={{padding: "4"}}>
                            <NightsStayOutlined/>
                        </ToggleButton>
                        <ToggleButton value="auto" aria-label="auto" style={{padding: "4"}}>
                            {t('topBar.theme.auto')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </MenuItem>);
    }

    return (
        <React.Fragment>
            <AppBar position={"static"} elevation={0} style={{backgroundColor: theme.palette.tertiary.main}}>
                <Toolbar style={{minHeight: theme.spacing(6)}}>
                    {user ?
                        <React.Fragment>
                            <Button edge="start" color="inherit" aria-label="menu" onClick={handleSiteMenu}
                                    className={classes.topBarButton}>
                                <MenuOutlined/>
                                <Box pl={1}
                                     style={{
                                         backgroundImage: `url(${VREX_IMAGE})`,
                                         backgroundSize: "contain",
                                         backgroundRepeat: "no-repeat",
                                         minWidth: "150px",
                                         minHeight: "30px",
                                     }}/>
                            </Button>
                            <Menu
                                open={siteMenuOpen}
                                anchorEl={siteAnchorEl}
                                onClose={handleSiteMenuClose}
                                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                                transformOrigin={{vertical: "top", horizontal: "left"}}
                                PaperProps={{
                                    style: {
                                        borderRadius: 0,
                                    }
                                }}
                            >
                                <MenuItem onClick={handleSiteMenuClose} component={Link}
                                          to={"/projects"}>{t('projects.title')}</MenuItem>
                                <MenuItem onClick={handleSiteMenuClose} component={Link}
                                          to={"/organizations"}>{t('organizations.title')}</MenuItem>
                                <MenuItem onClick={handleSiteMenuClose} component={Link}
                                          to={"/help"}>{t('help.title')}</MenuItem>
                                <MenuItem onClick={handleSiteMenuClose} component={Link}
                                          to={"/downloads"}>{t('downloads.title')}</MenuItem>
                            </Menu>

                        </React.Fragment> : ""}
                    <Box flexGrow={1}/>
                    <Button href={process.env.REACT_APP_ACADEMY_URL} target={"_blank"}
                            className={classes.topBarButton}
                            style={{
                                textTransform: "none", marginRight: theme.spacing(1)
                            }}>
                        <Box pl={1}
                             style={{
                                 backgroundImage: `url(${VREX_ACADEMY_IMAGE})`,
                                 backgroundSize: "contain",
                                 backgroundRepeat: "no-repeat",
                                 minWidth: "80px",
                                 minHeight: "24px",
                             }}/>
                    </Button>
                    <Button href={vrexUrl} target={"_blank"}
                            className={classes.topBarButton}
                            style={{textTransform: "none", marginRight: theme.spacing(1)}}>
                        <Typography variant="caption" style={{color: theme.palette.tertiary.contrastText}}>
                            {t('downloads.vrexViewer.title')}
                        </Typography>
                        <GetAppOutlined style={{color: theme.palette.tertiary.contrastText}}/>
                    </Button>
                    {user ?
                        <div>
                            <Tooltip title={user.email}>
                                <Button onClick={handleUserMenu}
                                        className={classes.topBarButton}
                                        style={{textTransform: "none"}}>
                                    <Typography variant="caption" style={{
                                        color: theme.palette.tertiary.contrastText,
                                        marginRight: theme.spacing(1)
                                    }}>
                                        {user.name + " " + user.lastName}
                                    </Typography>
                                    <Badge color={"primary"} badgeContent={pendingInvitationCount}>
                                        <AccountCircle style={{color: theme.palette.tertiary.contrastText}}/>
                                    </Badge>
                                </Button>
                            </Tooltip>
                            <Menu
                                open={userMenuOpen}
                                anchorEl={userAnchorEl}
                                onClose={handleUserMenuClose}
                                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                transformOrigin={{vertical: "top", horizontal: "right"}}
                                PaperProps={{
                                    style: {
                                        borderRadius: 0,
                                    }
                                }}
                            >
                                {renderThemeMenuItem()}
                                <MenuItem onClick={() => {
                                    handleUserMenuClose();
                                    setIntegrationsOpen(true);
                                }}>{t('integrations.title')}</MenuItem>

                                {pendingInvitationCount > 0 ?
                                    <MenuItem onClick={() => {
                                        handleUserMenuClose();
                                        setInvitationsOpen(true);
                                    }}>
                                        <Box display={"flex"} flexGrow={1} justifyContent={"space-between"}>
                                            <Box flexGrow={1}>{t('invitations.title')}</Box>
                                            <Box fontSize={"0.75rem"}
                                                 lineHeight={"20px"}
                                                 height={"20px"} width={"20px"}
                                                 textAlign={"center"}
                                                 color={"primary.contrastText"}
                                                 bgcolor={"primary.main"}
                                                 borderRadius="50%px">{pendingInvitationCount}</Box>
                                        </Box>
                                    </MenuItem>
                                    : ""}
                                <MenuItem onClick={() => {
                                    handleUserMenuClose();
                                    setChangePasswordOpen(true);
                                }}>{t('topBar.changePassword.title')}</MenuItem>
                                <MenuItem onClick={handleLogout}>{t('topBar.logout.title')}</MenuItem>
                            </Menu>
                            <InvitationsDialog open={invitationsOpen} onClose={() => setInvitationsOpen(false)}/>
                            <IntegrationsDialog open={integrationsOpen} onClose={() => setIntegrationsOpen(false)}/>
                            <ChangePasswordDialog open={changePasswordOpen} onClose={() => {
                                setChangePasswordOpen(false);
                            }
                            }/>
                        </div>
                        :
                        <div>
                            <IconButton
                                aria-label="settings"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleUserMenu}
                                color="inherit"
                                size={"small"}
                            >
                                <SettingsOutlined/>
                            </IconButton>
                            <Menu
                                open={userMenuOpen}
                                anchorEl={userAnchorEl}
                                onClose={handleUserMenuClose}
                                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                transformOrigin={{vertical: "top", horizontal: "right"}}
                                PaperProps={{
                                    style: {
                                        borderRadius: 0,
                                    }
                                }}
                            >
                                {renderThemeMenuItem()}
                            </Menu>
                        </div>
                    }
                </Toolbar>
            </AppBar>
            {pendingInvitationCount > 0 &&
                <Box py={1} px={2} bgcolor={theme.palette.background.paper} display={"flex"}>
                    <Info className={classes.pulse}
                          style={{marginRight: theme.spacing(1), borderRadius: "50%"}} color={"primary"}/>
                    <Typography>{t('topBar.invitations.text')}</Typography>
                    <Button variant={"contained"} color={"primary"}
                            style={{marginLeft: theme.spacing(2), paddingTop: "0px", paddingBottom: "0px"}}
                            onClick={() => setInvitationsOpen(true)}>{t('topBar.invitations.action')}</Button>
                </Box>}
        </React.Fragment>
    );
}

