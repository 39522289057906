import {Box, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

export function AuthError({backUrl}) {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const {t} = useTranslation()

    return <Box display={"flex"} flexDirection={"column"} pt={2}>
        <Typography>{t('integrations.error.authentication')}</Typography>
        <Box pt={3} display={"flex"} alignItems={"center"}>
            <Button variant={"contained"} color={"primary"} onClick={() => navigate(location.pathname, {replace: true})}
                    style={{marginRight: theme.spacing(2)}}>{t('general.retry')}</Button>
            <Button variant={"outlined"} color={"secondary"} onClick={() => navigate(backUrl, {replace: true})}>{t('general.cancel')}</Button>
        </Box>
    </Box>
}
