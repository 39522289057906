import React from "react";
import BimsyncApi from "../../../api/bimsyncApi";
import { BimsyncConfig } from "../../../common/integrationConfig";
import {IntegrationBrowser} from "../IntegrationBrowser";

export function BimsyncBrowser({projectId, onModelAdded}) {

    async function fetchRootNodes(integration) {
        const response = await BimsyncApi.getModels(integration.externalProjectId);
        return response.map(item => ({
            id: item.id,
            name: item.name,
            selected: false,
            disabled: integration.models.find(model => model.externalId === item.id),
            isLeaf: true,
        }));
    }

    return (
        <IntegrationBrowser projectId={projectId}
                            integrationConfig={BimsyncConfig}
                            onModelAdded={onModelAdded}
                            fetchRootNodes={fetchRootNodes}
                            getFileName={(model) => model.name + ".ifc"}/>
    )
}
