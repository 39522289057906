import {Box, FormControlLabel, Switch, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {HelpTextButton} from "./HelpText";
import {HighlightOffOutlined, EditOutlined, FileCopyOutlined} from "@mui/icons-material";
import {ConfirmedAction} from "./ConfirmedAction";
import IconButton from "@mui/material/IconButton";
import React, {useState} from "react";
import {RoomDialog} from "./RoomDialog";
import {useTranslation} from "react-i18next";
import {HelpText} from "./HelpText";


export default function Room({room, disabled, removeRoom, shareRoom, unShareRoom, editRoom, validateRoomName}) {
    const [editingRoom, setEditingRoom] = useState(false);
    const {t} = useTranslation();

    return (
        <Box display={"flex"} my={2} p={2} bgcolor={"background.paper"} flexDirection={"column"}
             alignItems={"flex-start"} boxShadow={1}>
            <Typography component={"div"} variant={"h6"} align={"center"}>{room.name}</Typography>
            <Box display={"flex"} pt={2} pb={1}>
                <HelpTextButton title={t('project.rooms.edit.title')} onClick={() => setEditingRoom(true)}
                                startIcon={<EditOutlined/>} disabled={disabled}
                                helpText={t('project.rooms.edit.description')}/>
                <ConfirmedAction title={t('project.rooms.delete.dialog.title')}
                                 description={t('project.rooms.delete.dialog.description', {name: room.name})}
                                 onConfirmed={() => removeRoom(room.id)}>
                    <HelpTextButton disabled={disabled} title={t('project.rooms.delete.title')}
                                    startIcon={<HighlightOffOutlined/>} helpText={t('project.rooms.delete.description')}/>
                </ConfirmedAction>
                <RoomDialog open={editingRoom} title={t('project.rooms.edit.dialog.title')}
                            onClose={() => setEditingRoom(false)}
                            validateRoomName={validateRoomName} submitText={t('general.save')}
                            onSubmit={r => {
                                editRoom(room.id, r);
                                setEditingRoom(false)
                            }} initialName={room.name} initialDescription={room.description}/>
            </Box>
            {room.description !== "" && (
                <Box bgcolor={"background.list"} width={"100%"} p={2} mb={2}>
                    <Typography variant={"body2"}>{room.description}</Typography>
                </Box>)}
            <Box bgcolor={"background.list"} width={"100%"} p={2} display={"flex"} flexDirection={"column"}>
                <FormControlLabel
                    disabled={disabled}
                    control={
                        <ConfirmedAction
                            onConfirmed={() => !room.externalAccess ? shareRoom(room.id) : unShareRoom(room.id)}
                            shouldConfirmOnClick={() => room.externalAccess}
                            title={t('project.rooms.code.remove.title')}
                            description={t('project.rooms.code.remove.description')}>
                            <Switch
                                disabled={disabled}
                                checked={Boolean(room.externalAccess)}
                                color="primary"
                            />
                        </ConfirmedAction>
                    }
                    label={
                        <Box display={"flex"}>
                            <Typography>
                                {t('project.rooms.code.title')}
                            </Typography>
                            <HelpText text={t('project.rooms.code.description')}/>
                        </Box>
                    }
                />
                {room.externalAccess && (
                    <Box display={"flex"} pt={2} alignItems={"center"}>
                        <Tooltip title={t('project.rooms.code.tooltip')}>
                            <IconButton
                                color={"secondary"}
                                onClick={() => navigator.clipboard.writeText(room.externalAccess.code)}
                                size="large"><FileCopyOutlined/></IconButton>
                        </Tooltip>
                        <Box>
                            <Typography
                                variant={"body2"}>{t('project.rooms.code.code', {code: room.externalAccess.code})}
                            </Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {t('project.rooms.code.expiration', {time: new Date(room.externalAccess.expires).toLocaleString()})}
                            < /Typography>
                        </Box>
                    </Box>)}
            </Box>
        </Box>
    );
}
