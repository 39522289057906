import React from "react";
import Box from "@mui/material/Box";
import VREX_IMAGE_LIGHT from "../images/Vrex_white_textless.png";
import VREX_IMAGE_DARK from "../images/Vrex_dark_textless.png";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";
import { useTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
    },
}));

export default function UnauthenticatedPage(props) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Container component="main" maxWidth="xs">
            <Box className={classes.paper}>
                <Box style={{
                    backgroundImage: theme.palette.mode === "dark"?`url(${VREX_IMAGE_LIGHT})`:`url(${VREX_IMAGE_DARK})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    minWidth: "75px",
                    minHeight: "75px",
                }}>
                </Box>
                {props.children}
                <Box mt={8}>
                    <Copyright/>
                </Box>
            </Box>
        </Container>
    );
}
