import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Navigate, Route, Routes, useNavigate, useParams} from "react-router-dom";
import Models from "./Models";
import Rooms from "./Rooms";
import ProjectUsers from "./ProjectUsers";
import List from "@mui/material/List";
import {HouseOutlined, MeetingRoomOutlined, PeopleOutlined, SettingsOutlined} from "@mui/icons-material";
import {useAppContext} from "../common/context";
import VrexApi from "../api/vrexApi";
import {Box} from "@mui/material";
import ProjectSettings from "./ProjectSettings";
import {MenuButton} from "../component/MenuButton";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import MainPage from "../component/MainPage";
import {Roles} from "../common/util";


export function Project() {
    const {projectId} = useParams();
    const [project, setProject] = useState(null);
    const {isAuthenticated, liveUpdatesReady, subscribeToProject, setToast} = useAppContext();
    const navigate = useNavigate();
    const theme = useTheme();
    const {t} = useTranslation();


    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            try {
                const loadedProject = await VrexApi.getProject(projectId);
                setProject(loadedProject);
            } catch (e) {
                setToast({message: t('project.error'), error: e, severity: "error"});
                navigate("/projects");
            }
        }

        onLoad().then();
    // We only care about changes to isAuthenticated and projectId
    // eslint-disable-next-line
    }, [projectId, isAuthenticated]);

    useEffect(() => {
        if (liveUpdatesReady) {
            subscribeToProject(parseInt(projectId, 10))
        }
    }, [projectId, liveUpdatesReady, subscribeToProject]);

    async function onProjectChange(projectChange) {
        try {
            const patchedProject = await VrexApi.patchProject(project.id, projectChange);
            setProject(patchedProject);
        } catch (e) {
            setToast({message: t('project.settings.error'), error: e, severity: "error"});
        }
    }

    function projectStatusChanged(status) {
        project.status = status;
        let p = {...project};
        setProject(p);
    }

    function getRedirectPath() {
        if (project.role.accessLevel >= Roles.COORDINATOR.accessLevel) {
            return project.status === "ACTIVE" ? `/projects/${projectId}/models` : `/projects/${projectId}/settings`;
        }
        return `/projects/${projectId}/members`;
    }

    return (
        <MainPage title={project ? project.name : ""}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                sx={{flexGrow: '1'}}
            >
                <Grid item xs={12} sm={3} md={2} lg={2} xl={2}
                      sx={{backgroundColor: theme.palette.background.default}}>
                    <Box pt={3}>
                        {project ?
                            <List>
                                {project.role.accessLevel >= Roles.COORDINATOR.accessLevel &&
                                    <MenuButton name={t('project.models.title')}
                                                path={`/projects/${projectId}/models`}>
                                        <HouseOutlined style={{color: theme.palette.text.secondary}}/>
                                    </MenuButton>}
                                <MenuButton name={t('project.rooms.title')} path={`/projects/${projectId}/rooms`}>
                                    <MeetingRoomOutlined style={{color: theme.palette.text.secondary}}/>
                                </MenuButton>
                                <MenuButton name={t('project.users.title')} path={`/projects/${projectId}/members`}>
                                    <PeopleOutlined style={{color: theme.palette.text.secondary}}/>
                                </MenuButton>
                                {project.role.accessLevel >= Roles.COORDINATOR.accessLevel &&
                                    <MenuButton name={t('project.settings.title')}
                                                path={`/projects/${projectId}/settings`}>
                                        <SettingsOutlined style={{color: theme.palette.text.secondary}}/>
                                    </MenuButton>}
                            </List> : ""}
                    </Box>
                </Grid>
                {project && (
                    <Routes>
                        {project.role.accessLevel >= Roles.COORDINATOR.accessLevel &&
                            <Route path={"models/*"}
                                   element={<Models project={project}/>}/>}

                        <Route path={"rooms"}
                               element={<Rooms project={project}/>}/>

                        <Route path={"members"}
                               element={<ProjectUsers project={project}/>}/>
                        {project.role.accessLevel >= Roles.COORDINATOR.accessLevel &&
                            <Route path={"settings"}
                                   element={<ProjectSettings project={project}
                                                             onProjectChange={onProjectChange}
                                                             projectStatusChanged={projectStatusChanged}/>}/>}
                        <Route path={"*"}
                               element={<Navigate
                                   to={{pathname: getRedirectPath()}} replace/>}/>
                    </Routes>
                )}
            </Grid>
        </MainPage>
    )
}

