import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {Dialog, LinearProgress, TableContainer} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {Clear, CloudUploadOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {useAppContext} from "../common/context";
import List from "@mui/material/List";
import VrexApi from "../api/vrexApi";
import {sleep, uploadFile} from "../common/util";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Queue} from "../common/Queue";


const useStyles = makeStyles(theme => ({
    dropzone: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
        borderStyle: "dashed",
        border: 2,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.list,
        minWidth: 200,
        minHeight: 100,
    },

    listItem: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.listAlt,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.list,
        },
    },
    listItemText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: 12,
    },
}))

const State = Object.freeze({READY: 0, UPLOADING: 1, COMPLETED: 2})

export default function UploadDialog({open, projectId, modelSourceId, type, handleExit}) {
    const theme = useTheme();
    const {setToast} = useAppContext();
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState([])
    const [uploadState, setUploadState] = useState(State.READY);
    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        if (!open)
            return;
        setFiles([]);
        setUploadProgress([]);
        setUploadState(State.READY);
    }, [open])

    async function handleUploads() {
        try {
            setUploadState(State.UPLOADING);
            const queue = new Queue(3);
            for (let i = 0; i < files.length; i++) {
                queue.enqueue(handleFile(files[i], i));
            }
            await queue.execute();
            await sleep(1000);
            setUploadState(State.COMPLETED);
        } catch (e) {
            setToast({message: t('project.models.local.upload.dialog.error'), error: e, severity: "error"})
            setUploadState(State.COMPLETED);
        }
    }


    function handleFile(file, index) {
        return async () => {
            const uploadResponse = await VrexApi.postUpload(projectId, modelSourceId, {fileName: file.name});
            await uploadFile(uploadResponse.url, file, index, onUploadProgress);
        }
    }

    function onUploadProgress(index, percentCompleted) {
        uploadProgress[index] = percentCompleted;
        setUploadProgress([...uploadProgress]);
    }

    function onRemoveFile(index) {
        files.splice(index, 1);
        setFiles([...files]);
        uploadProgress.splice(index, 1);
        setUploadProgress([...uploadProgress]);
    }

    const onDrop = (acceptedFiles, fileRejections) => {
        if (fileRejections.length > 0) {
            setToast({message: fileRejections[0].errors[0].message, severity: "error"});
        }
        acceptedFiles.forEach(newFile => {
            if (!files.find(file => {
                return file.path === newFile.path
            })) {
                files.push(newFile);
                uploadProgress.push(0);
            }
        });
        setFiles([...files]);
        setUploadProgress([...uploadProgress]);
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: onDrop,
        maxFiles: 200,
        maxSize: 5000000000,
        accept: type === "VRP" ? ".e57, .zip" : ".ifc, .jt, .zip, .ifczip, .nwd, .nwc, .rvt",
        disabled: uploadState !== 0,
    })


    function validateForm() {
        return files.length > 0;
    }

    return (
        <Dialog open={open}>
            <DialogTitle id="form-dialog-title">{t('project.models.local.common.upload.title')}</DialogTitle>
            <Typography variant={"body1"} color={"textSecondary"} style={{paddingLeft: theme.spacing(3)}}>
                {type === "VRP" ? t('project.models.local.vrp.upload.dialog.fileSupport') : t('project.models.local.vrx.upload.dialog.fileSupport')}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"} style={{paddingLeft: theme.spacing(3)}}>
                {t('project.models.local.common.upload.maxFileSize')}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"} style={{paddingLeft: theme.spacing(3)}}>
                {t('project.models.local.common.upload.maxFileCount')}
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"} style={{paddingLeft: theme.spacing(3)}}>
                {type === "VRP" ? t('project.models.local.vrp.upload.dialog.zipDetails') : t('project.models.local.vrx.upload.dialog.zipDetails')}
            </Typography>
            <Box style={{marginTop: theme.spacing(1)}} {...getRootProps({
                display: "flex",
                alignItems: "center",
                className: classes.dropzone
            })} >
                <input {...getInputProps()} />
                <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"} height={"100%"}>
                    <Box clone p={1}>
                        {isDragActive ?
                            <Typography
                                variant={"body1"}>{t('project.models.local.common.upload.dragDescription')}</Typography> :
                            <Typography
                                variant={"body1"}>{t('project.models.local.common.upload.description')}</Typography>}
                    </Box>
                    <CloudUploadOutlined/>
                </Box>
            </Box>
            {files.length > 0 &&
                <Typography variant={"body2"} color={"textSecondary"} style={{paddingLeft: theme.spacing(3)}}>
                    {t('project.models.local.common.upload.status', {
                        uploadCount: files.length,
                        completedCount: uploadProgress.filter(p => p === 100).length
                    })}
                </Typography>
            }
            <DialogContent>
                <TableContainer className={classes.tableContainer}>
                    <List size="small" aria-label="file table" style={{maxWidth: "100%", tableLayout: "fixed"}}>
                        {files.map((file, i) =>
                            <li key={i} className={classes.listItem}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Typography flexGrow={1} px={1} className={classes.listItemText}
                                                variant={"body1"}> {file.name}</Typography>
                                    <IconButton size={"small"}
                                                disabled={uploadState !== 0}
                                                onClick={() => onRemoveFile(i)}><Clear/>
                                    </IconButton>

                                </Box>
                                <LinearProgress key={i} color={"secondary"} variant="determinate"
                                                value={uploadProgress[i]}/>
                            </li>)}
                    </List>
                </TableContainer>
            </DialogContent>
            {uploadState === 0 && (
                <DialogActions style={{flexDirection: "row-reverse", justifyContent: "flex-start"}} disableSpacing>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!validateForm()}
                        onClick={() => handleUploads(files)}
                    >
                        {t('project.models.local.common.upload.submit')}
                    </Button>
                    <Button onClick={handleExit} variant={"outlined"} color={"secondary"}
                            style={{marginRight: theme.spacing(1)}}>
                        {t('general.cancel')}
                    </Button>

                </DialogActions>)}
            {uploadState !== 0 && (
                <DialogActions>
                    <Button onClick={handleExit} disabled={uploadState !== 2} variant="contained" color="primary">
                        {t('general.close')}
                    </Button>
                </DialogActions>)}
        </Dialog>
    )
}
