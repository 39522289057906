import {useTranslation} from "react-i18next";
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import React, {useRef} from "react";
import {tableStyles} from "../common/sharedStyles";
import {CloudUploadOutlined, ErrorOutlined} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {HelpText} from "./HelpText";
import Box from "@mui/material/Box";

export function UploadTable({uploads}) {
    const classes = tableStyles();
    const {t} = useTranslation();
    const animations = useRef([]);

    function syncAnimation(event) {
        for (let a of event.target.getAnimations()) {
            animations.current.push(a);
            if (animations.current[0].currentTime === null) {
                animations.current = animations.current.filter(a => a.currentTime !== null);
            }
            a.currentTime = animations.current[0].currentTime;
        }
    }

    return (
        <TableContainer className={classes.tableContainer}>
            <Table size="small" aria-label="upload table" style={{maxWidth: "100%"}}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell}
                                   style={{width: "auto"}}>{t('project.models.local.common.upload.list.name')}</TableCell>
                        <TableCell className={classes.tableCell} style={{width: "60px"}}
                                   align="left"
                                   sx={{minWidth: "40px", width: "60px", maxWidth: "60px"}}>
                            <Box display={"flex"} alignItems={"center"}><CloudUploadOutlined
                                fontSize={"small"}/><HelpText
                                text={t('project.models.local.common.upload.list.status.description')}/></Box>
                        </TableCell>
                        <TableCell
                            sx={{maxWidth: "260px", width: "260px", minWidth: "0px", border: "none"}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {uploads && uploads.map((upload) => (
                        <UploadRow key={upload.id} upload={upload} syncAnimation={syncAnimation}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function UploadRow({upload, syncAnimation}) {
    const classes = tableStyles();
    const theme = useTheme();
    const {t} = useTranslation();
    const tooltipPlacement = "right-start";

    function getFailedReasonTooltip() {
        switch (upload.failedReason) {
            case "UNSUPPORTED_FILE":
                return t('project.models.local.common.upload.list.status.failed.unsupportedFile');
            case "INVALID_ZIP":
                return t('project.models.local.common.upload.list.status.failed.invalidZip');
            default:
                return t('project.models.local.common.upload.list.status.failed.internalError');
        }
    }

    function getUploadStatus() {
        if (upload.status === "COMPLETED") {
            return "";
        }
        if (upload.status === "FAILED") {
            return (
                <Tooltip title={getFailedReasonTooltip()} placement={tooltipPlacement}>
                    <ErrorOutlined sx={{fontSize: 18, color: theme.palette.error.main, verticalAlign: "middle"}}/>
                </Tooltip>
            )
        }
        return (
            <Tooltip title={t('project.models.local.common.upload.list.status.processing')}
                     placement={tooltipPlacement}>
                <CircularProgress onAnimationStart={syncAnimation} color={"secondary"} size={18}
                                  sx={{verticalAlign: "middle"}}/>
            </Tooltip>
        )
    }

    return (
        <TableRow className={classes.tableDataRow}>
            <TableCell className={classes.tableCell}>{upload.filename}</TableCell>
            <TableCell className={`${classes.tableCell} ${classes.iconCell}`}>{getUploadStatus()}</TableCell>
        </TableRow>)
}
