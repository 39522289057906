import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import {Auth} from "aws-amplify";
import {useAppContext} from "../common/context";
import LoaderButton from "../component/LoaderButton";
import {useFormFieldsId} from "../common/hooks";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import UnauthenticatedPage from "../component/UnauthenticatedPage";
import {useTranslation} from "react-i18next";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    const {userHasAuthenticated, setToast} = useAppContext();
    const classes = useStyles();
    const [fields, handleFieldChange] = useFormFieldsId({
        email: "",
        password: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    function validateForm() {
        return fields.email.length > 0 && fields.password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.signIn(fields.email, fields.password);
            userHasAuthenticated(true);
        } catch (e) {
            setIsLoading(false);
            if (e.code === "UserNotConfirmedException") {
                navigate("/confirm", {state: {email: fields.email, password: fields.password}});
                return;
            }
            setToast({message: t('login.error'), error: e, severity: "error"});
        }
    }

    return (
        <UnauthenticatedPage>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('general.email')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleFieldChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('general.password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>)
                    }}
                    id="password"
                    autoComplete="off"
                    onChange={handleFieldChange}
                />
                <LoaderButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                    className={classes.submit}
                    disabled={!validateForm()}
                >
                    {t('login.submit')}
                </LoaderButton>
                <Grid container>
                    <Grid item xs>
                        <Link
                            color={"textSecondary"}
                            component={RouterLink}
                            to={"/login/reset"}
                            variant="body2"
                            underline="hover">
                            {t('login.forgot')}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            color={"textSecondary"}
                            component={RouterLink}
                            to={"/signup"}
                            variant="body2"
                            underline="hover">
                            {t('login.signUp')}
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </UnauthenticatedPage>
    );
}
