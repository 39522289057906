import React from "react";
import {Box, Button, CircularProgress} from "@mui/material"

export default function LoaderButton({
                                         isLoading,
                                         className = "",
                                         disabled = false,
                                         ...props
                                     }) {
    return (
        <Button
            className={`LoaderButton ${className}`}
            disabled={disabled || isLoading}
            {...props}
        >
            {isLoading && <CircularProgress size={24.5} sx={{marginRight: 1}}/>}
            <Box>
                {props.children}
            </Box>
        </Button>
    );
}
